/**
 *
 * SimpleLogs
 *
 */

import React from "react";
import ReactJson from "react-json-view";
import { DateTime } from "luxon";
import { Modal } from "antd";
import { Table } from "components";
import "./styles.scss";

import { CellProps, Row } from "react-table";

export class SimpleLogs extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedLog: null,
    };

    this.onPress = this.onPress.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
  }

  onPress({ original }: Row<object>) {
    this.setState({ selectedLog: original });
  }

  renderSummary() {
    const { selectedLog } = this.state;
    const { message, meta, source } = selectedLog;

    const date = message.split("[").pop().split("]")[0];
    let status = "500";

    if (meta.error) {
      status = meta.error.status;
    } else if (meta.res && meta.res.statusCode) {
      status = meta.res.statusCode;
    }

    const ipAddress =
      (meta.req.headers && meta.req.headers["x-real-ip"]) || "-";
    const origin = (meta.req.headers && meta.req.headers.host) || "-";

    return (
      <div className="DetailsSummary">
        <span className="Title">Summary</span>
        <span className="InfoTitle">Time</span>
        <span className="Info">{date}</span>
        <span className="InfoTitle">Method</span>
        <span className="Info">{meta.req.method}</span>
        <span className="InfoTitle">URL</span>
        <span className="Info">{meta.req.originalUrl}</span>
        <span className="InfoTitle">Status</span>
        <span className="Info">{status}</span>
        <span className="InfoTitle">IP Address</span>
        <span className="Info">{ipAddress}</span>
        <span className="InfoTitle">Source</span>
        <span className="Info">{source}</span>
        <span className="InfoTitle">Origin</span>
        <span className="Info">{origin}</span>
      </div>
    );
  }

  renderQuery() {
    const { selectedLog } = this.state;
    const { meta } = selectedLog;

    const hasMeta = Boolean(
      meta.req && Object.keys(meta.req.query || {}).length
    );

    if (!hasMeta) {
      return (
        <div className="DetailsSummary">
          <span className="Title">Request query params</span>
          <span className="NoInfo">No query parameters</span>
        </div>
      );
    }

    return (
      <div className="DetailsSummary">
        <span className="Title">Request query params</span>
        <ReactJson src={meta.req.query} enableClipboard />
      </div>
    );
  }

  renderBody() {
    const { selectedLog } = this.state;
    const { meta } = selectedLog;

    const hasReq = Boolean(meta && Object.keys(meta.req).length);

    if (!hasReq) {
      return (
        <div className="DetailsSummary">
          <span className="Title">Request body</span>
          <span className="NoInfo">No request body</span>
        </div>
      );
    }

    return (
      <div className="DetailsSummary">
        <span className="Title">Request body</span>
        <ReactJson src={meta.req} enableClipboard />
      </div>
    );
  }

  renderResponse() {
    const { selectedLog } = this.state;
    const { meta } = selectedLog;

    const hasRes = Boolean(meta && Object.keys(meta.res).length);

    if (!hasRes) {
      return (
        <div className="DetailsSummary">
          <span className="Title">Response</span>
          <span className="NoInfo">No response</span>
        </div>
      );
    }

    return (
      <div className="DetailsSummary">
        <span className="Title">Response</span>
        <ReactJson src={meta.res} enableClipboard />
      </div>
    );
  }

  renderDetails() {
    const { selectedLog } = this.state;
    const { message, description } = selectedLog;

    const date = message.split("[").pop().split("]")[0];

    return (
      <React.Fragment>
        <div className="DetailsHeader">
          <span>{description}</span>
          <span>{date}</span>
        </div>
        <div className="DetailsBody">
          {this.renderSummary()}
          {this.renderQuery()}
          {this.renderBody()}
          {this.renderResponse()}
        </div>
      </React.Fragment>
    );
  }

  renderModalDetails() {
    const { selectedLog } = this.state;
    const { logs } = this.props;
    if (!logs.length) return null;

    const visible = Boolean(selectedLog);
    return (
      <Modal
        visible={visible}
        title={null}
        footer={null}
        centered
        width="80vw"
        className="LogDetailModal"
        onCancel={() => this.setState({ selectedLog: null })}
      >
        {visible && this.renderDetails()}
      </Modal>
    );
  }

  renderStatus({ value }: CellProps<object, any>) {
    return (
      <div className={`status __${String(value).charAt(0)}`}>
        <span>{value}</span>
      </div>
    );
  }

  accessorDate({ _created }: any) {
    const date = DateTime.fromISO(_created);
    return date.toFormat("dd/MM/yyyy HH:mm:ss");
  }

  render() {
    const { logs } = this.props;
    return (
      <React.Fragment>
        <Table
          title={{
            icon: "duplicate",
            title: "Logs",
          }}
          data={logs}
          columns={[
            {
              Header: "Status",
              id: "code",
              accessor: "code",
              Cell: this.renderStatus,
              maxWidth: 120,
              type: "number",
            },
            {
              Header: "Method",
              id: "method",
              accessor: (row: any) => row.method,
              maxWidth: 120,
            },
            {
              Header: "Response",
              accessor: "response",
              maxWidth: 150,
            },
            { Header: "Description", accessor: "description" },
            {
              Header: "Date",
              accessor: this.accessorDate,
              maxWidth: 150,
            },
          ]}
          onRowPress={this.onPress}
          fixedHeight={281}
        />
        {this.renderModalDetails()}
      </React.Fragment>
    );
  }
}

export default SimpleLogs;
