/**
 *
 * ImportModal
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import Strings from "utils/strings";
import "./styles.scss";
import { API, Endpoints } from "utils/api";
import { popup } from "utils/utils";
import { ClapSpinner } from "react-spinners-kit";

/* eslint-disable react/prefer-stateless-function */
export class ImportModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      structure: {
        products: "https://.s3-eu-west-1.amazonaws.com/General/products.xlsx",
      },
      uploading: false,
    };
  }

  async handleSubmit() {
    const { file, onRequestClose, onSubmit } = this.props;
    const body = { files: file };
    const data = new FormData();
    data.append("files", body.files[0]);

    this.setState({ uploading: true });
    try {
      await API.post({ url: Endpoints.uriImportPromoters(), data: data });
      onSubmit();
      onRequestClose();
    } catch (err) {
      popup.error(Strings.errors.couldNotImportProduct);
      console.log("Err", err);
    }

    this.setState({ uploading: false });
  }

  renderModalContent() {
    const {
      title,
      onUploadFile,
      file,
      deleteFile,
      onRequestClose,
      onHelp,
    } = this.props;
    const { uploading } = this.state;

    let finalFile = null;
    let size = "";
    if (file) {
      finalFile = file[0];
      size = (finalFile.size / 1024).toFixed(1);
    }

    return (
      <div className="import-modal">
        <div className="import-modal-header">
          <div className="import-modal-header-back" onClick={onRequestClose}>
            <em className="moon-close1" />
          </div>
          <div className="import-modal-header-help" onClick={onHelp}>
            <em className="moon-info-2" />
          </div>
          <div className="import-modal-header-title">{title || ""}</div>
        </div>
        <div className="import-modal-content">
          <Dropzone
            style={{ height: 160 }}
            className="defaultDropzone"
            onDrop={onUploadFile}
            multiple={false}
            accept="text/csv, .xls, .xlsx"
          >
            {finalFile ? (
              <div className="import-modal-dropzone-content">
                <em className="moon-generate-folder" />
                <div>
                  <span>{Strings.importModal.fileName}: </span>
                  {finalFile.name}
                </div>
                <div>
                  <span>{Strings.importModal.size}: </span>
                  {size} Kbs
                </div>
              </div>
            ) : (
              <div
                className="dropzone_placeholder"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <em className="moon-upload-file" />
                <p style={{ marginTop: 10 }}>
                  {Strings.fields.filesPlaceholder}
                </p>
              </div>
            )}
          </Dropzone>
          {finalFile && (
            <div
              style={{ top: 10, right: 30, fontSize: "1.3em" }}
              className="delete-image-action"
              onClick={deleteFile}
            >
              <em className="moon-trash" />
            </div>
          )}
        </div>
        <div className="import-modal-container">
          <form method="get" action={this.state.structure.products}>
            <Button className="import-modal-example" type="primary">
              {Strings.generic.template}
            </Button>
          </form>
          <Button
            onClick={() => {
              if (!uploading) {
                this.handleSubmit();
              }
            }}
            className="import-modal-upload"
            disabled={!finalFile}
          >
            {uploading ? (
              <ClapSpinner
                size={16}
                frontColor="#FFFFFF"
                backColor="#FFFFFF"
                loading={uploading}
              />
            ) : (
              <span>{Strings.importModal.import}</span>
            )}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { openModal } = this.props;
    return (
      <Modal
        isOpen={openModal}
        onRequestClose={() => this.props.onRequestClose()}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 0,
            background: "transparent",
            border: "none",
            borderRadius: "8px",
          },
          overlay: {
            zIndex: 998,
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        }}
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(ImportModal);
