/* eslint-disable react/no-direct-mutation-state */
/*
*
* Promoter
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setTitle, setLoader, setBreadcrumb, delayedDispatch, updateCrumb } from 'store/actions';
import { API, Endpoints } from 'utils/api';
import { Table } from 'components';
import PhoneInput from 'components/PhoneInput';
import { Helmet } from 'react-helmet';
import { ContentWrapper, Icon } from 'components';
import { Row, Col, Input, Select, Form } from "antd";
import Dropzone from 'react-dropzone';
import Compressor from "compressorjs";
import { toast } from 'utils/utils';
import Strings from 'utils/strings';
import placeholder from 'assets/images/placeholders/image.jpg';
import './styles.scss';

const { Option } = Select;

export class Promoter extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			countries: [{
				label: 'Portugal',
				value: 'pt'
			}, {
				label: 'Espanha',
				value: 'es'
			}],
			phoneCountry: 'pt',
			promoter: {},
			page: 0,
			pageSize: 100,
			countiesPage: 0,
			countiesPageSize: 100,
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.promoters.header));

		delayedDispatch(
			setBreadcrumb(() => {
				const { match } = this.props;

				return {
					locations: [
						{
						  text: Strings.sidebar.promoters,
						  route: "/promoters",
						  icon: "working-briefcase",
						},
						{
							text: match.params.id === 'new' ? 'new' : this.state.promoter?.name,
							// route: `/parts/${match.params.id}`,
							icon: match.params.id === 'new' ? 'adicionar-acrescentar' : 'lapis',
						},
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							onClick: this.postPromoter,
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							isSave: true,
						},
					]
				}
			})
		);

		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { match, dispatch } = this.props;

		dispatch(setLoader(true));

		const [countryResponse, promotersResponse] = await Promise.all([
			API.get({
				url: Endpoints.uriCountriesPromoters(),
			}),
			API.get({
				url: Endpoints.uriPromoters(match.params.id === 'new' ? '' : match.params.id),
			}),
		]);

		if (countryResponse.ok) {
			this.setState({
				countries: countryResponse.data.results || [],
				countriesOptions: countryResponse.data.results.map((country: any, index: number) => (
					<Option
						key={`select_option_${country}_${index}`}
						value={country.alpha3Code}>
						{country.name}
					</Option>
				)) || [],
			});
		} else {
			toast.error(countryResponse.data?.message || Strings.serverErrors.title);
		}

		if (promotersResponse.ok) {
			const promoter = promotersResponse.data.results;

			const workAreasAux = promoter?.country?.states?.map((workArea: any) => ({
				name: workArea.name,
				counties: workArea?.counties?.map((elem: any) => {
					const selectedCounties = promoter?.workAreas?.find((elem: any) => elem.state === workArea?.name)?.counties
					return {
						...elem,
						isActive: !!selectedCounties?.find((county: any) => county.county === elem.name)
					}
				}),
				isActive: promoter?.workAreas?.find((wa: any) => wa.state === workArea.name)
			}));
			this.setState({ workAreas: workAreasAux });

			this.setState({
				promoter: promoter || [],
				workAreas: workAreasAux,
			});
		} else {
			toast.error(promotersResponse.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	}

	postPromoter = async () => {
		//POST Promoter
		const { promoter } = this.state;
		const { match, dispatch } = this.props;

		const body = new FormData();

		body.append('name', JSON.stringify(promoter.name));

		if(promoter.photo && promoter.photo.file) {
			body.append('photo', promoter.photo.file);
		} else if (promoter.photo)
			body.append('photo', promoter.photo);

		body.append('country', JSON.stringify(promoter.country._id));
		body.append('phone', JSON.stringify(promoter.phone));
		body.append('email', JSON.stringify(promoter.email));
		if (promoter.workAreas && promoter.workAreas.length) body.append('workAreas', JSON.stringify(promoter.workAreas));

		for (const pair of body.entries()) {
			console.log(`${pair[0]}, ${pair[1]}`);
		}

		dispatch(setLoader(true));

		const request = match?.params?.id === 'new' ? API.post : API.put;

		try {
			const response = await request({
				url: Endpoints.uriPromoters(match?.params?.id === 'new' ? '' : match.params.id),
				data: body,
			});

			if (response.ok) {
				this.setState({
					promoter: response.data.results || [],
				});
				if (match?.params?.id === 'new') {
					dispatch(push('/'))
					dispatch(push(`/promoters/${response.data.results._id}`))
				}
			}
		} catch (err) {
			console.log('API Error', err);
		}

		dispatch(setLoader(false));
	}

	onSubmit = () => {
		const { item } = this.state;
		if (item && item._id)
			this.addContact(item);
		else
			this.editContact(item);
	}

	addContact = async (contact: any) => {
		// Add Contact
		this.closeSidebar()
	}

	editContact  = async (contact: any) => {
		// Edit Contact
		this.closeSidebar()
	}

	deleteContact  = async (id: string) => {
		// Delete Contact
	}

	toogleContact  = async (id: string) => {
		// Toogle Contact 
	}

	onChange = (field: string, value: any) => {
		const { item } = this.state;
		this.setState({ item: { ...item, [field]: value } });
	}

	openSidebar = (value: any) => {
		this.setState({ openSidebar: true, item: JSON.parse(JSON.stringify(value)) });
	}

	closeSidebar = () => {
		this.setState({ openSidebar: false });
	}

	getBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.readAsDataURL(file);
		  reader.onload = () => resolve(reader.result);
		  reader.onerror = (error) => reject(error);
		});
	}
	
	onDrop = (files: any) => {
		try {
		  	const file = files[files.length - 1];
	
			new Compressor(file, {
				// quality: 0.9,
				// maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
				this.getBase64(result).then((res) => {
					console.log(result);
					this.setState((state: any) => ({
						promoter: {
							...state.promoter,
							photo: { file: result, preview: res },
						},
						hasUnsavedFields: true,
					}));
				});
				},
			});
		} catch (err) {
			toast.warning(Strings.errors.notSupportedFile);
		}
	}

	renderGeneralInfo() {
		const { promoter, countries, countriesOptions, phoneCountry } = this.state;

		return (
			<ContentWrapper>
				<Form name="profile">
					<Row gutter={24}>
						<Col xs={24} lg={10}>
							<div className="ProfilePictureContainer">
								<div className="ProfilePicture" style={{ margin: '30px auto 0', width: '200px', height: '200px' }}>
									<img
										alt={Strings.fields.image}
										src={promoter?.photo?.preview || promoter?.photo || placeholder}
									/>
									<div className="ProfilePictureOverlay">
										<Dropzone
											accept="image/jpg, image/jpeg, image/png"
											className="ProfilePictureOverlayOption"
											onDrop={(this.onDrop)}
											>
											<Icon name="pencil-outline" />
										</Dropzone>
										<div
											className={`ProfilePictureOverlayOption${
													Boolean(promoter?.photo) ? "" : " __disabled"
												}`}
											onClick={() => this.setState({ promoter: { ...promoter, photo: null }, hasUnsavedFields: true })}
											>
											<Icon name="trash" />
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={24} lg={14}>
							<Row gutter={24}>
								<Col xs={24} lg={12}>
									<label className="SingleLabel __required">{Strings.fields.name}</label>
									<Input
										placeholder={Strings.fields.name}
										defaultValue={promoter?.name}
										value={promoter?.name}
										onChange={e => {
											this.setState({
												promoter: {
													...promoter,
													name: e.target.value,
												},
												hasUnsavedFields: true,
											});
										}}
									/>
								</Col>
								<Col xs={24} lg={12}>
									<label className="SingleLabel __required">{Strings.fields.country}</label>
									<Select
										optionFilterProp='children'
										style={{ width: '100%' }}
										placeholder={Strings.fields.country}
										onChange={country => {
											country = countries.find((elem: any) => elem.alpha3Code === country);
											const workAreas = country.states?.map((workArea: any) => ({
													name: workArea.name,
													counties: workArea?.counties?.map((elem: any) => {
														const selectedCounties = promoter?.workAreas?.find((elem: any) => elem.state === workArea?.name)?.counties
														return {
															...elem,
															isActive: !!selectedCounties?.find((county: any) => county.county === elem.name)
														}
													})
												})
											);
											this.setState({
												promoter: {
													...promoter,
													country
												},
												workAreas: workAreas,
												hasUnsavedFields: true
											})}
										}
										filterOption={(input: any, option: any) =>
											option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
										}
										value={promoter?.country?.alpha3Code}>
										{countriesOptions}
									</Select>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col xs={24} lg={12}>
									<label className="SingleLabel __required">{Strings.fields.phone}</label>
									<PhoneInput
										key={`phone_${JSON.stringify(phoneCountry)}`}
										defaultCountry={phoneCountry}
										value={promoter?.phone}
										inputClass={`input-phone`}
										onChange={(phone: any) => {
											this.setState({
												promoter: {
													...promoter,
													phone,
												},
												hasUnsavedFields: true,
											})
										}}
									/>
								</Col>
								<Col xs={24} lg={12}>
									<label className="SingleLabel __required">{Strings.fields.email}</label>
									<Form.Item
										rules={[
											{ type: 'email', message: Strings.errors.emailIsInvalid },
										]}>
										<Input
											placeholder={Strings.fields.email}
											value={promoter?.email}
											onChange={e => {
												this.setState({
													promoter: {
														...promoter,
														email: e.target.value,
													},
													hasUnsavedFields: true,
												});
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</ContentWrapper>
		)
	}

	renderWorkAreasTable() {
		const { promoter, workAreas } = this.state;

		return (
			<>
				<Table
					title={{
						icon: "team",
						title: Strings.promoters.workAreas
					}}
					data={workAreas}
					columns={[
						{
							Header: Strings.fields.state,
							id: 'state',
							accessor: (row: any) => row.name,
						},
					]}
					paginationApi={{
						total: workAreas?.length || 0,
						pageIndex: this.state.page,
						pageSize: this.state.pageSize,
						setPage: (page, size) => {
							this.setState({ page, pageSize: size });
						}
					}}
					filterable
					expanded
					renderExpanded={(elem: any) => this.renderWorkAreaCounties(elem)}
					actions={{
						toggle: (original, value) => ({
							value: original.isActive,
							onChange: () => {
								if (original.isActive) {
									const index = promoter.workAreas.findIndex((elem: any) => elem.state === original.name);
									promoter.workAreas.splice(index, 1);

									const WAIndex = this.state.workAreas.findIndex((elem: any) => elem.name === original.name);
									this.state.workAreas[WAIndex].counties = this.state.workAreas[WAIndex].counties?.map((elem: any) => ({ name: elem.name, isActive: false }));
									this.state.workAreas[WAIndex].isActive = false;

									this.setState({ promoter, hasUnsavedFields: true });
								} else {
									if (!promoter.workAreas) promoter.workAreas = [];
									promoter.workAreas.push({ ...original, state: original.name, counties: original.counties.map((elem: any) => ({ county: elem.name })) });

									const WAIndex = this.state.workAreas.findIndex((elem: any) => elem.name === original.name);
									this.state.workAreas[WAIndex].counties = this.state.workAreas[WAIndex].counties?.map((elem: any) => ({ name: elem.name, isActive: true }));
									this.state.workAreas[WAIndex].isActive = true;

									this.setState({ promoter, hasUnsavedFields: true });
								}
							},
						}),
					}}
				/>
			</>
		)
	}

	renderWorkAreaCounties(row: any) {
		const workArea = row.original;
		const { promoter }= this.state;

		return (
			<Table
				title={{
					icon: "team",
					title: Strings.fields.counties
				}}
				data={workArea?.counties}
				columns={[
					{
						Header: Strings.fields.county,
						id: 'county',
						accessor: (row: any) => row.name,
					},
				]}
				paginationApi={{
					total: workArea?.counties?.length || 0,
					pageIndex: this.state.countiesPage,
					pageSize: this.state.countiesPageSize,
					setPage: (page, size) => {
						this.setState({ countiesPage: page, countiesPageSize: size });
					}
				}}
				filterable
				actions={{
					toggle: (original, value) => ({
						value: original.isActive,
						onChange: () => {
							if (original.isActive) {
								const promoterWAIndex = promoter.workAreas.findIndex((elem: any) => elem.state === workArea.name);
								const index = promoter.workAreas[promoterWAIndex].counties.findIndex((elem: any) => elem.county === original.name);
								promoter.workAreas[promoterWAIndex].isActive = true;
								promoter.workAreas[promoterWAIndex].counties.splice(index, 1);

								const WAIndex = this.state.workAreas.findIndex((elem: any) => elem.name === workArea.name);
								const countyIndex = this.state.workAreas[WAIndex].counties?.findIndex((elem: any) => elem.name === original.name);
								this.state.workAreas[WAIndex].counties[countyIndex].isActive = false;

								if (!promoter.workAreas[promoterWAIndex].counties || !promoter.workAreas[promoterWAIndex].counties.length)
									this.state.workAreas[WAIndex].isActive = false;

								this.setState({
									promoter,
									hasUnsavedFields: true,
								}, () => {
										row.toggleRowExpanded(true);
									});
							} else {
								let promoterWAIndex = promoter.workAreas?.findIndex((elem: any) => elem.state === workArea.name);

								if (!promoter.workAreas) promoter.workAreas = [];

								if(promoterWAIndex === undefined || promoterWAIndex === -1) {
									promoter.workAreas.push({ ...workArea, state: workArea.name, counties: []});
									promoterWAIndex = promoter.workAreas.length - 1;
								}
								
								promoter.workAreas[promoterWAIndex].counties.push({ county: original.name });

								const index = this.state.workAreas.findIndex((elem: any) => elem.name === workArea.name);
								const countyIndex = this.state.workAreas[index].counties?.findIndex((elem: any) => elem.name === original.name);
								this.state.workAreas[index].counties[countyIndex].isActive = true;
								this.state.workAreas[index].isActive = true;

								this.setState({
									promoter,
									hasUnsavedFields: true,
								}, () => {
										row.toggleRowExpanded(true);
									});
							}
						},
					}),
				}}
			/>
		)
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.promoters.header}</title>
					<meta name="description" content="Description of Promoter" />
				</Helmet>
				{this.renderGeneralInfo()}
				{this.renderWorkAreasTable()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Promoter);