/*
 *
 * Lines
 *
 */

import React from "react";
import { connect } from "react-redux";
import {
  setLoader,
  setTitle,
  delayedDispatch,
  setBreadcrumb,
  updateCrumb,
} from "store/actions";
import { Helmet } from "react-helmet";
import { EditSidebar } from "components/EditSidebar";
import { Table } from "components";
import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";
import Strings from "utils/strings";
import "./styles.scss";
import { DateTime } from "luxon";

export class Lines extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      item: {},
      language: "pt",
      sidebarLanguage: "pt",
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(setTitle(Strings.settings.lines));

    delayedDispatch(
      setBreadcrumb(() => {
        return {
          locations: [
            {
              text: Strings.sidebar.settings,
              route: "/settings",
              icon: "preferences",
            },
            {
              text: Strings.settings.lines,
              //   route: "/lines",
              icon: "growth",
            },
          ],
        };
      })
    );

    this.getLines();
  }

  componentDidUpdate() {
    const { dispatch } = this.props;
    dispatch(updateCrumb());
  }

  async getLines() {
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    try {
      const response = await API.get({
        url: Endpoints.uriLines(),
      });

      if (response.ok) {
        this.setState({
          lines: response.data.results || [],
        });
      }
    } catch (err) {
      console.log("API Error", err);
    }

    dispatch(setLoader(false));
  }

  submitLine = () => {
    const { item } = this.state;
    if (item && item._id) {
      this.editLine();
    } else {
      this.addLine();
    }
  };

  addLine = async () => {
    const { item, lines } = this.state;
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    const data = new FormData();
    data.append("name", JSON.stringify(item.name));

    if (item && item.icon && item.icon.file) {
      data.append("icon", item.icon.file);
    } else if (item && item.icon) data.append("icon", item.icon);

    await API.post({
      url: Endpoints.uriLines(),
      data,
    })
      .then((response) => {
        if (response.ok && response.data.results) {
          lines.push(response.data.results);
          this.setState({ lines, openSidebar: false, item: {} });
          toast.success(response.data?.message);
        } else
          toast.error(response.data?.message || Strings.serverErrors.title);
      })
      .catch((err) => {
        toast.error(err);
      });

    dispatch(setLoader(false));
  };

  editLine = async () => {
    const { item, lines } = this.state;
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    const data = new FormData();
    data.append("name", JSON.stringify(item.name));

    if (item && item.icon && item.icon.file) {
      data.append("icon", item.icon.file);
    } else if (item && item.icon) data.append("icon", item.icon);

    await API.put({
      url: Endpoints.uriLines(item._id),
      data,
    })
      .then((response) => {
        if (response.ok && response.data.results) {
          const index = lines.findIndex(
            (elem: any) => elem._id === response.data.results._id
          );
          lines.splice(index, 1, response.data.results);
          this.setState({ lines, openSidebar: false, item: {} });
          toast.success(response.data?.message);
        } else
          toast.error(response.data?.message || Strings.serverErrors.title);
      })
      .catch((err) => {
        toast.error(err);
      });

    dispatch(setLoader(false));
  };

  onDelete = async (id: any) => {
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    await API.delete({
      url: Endpoints.uriLines(id),
    })
      .then((response) => {
        if (response.ok && response.data.results) {
          this.setState({ lines: response.data.results });
        } else
          toast.error(response.data?.message || Strings.serverErrors.title);
      })
      .catch((err) => {
        toast.error(err);
      });

    dispatch(setLoader(false));
  };

  onDrag = async () => {
    const { lines, hoverIndex } = this.state;
    const { dispatch } = this.props;

    const item = lines[hoverIndex];
    const itemId = item?._id;

    if (!itemId || item.pos === hoverIndex) return;

    dispatch(setLoader(true));

    await API.patch({
      url: Endpoints.uriLines(`position/${itemId}`),
      data: { pos: hoverIndex },
    })
      .then((response) => {
        if (response.ok && response.data.results) {
          this.setState({ lines: response.data.results });
        } else
          toast.error(response.data?.message || Strings.serverErrors.title);
      })
      .catch((err) => {
        toast.error(err);
      });

    dispatch(setLoader(false));
  };

  onChange = (field: string, value: any) => {
    const { item, sidebarLanguage } = this.state;

    if (field === "name")
      this.setState({
        item: {
          ...item,
          [field]: { ...item[field], [sidebarLanguage]: value },
        },
      });
    else this.setState({ item: { ...item, [field]: value } });
  };

  closeSidebar = () => {
    this.setState({ openSidebar: false });
  };

  openSidebar = (value: any = {}) => {
    this.setState({
      openSidebar: true,
      item: JSON.parse(JSON.stringify(value)),
    });
  };

  renderDrawer() {
    const { item, sidebarLanguage } = this.state;
    return (
      <EditSidebar
        title={Strings.settings.lines}
        open={this.state.openSidebar}
        onClose={this.closeSidebar}
        onChange={this.onChange}
        onSubmit={this.submitLine}
        defaultValue={this.state.item}
        language={sidebarLanguage}
        onChangeLanguage={(lang: any) =>
          this.setState({ sidebarLanguage: lang })
        }
        closable={false}
        fields={[
          {
            field: "icon",
            value: item?.icon,
            type: "image",
            name: Strings.lines.icon,
          },
          {
            field: "name",
            value: item?.name?.[sidebarLanguage],
            type: "input",
            name: Strings.fields.name,
            required: true,
          },
          {
            version: DateTime.utc(),
          },
        ]}
      />
    );
  }

  render() {
    const { lines, language } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.settings.lines}</title>
          <meta name="description" content="Description of Lines" />
        </Helmet>
        <Table
          title={{
            icon: "growth",
            title: Strings.settings.lines,
          }}
          data={lines}
          columns={[
            {
              Header: Strings.lines.icon,
              id: "icon",
              accessor: (row: any) => row.icon,
              type: "image",
              maxWidth: 65,
            },
            {
              Header: Strings.fields.name,
              id: "name",
              accessor: (row: any) => row.name?.[language],
            },
          ]}
          filterable
          draggable
          onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
            this.setState({ lines: list, dragIndex, hoverIndex })
          }}
          onDragEnd={this.onDrag}
          add={{
            label: String(
              Strings.formatString(
                Strings.generic.addNew,
                Strings.settings.lines.toLowerCase()
              )
            ),
            onClick: () => this.openSidebar(),
          }}
          actions={{
            edit: (original, value) => ({
              onClick: () => this.openSidebar(original),
            }),
            remove: (original, value) => ({
              onClick: () => this.onDelete(original._id),
            }),
          }}
        />
        {this.renderDrawer()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Lines);
