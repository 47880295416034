/*
 *
 * Fertilization
 *
 */

import React from "react";
import { connect } from "react-redux";
import {
  setTitle,
  setLoader,
  setBreadcrumb,
  delayedDispatch,
  updateCrumb,
} from "store/actions";
import { Helmet } from "react-helmet";
import { ContentWrapper, Table } from "components";
import { EditSidebar } from "components/EditSidebar";
import { Row, Col, Input, Select } from "antd";
import { push } from "connected-react-router";
import { API, Endpoints } from "utils/api";
import { toast, translate } from "utils/utils";
import moment from "moment";
import Strings from "utils/strings";
import "./styles.scss";

const { Option } = Select;

export class Fertilization extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      fertilizing: {},
      hasUnsavedFields: false,

      productsOptions: [],
      formulasOptions: [],
      fertilizingTypesOptions: [],

      language: "pt",
      sidebarLanguage: "pt",
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle(Strings.fertilizing.header));

    delayedDispatch(
      setBreadcrumb(() => {
        const { language } = this.state;
        const { match, cultureName } = this.props;

        return {
          locations: [
            {
              text: Strings.sidebar.cultures,
              route: "/cultures",
              icon: "planting",
            },
            {
              text: cultureName?.[language],
              route: `/cultures/${match.params.id}`,
              icon: "planting",
            },
            {
              text: Strings.fertilizing.header,
              route: `/cultures/${match.params.id}`,
              icon: "fertilizer",
            },
            {
              text: match.params.fertilizationId === "new" ? "new" : "edit",
              // route: `/parts/${match.params.id}`,
              icon:
                match.params.fertilizationId === "new"
                  ? "adicionar-acrescentar"
                  : "lapis",
            },
          ],
          actions: [
            {
              type: "language",
              value: language,
              onChange: (lang: any) => this.setState({ language: lang }),
            },
            {
              type: "button",
              text: Strings.generic.save,
              onClick: this.submitFertilization,
              disabled: !this.state.hasUnsavedFields,
              className: this.state.hasUnsavedFields
                ? "BreadcrumbButtonSuccess"
                : "",
              separator: "left",
              isSave: true,
            },
          ],
        };
      })
    );

    this.getData();
  }

  componentDidUpdate() {
    const { dispatch } = this.props;
    dispatch(updateCrumb());
  }

  getData = async () => {
    const { language } = this.state;
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    const [productsResponse, fertilizingTypesResponse] = await Promise.all([
      API.get({
        url: Endpoints.uriProducts(),
      }),
      API.get({
        url: Endpoints.uriFertilizingTypes(),
      }),
    ]);

    if (productsResponse.ok && productsResponse.data.results) {
      this.setState({
        defaultProducts: productsResponse.data.results,
        productsOptions: productsResponse.data.results.map((elem: any) => (
          <Option key={elem._id} value={elem._id}>
            {elem.name?.[language]}
          </Option>
        )),
      }, () => this.getFertilizing());
    } else {
      toast.error(productsResponse.data?.message || Strings.serverErrors.title);
    }

    if (fertilizingTypesResponse.ok && fertilizingTypesResponse.data.results) {
      this.setState({
        fertilizingTypesOptions: fertilizingTypesResponse.data.results?.map(
          (elem: any) => (
            <Option key={elem._id} value={elem._id}>
              {elem.name?.[language]}
            </Option>
          )
        ),
      });
    } else {
      toast.error(
        fertilizingTypesResponse.data?.message || Strings.serverErrors.title
      );
    }

    dispatch(setLoader(false));
  };

  getFertilizing = async () => {
    const { defaultProducts } = this.state;
    const { dispatch, match } = this.props;

    dispatch(setLoader(true));

    try {
      const response = await API.get({
        url: Endpoints.uriCultures(`${match.params.id}`),
      });
      if (response.ok) {
        const { culture } = response.data.results;

        const fertilizing = culture.fertilizing.find(
          (elem: any) => elem._id === match.params.fertilizationId
        );

        if (fertilizing) {
          fertilizing.country = fertilizing.country._id;

          fertilizing.usualProduction.doses =
            fertilizing.usualProduction.doses.map((elem: any) => {
              const product = defaultProducts.find((aux: any) => aux._id === elem.product._id);
              elem.product = product || elem.product;
              elem.formula = product.formulas.find(
                (formula: any) => formula._id === elem.formula
              ) || elem.formula;
              return elem;
            });

          fertilizing.expectedProduction = fertilizing.expectedProduction.map(
            (production: any) => {
              production.doses = production.doses.map((elem: any) => {
                const product = defaultProducts.find((aux: any) => aux._id === elem.product._id);
                elem.product = product || elem.product;
                elem.formula = product.formulas.find(
                  (formula: any) => formula._id === elem.formula
                ) || elem.formula;
                return elem;
              });
              return production;
            }
          );
        }

        this.setState({ culture, fertilizing: fertilizing || {} }, () => {
          this.getCountries()
        });
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      return toast.error(err || Strings.serverErrors.title);
    } finally {
      dispatch(setLoader(false));
    }
  };

  getCountries = async () => {
    // const { culture } = this.state;
    const { dispatch } = this.props;
    dispatch(setLoader(true));

    try {
      const response = await API.get({
        url: Endpoints.uriCountriesPromoters(),
      });
      if (response.ok) {
        const countries = response.data.results;

        let availableCountriesStates = JSON.parse(JSON.stringify(countries));

        //
        // for (let fertilizing of culture.fertilizing) {
        // 	if (fertilizing._id !== match.params.fertilizationId) {
        // 		availableCountriesStates = availableCountriesStates.map((country: any) => {
        // 			if (fertilizing.country._id === country._id) {
        // 				country.states = country.states.filter((state: any) => !fertilizing.states.find((aux: any) => aux === state.name))
        // 				return country;
        // 			} else {
        // 				return country;
        // 			}
        // 		})
        // 	}
        // }

        this.setState({ countries: availableCountriesStates });
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      return toast.error(err || Strings.serverErrors.title);
    } finally {
      dispatch(setLoader(false));
    }
  };

  submitFertilization = async () => {
    const { fertilizing } = this.state;
    const { dispatch, match } = this.props;

    dispatch(setLoader(true));

    const usualProduction = { doses: [] };

    usualProduction.doses =
      fertilizing.usualProduction?.doses?.map((elem: any) => ({
        fertilizingType: elem.fertilizingType,
        product: elem.product._id,
        formula: elem.formula?._id,
        season: elem.season,
        dose: elem.dose,
        maxConcentration: elem.maxConcentration,
        observations: elem.observations,
      })) || [];

    const expectedProduction = fertilizing.expectedProduction?.map(
      (production: any) => ({
        production: production.production,
        doses:
          production?.doses?.map((elem: any) => ({
            fertilizingType: elem.fertilizingType,
            product: elem.product._id,
            formula: elem.formula?._id,
            season: elem.season,
            dose: elem.dose,
            maxConcentration: elem.maxConcentration,
            observations: elem.observations,
          })) || [],
      })
    );

    const body = {
      country: fertilizing.country,
      states: fertilizing.states,
      productionDestiny: fertilizing.productionDestiny,
      usualProduction,
      expectedProduction,
    };

    try {
      const request =
        match.params.fertilizationId === "new" ? API.post : API.put;
      const response = await request({
        url: Endpoints.uriCultures(
          `${match.params.id}/fertilizing/${
            match.params.fertilizationId !== "new"
              ? match.params.fertilizationId
              : ""
          }`
        ),
        data: body,
      });
      if (response.ok) {
        const culture = response.data.results;

        dispatch(push(`/cultures/${culture._id}`));
      } else {
        return toast.error(
          response.data?.message || Strings.serverErrors.title
        );
      }
    } catch (err) {
      return toast.error(err || Strings.serverErrors.title);
    } finally {
      dispatch(setLoader(false));
    }
  };

  openSidebar = (value: any, sidebarType: string) => {
    let formulasOptions = null;
    if (sidebarType !== "production") {
      formulasOptions =
        value.product?.formulas?.map((elem: any) => (
          <Option key={`formula_${elem._id}`} value={elem._id}>
            {translate(elem.name)}
          </Option>
        )) || [];
    }

    this.setState({
      openSidebar: true,
      item: JSON.parse(JSON.stringify(value)),
      formulasOptions: formulasOptions || [],
      sidebarType,
    });
  };

  closeSidebar = () => {
    this.setState({ openSidebar: false, sidebarType: null });
  };

  onChange = (field: string, value: any) => {
    const { item, defaultProducts, sidebarLanguage } = this.state;

    if (field === "season" || field === "observations" || field === 'dose')
      this.setState({
        item: {
          ...item,
          [field]: { ...item[field], [sidebarLanguage]: value },
        },
      });
    else if (field === "product") {
      const product = defaultProducts.find((elem: any) => elem._id === value);
      const formulasOptions =
        product?.formulas?.map((elem: any) => (
          <Option key={`formula_${elem._id}`} value={elem._id}>
            {translate(elem.name)}
          </Option>
        )) || [];

      this.setState({
        item: { ...item, product, formula: null },
        formulasOptions,
      });
    } else this.setState({ item: { ...item, [field]: value } });
  };

  submitUsualProduction = () => {
    const { item, fertilizing } = this.state;

    item.formula = item.product.formulas.find(
      (elem: any) => elem._id === (item.formula?._id || item.formula)
    );

    if (item.index >= 0) {
      fertilizing.usualProduction?.doses?.splice(item.index, 1, item);
    } else {
      if (!fertilizing.usualProduction)
        fertilizing.usualProduction = { doses: [] };
      else if (!fertilizing.usualProduction.doses)
        fertilizing.usualProduction.doses = [];
      fertilizing.usualProduction?.doses.push(item);
    }
    this.setState({ fertilizing, openSidebar: false, hasUnsavedFields: true });
  };

  submitExpectedProduction = () => {
    const { sidebarType, item, fertilizing } = this.state;
    if (sidebarType === "production") {
      if (item.index >= 0) {
        fertilizing.expectedProduction.splice(item.index, 1, item);
      } else {
        if (!fertilizing.expectedProduction)
          fertilizing.expectedProduction = [];
        fertilizing.expectedProduction.push(item);
      }
      this.setState({
        fertilizing,
        openSidebar: false,
        hasUnsavedFields: true,
      });
    } else if (sidebarType === "doses") {
      item.formula = item.product.formulas.find(
        (elem: any) => elem._id === (item.formula?._id || item.formula)
      );

      if (item.index >= 0) {
        fertilizing.expectedProduction[item.productionIndex]?.doses?.splice(
          item.index,
          1,
          item
        );
      } else {
        if (!fertilizing.expectedProduction[item.productionIndex]?.doses)
          fertilizing.expectedProduction[item.productionIndex].doses = [];
        fertilizing.expectedProduction[item.productionIndex]?.doses.push(item);
      }
      this.setState({
        fertilizing,
        openSidebar: false,
        hasUnsavedFields: true,
      });
    }
  };

  deleteExpectedProduction(index: any, productionId: any = null) {
    const { fertilizing } = this.state;

    if (typeof productionId === 'number' && productionId !== -1) {
      fertilizing.expectedProduction[productionId].doses.splice(index, 1);
    } else {
      fertilizing.expectedProduction.splice(index, 1);
    }

    this.setState({ fertilizing });
  }

  deleteUsualProductionDose(index: any) {
    const { fertilizing } = this.state;

    fertilizing.usualProduction.doses.splice(index, 1);

    this.setState({ fertilizing });
  }

  renderDrawer() {
    const {
      item,
      productsOptions,
      formulasOptions,
      fertilizingTypesOptions,
      sidebarType,
      sidebarLanguage,
    } = this.state;

    const files =
      sidebarType === "production"
        ? [
            {
              field: "production",
              value: item?.production || [],
              type: "input",
              name: Strings.fertilizing.production,
              required: true,
            },
          ]
        : [
            {
              field: "fertilizingType",
              value: item?.fertilizingType?._id,
              populated: true,
              options: fertilizingTypesOptions,
              type: "selector",
              name: Strings.fertilizing.fertilizingType,
              required: true,
            },
            {
              field: "product",
              value: item?.product?._id,
              populated: true,
              options: productsOptions,
              type: "selector",
              name: Strings.products.header,
              required: true,
            },
            {
              key: `${item?.product?._id}`,
              field: "formula",
              value: item?.formula?._id || [],
              populated: true,
              options: formulasOptions,
              type: "selector",
              name: Strings.products.formula,
              required: true,
            },
            {
              field: "season",
              value: item?.season?.[sidebarLanguage] || [],
              type: "input",
              name: Strings.fertilizing.season,
              required: true,
            },
            {
              field: "dose",
              value: item?.dose?.[sidebarLanguage] || [],
              type: "input",
              name: Strings.fertilizing.dose,
              required: true,
            },
            {
              field: "maxConcentration",
              value: item?.maxConcentration || [],
              type: "input",
              name: Strings.fertilizing.maxConcentration,
            },
            {
              field: "observations",
              value: item?.observations?.[sidebarLanguage] || [],
              type: "textArea",
              name: Strings.fertilizing.observations,
            },
          ];

    return (
      <EditSidebar
        title={
          sidebarType === "usualDoses"
            ? Strings.fertilizing.usualProduction
            : Strings.fertilizing.expectedProduction
        }
        open={this.state.openSidebar}
        onClose={this.closeSidebar}
        onChange={this.onChange}
        onSubmit={
          sidebarType === "usualDoses"
            ? this.submitUsualProduction
            : this.submitExpectedProduction
        }
        defaultValue={this.state.item}
        language={sidebarType !== "production" && sidebarLanguage}
        onChangeLanguage={
          sidebarType !== "production"
            ? (lang: any) => this.setState({ sidebarLanguage: lang })
            : () => {}
        }
        closable={false}
        fields={[
          ...files,
          {
            version: moment.now(),
          },
        ]}
      />
    );
  }

  renderUsualProduction() {
    const { fertilizing, language } = this.state;

    return (
      <>
        <Table
          title={{
            icon: "flask",
            title: Strings.fertilizing.usualProduction,
          }}
          data={fertilizing.usualProduction?.doses}
          columns={[
            {
              Header: Strings.products.header,
              id: "product",
              accessor: (row: any) => row.product.name?.[language],
              maxWidth: 150,
            },
            {
              Header: Strings.products.formulas,
              id: "formula",
              accessor: (row: any) => translate(row.formula?.name),
            },
            {
              Header: Strings.fertilizing.dose,
              id: "dose",
              accessor: (row: any) => row.dose?.[language],
            },
          ]}
          add={{
            label: String(
              Strings.formatString(
                Strings.generic.addNew,
                Strings.fertilizing.dose.toLowerCase()
              )
            ),
            onClick: () => this.openSidebar({}, "usualDoses"),
          }}
          actions={{
            edit: (original, value) => ({
              onClick: () =>
                this.openSidebar(
                  {
                    ...original,
                    index: fertilizing.usualProduction?.doses?.findIndex(
                      (elem: any) => elem === original
                    ),
                  },
                  "usualDoses"
                ),
            }),
            remove: (original, value) => ({
              onClick: () =>
                this.deleteUsualProductionDose(
                  fertilizing.usualProduction?.doses?.findIndex(
                    (elem: any) => elem === original
                  )
                ),
            }),
          }}
        />
        {this.renderDrawer()}
      </>
    );
  }

  renderProductionExpandable(productionIndex: any) {
    const { fertilizing, language } = this.state;

    return (
      <>
        <Table
          title={{
            icon: "flask",
            title: Strings.fertilizing.doses,
          }}
          data={fertilizing.expectedProduction[productionIndex].doses}
          columns={[
            {
              Header: Strings.products.header,
              id: "product",
              accessor: (row: any) => row.product.name?.[language],
              maxWidth: 150,
            },
            {
              Header: Strings.products.formulas,
              id: "formula",
              accessor: (row: any) => translate(row.formula?.name),
            },
            {
              Header: Strings.fertilizing.dose,
              id: "dose",
              accessor: (row: any) => row.dose?.[language],
            },
          ]}
          add={{
            label: String(
              Strings.formatString(
                Strings.generic.addNew,
                Strings.fertilizing.dose.toLowerCase()
              )
            ),
            onClick: () => this.openSidebar({ productionIndex }, "doses"),
          }}
          actions={{
            edit: (original, value) => ({
              onClick: () =>
                this.openSidebar(
                  {
                    ...original,
                    productionIndex,
                    index: fertilizing.expectedProduction[
                      productionIndex
                    ]?.doses?.findIndex((elem: any) => elem === original),
                  },
                  "doses"
                ),
            }),
            remove: (original, value) => ({
              onClick: () =>
                this.deleteExpectedProduction(
                  fertilizing.expectedProduction[
                    productionIndex
                  ]?.doses?.findIndex((elem: any) => elem === original),
                  productionIndex
                ),
            }),
          }}
        />
        {this.renderDrawer()}
      </>
    );
  }

  renderExpectedProductionTable() {
    const { fertilizing } = this.state;

    return (
      <div style={{ marginTop: 10 }}>
        <Table
          title={{
            icon: "flask",
            title: Strings.fertilizing.expectedProduction,
          }}
          data={fertilizing.expectedProduction}
          columns={[
            {
              Header: Strings.fertilizing.production,
              id: "production",
              accessor: (row: any) => row.production,
            },
          ]}
          expanded
          renderExpanded={(elem: any) =>
            this.renderProductionExpandable(elem.index)
          }
          add={{
            label: String(
              Strings.formatString(
                Strings.generic.addNew,
                Strings.fertilizing.production.toLowerCase()
              )
            ),
            onClick: () => this.openSidebar({}, "production"),
          }}
          actions={{
            edit: (original, value) => ({
              onClick: () =>
                this.openSidebar(
                  {
                    ...original,
                    index: fertilizing.expectedProduction?.findIndex(
                      (elem: any) => elem === original
                    ),
                  },
                  "production"
                ),
            }),
            remove: (original, value) => ({
              onClick: () =>
                this.deleteExpectedProduction(
                  fertilizing.expectedProduction?.findIndex(
                    (elem: any) => elem === original
                  )
                ),
            }),
          }}
        />
        {this.renderDrawer()}
      </div>
    );
  }

  render() {
    const { fertilizing, countries, language } = this.state;

    const country = countries?.find(
      (elem: any) => elem._id === fertilizing.country
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.fertilizing.header}</title>
          <meta name="description" content="Description of Fertilization" />
        </Helmet>
        <ContentWrapper>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <label className="SingleLabel __required">
                {Strings.fields.country}
              </label>
              <Select
                key={fertilizing.country}
                className="tagsSelector"
                style={{ width: "100%" }}
                placeholder={Strings.fields.country}
                allowClear
                showSearch
                defaultValue={fertilizing.country}
                onChange={(elem: any) =>
                  this.setState({
                    fertilizing: { ...fertilizing, country: elem, states: [] },
                    hasUnsavedFields: true,
                  })
                }
              >
                {countries?.map((elem: any) => (
                  <Option key={elem._id} value={elem._id}>
                    {elem.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} md={12}>
              <label className="SingleLabel __required">
                {Strings.fields.state}
              </label>
              <Select
                key={fertilizing.country}
                className="tagsSelector"
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={Strings.fields.state}
                allowClear
                showSearch
                defaultValue={fertilizing.states}
                onChange={(elem: any) =>
                  this.setState({
                    fertilizing: { ...fertilizing, states: elem },
                    hasUnsavedFields: true,
                  })
                }
              >
                {country?.states?.map((elem: any) => (
                  <Option key={elem._id} value={elem.name}>
                    {elem.name}
                  </Option>
                )) || []}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <label className="SingleLabel __required">
                {Strings.fertilizing.productionDestiny}
              </label>
              <Input
                placeholder={Strings.fertilizing.productionDestiny}
                defaultValue={fertilizing?.productionDestiny?.[language] || ""}
                value={fertilizing?.productionDestiny?.[language] || ""}
                onChange={(e) => {
                  this.setState({
                    fertilizing: {
                      ...fertilizing,
                      productionDestiny: {
                        ...fertilizing?.productionDestiny,
                        [language]: e.target.value,
                      },
                    },
                    hasUnsavedFields: true,
                  });
                }}
              />
            </Col>
          </Row>
        </ContentWrapper>
        {this.renderUsualProduction()}
        {this.renderExpectedProductionTable()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  cultureName: state.router?.location?.state?.cultureName,
});

export default connect(mapStateToProps)(Fertilization);
