/*
*
* NewsDetails
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setLoader, setBreadcrumb, delayedDispatch, updateCrumb } from 'store/actions';
import { Helmet } from 'react-helmet';
import { Row, Col, Input, Switch, DatePicker, Select } from "antd";
import { API, Endpoints } from "utils/api";
import Dropzone from 'react-dropzone';
import { push } from 'connected-react-router';
import { toast } from 'utils/utils';
import { ContentWrapper, Icon, Editor } from "components";
import Compressor from "compressorjs";
import moment from 'moment';
import Strings from 'utils/strings';
import placeholder from 'assets/images/placeholders/image.jpg';
import './styles.scss';

const { Option } = Select;

export class NewsDetails extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			language: 'pt',
			filesToDelete: [],
			countries: [],
			timezones: [],
			timezone: "Europe/Lisbon",
		};
	}

	componentDidMount() {
		const { dispatch, match } = this.props;

		dispatch(setTitle(Strings.news.header));

		delayedDispatch(
			setBreadcrumb(() => {
				const { language, published } = this.state;

				return {
					locations: [
						{
						  text: Strings.sidebar.news,
						  route: "/news",
						  icon: "timetable-outline",
						},
						{
							text: match.params.id === 'new' ? 'new' : this.state.name?.[language],
							// route: `/parts/${match.params.id}`,
							icon: match.params.id === 'new' ? 'adicionar-acrescentar' : 'lapis',
						},
					],
					actions: [
						{
							type: "switch",
							text: Strings.publication.published,
							value: published,
							onClick: (published: any) => this.setState({ published, hasUnsavedFields: true }),
							small: {
								text: true,
								switch: true,
							},
						},
						{
							type: "language",
							value: language,
							separator: 'left',
							onChange: (lang: any) => this.setState({ language: lang }),
						},
						{
							type: "button",
							text: Strings.generic.save,
							onClick: this.postNotice,
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							separator: 'left',
							isSave: true,
						},
					]
				}
			})
		);

		if(match.params.id !== 'new') this.getNotice();
		this.getCountries();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getNotice() {
		const { match, dispatch } = this.props;

		const response = await API.get({
			url: Endpoints.uriNews(match.params.id),
		});

		if (response.ok) {
			this.setState({ ...response.data.results });
		} else {
			dispatch(push('/news/new'))
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
	}

	async getCountries() {
		const response = await API.get({
			url: Endpoints.uriCountries(),
		});

		if (response.ok) {
			const countries = response.data.results;
			const portugal = countries.find((elem: any) => elem.name === "Portugal");

			this.setState({
				countries,
				country: this.state.country || portugal._id,
				timezones: portugal.timezones,
			});
		} else {
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
	}

	isValid() {
		const { name, description, publishSchedule, publishScheduleDate } = this.state;
	
		return name && description && (!publishSchedule || (publishSchedule && publishScheduleDate));
	}

	postNotice = async () => {
		const { match, dispatch } = this.props;
		const { name, image, detailsImage, shortDescription, description, published, publishSchedule, publishScheduleDate, country, timezone, notifyUsers, notificationTitle, notificationDescription, filesToDelete } = this.state;

		if (!this.isValid()) {
			return toast.warn(Strings.errors.invalidFields);
		}

		dispatch(setLoader(true));

        const body = new FormData();

        body.append("name", JSON.stringify(name));
        if(shortDescription) body.append("shortDescription", JSON.stringify(shortDescription));
        body.append("description", JSON.stringify(description));
        body.append("published", published || false);

        body.append("publishSchedule", publishSchedule || false);

		if (publishSchedule){
        	body.append("country", country);
        	body.append("timezone", timezone);
        	body.append("publishScheduleDate", publishScheduleDate);
		}
        
		body.append("notifyUsers", notifyUsers || false);

		if (notifyUsers) {
			body.append("notificationTitle", JSON.stringify(notificationTitle));
			body.append("notificationDescription", JSON.stringify(notificationDescription));
		}

		if(image && image.file) {
			body.append('image', image.file);
		} else if (image)
			body.append('image', image);

		if(detailsImage && detailsImage.file) {
			body.append('detailsImage', detailsImage.file);
		} else if (image)
			body.append('detailsImage', detailsImage);

		if (match?.params?.id !== 'new') body.append('filesToDelete', JSON.stringify(filesToDelete));

		for (const pair of body.entries()) {
			console.log(`${pair[0]}, ${pair[1]}`);
		}

		const request = match?.params?.id === 'new' ? API.post : API.put;
	
		const response = await request({
			url: Endpoints.uriNews(match?.params?.id !== 'new' ? match?.params?.id : ''),
			data: body,
		});
	
		if (response.ok) {
			this.setState({ ...response.data.results, hasUnsavedFields: false });
			dispatch(push('/'));
			dispatch(push(`/news/${response.data.results._id}`))
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
		dispatch(setLoader(false));
	}

	getBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.readAsDataURL(file);
		  reader.onload = () => resolve(reader.result);
		  reader.onerror = (error) => reject(error);
		});
	}
	
	onDrop = (files: any, field: string) => {
		try {
		  	const file = files[files.length - 1];
	
			new Compressor(file, {
				// quality: 1,
        		// maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
				this.getBase64(result).then((res) => {
					console.log(result);
					this.setState((state: any) => ({
						filesToDelete: state[field] && typeof state[field] === 'string' ?
										[...state.filesToDelete, state[field] ] :
										state.filesToDelete,
						[field]: { file: result, preview: res },
						hasUnsavedFields: true,
					}));
				});
				},
			});
		} catch (err) {
			toast.warning(Strings.errors.notSupportedFile);
		}
	}

	renderGeneralInfo() {
		const { image, detailsImage, name, shortDescription, publishSchedule, publishScheduleDate, timezones, timezone, countries, country } = this.state;
		const { language } = this.state;

		console.log('language', language)

		return (
			<ContentWrapper>
				<Row gutter={24}>
					<Col xs={24} lg={12}>
						<label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.news.primaryImage}</label>
						<div className="ImageContainer">
							<div className="Image" style={{ height: 255 }}>
								<img
									alt={Strings.fields.image}
									src={image?.preview || image || placeholder}
								/>
								<div className="ImageOverlay">
									<Dropzone
										accept="image/jpg, image/jpeg, image/png"
										className="ImageOverlayOption"
										onDrop={(files: any) => this.onDrop(files, 'image')}
										>
										<Icon name="pencil-outline" />
									</Dropzone>
									<div
										className={`ImageOverlayOption${
												Boolean(image) ? "" : " __disabled"
											}`}
										onClick={() => this.setState((state: any) => ({
											filesToDelete: state.image && typeof state.image === 'string' ?
															[...state.filesToDelete, state.image ] :
															state.filesToDelete,
											image: null,
										}))}
										>
										<Icon name="trash" />
									</div>
								</div>
							</div>
						</div>
						<label className="SingleLabel __required">{Strings.fields.name}</label>
						<Input
							placeholder={Strings.fields.name}
							defaultValue={name?.[language] || ''}
							value={name?.[language]}
							onChange={e => {
								this.setState({ name: { ...name, [language]: e.target.value }, hasUnsavedFields: true });
							}}
							className={''}
						/>
					</Col>
					<Col xs={24} lg={12}>
						<label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.news.secondaryImage}</label>
						<div className="ImageContainer">
							<div className="Image" style={{ height: 255 }}>
								<img
									alt={Strings.fields.image}
									src={detailsImage?.preview || detailsImage || placeholder}
								/>
								<div className="ImageOverlay">
									<Dropzone
										accept="image/jpg, image/jpeg, image/png"
										className="ImageOverlayOption"
										onDrop={(files: any) => this.onDrop(files, 'detailsImage')}
										>
										<Icon name="pencil-outline" />
									</Dropzone>
									<div
										className={`ImageOverlayOption${
												Boolean(detailsImage) ? "" : " __disabled"
											}`}
										onClick={() => this.setState((state: any) => ({
											filesToDelete: state.detailsImage && typeof state.detailsImage === 'string' ?
															[...state.filesToDelete, state.detailsImage ] :
															state.filesToDelete,
											detailsImage: null,
										}))}
										>
										<Icon name="trash" />
									</div>
								</div>
							</div>
						</div>
						<div
							className={`General_ColorFul_Switch ${publishSchedule ? '__active' : ''}`}
							style={{ marginTop: 40 }}>
							<span>{Strings.news.schedulePublication}</span>
							<Switch
								className={`Switch ${publishSchedule ? '__active' : ''}`}
								checked={publishSchedule}
								size='small'
								onChange={value => this.setState({ publishSchedule: !publishSchedule })}
							/>
						</div>
					</Col>
					<Col xs={24}>
						{publishSchedule && <Row gutter={[12, 12]} style={{ paddingTop: '10px' }}>
							<Col xs={24}>
								<DatePicker
									value={publishScheduleDate ? moment(publishScheduleDate) : null}
									format='DD-MM-YYYY HH:mm'
									style={{ width: '100%' }}
									showTime
									placeholder={Strings.publication.publicationDate}
									onChange={(date: any) => {
										console.log('date', date)
										this.setState({ hasUnsavedFields: true, publishScheduleDate: date })
									}}
									disabled={!publishSchedule}
								/>
							</Col>
							<Col xs={24} md={12}>
								{/* <label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.notifications.timezone}</label> */}
								<Select
									showSearch
									placeholder={Strings.fields.countries}
									optionFilterProp="children"
									style={{ width: '100%' }}
									onChange={(countryId: any) => {
										const country = countries.find((elem: any) => elem._id === countryId);
										this.setState({
											country: countryId,
											timezones: country.timezones,
											timezone: country.timezones.find((elem: any) => elem === timezone),
											hasUnsavedFields: true,
										})
									}}
									filterOption={(input: any, option: any) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={country}
								>
									{countries.map((elem: any) => (
										<Option value={elem._id}>{elem.name}</Option>)
									)}
								</Select>
							</Col>
							<Col xs={24} md={12}>
								{/* <label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.notifications.timezone}</label> */}
								<Select
									showSearch
									placeholder={Strings.cultures.header}
									optionFilterProp="children"
									style={{ width: '100%' }}
									onChange={(timezone: any) =>
										this.setState({ timezone, hasUnsavedFields: true, })
									}
									filterOption={(input: any, option: any) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={timezone}
								>
									{timezones.map((elem: any) => (
										<Option value={elem}>{elem}</Option>)
									)}
								</Select>
							</Col>
						</Row>}
						<label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.news.shortDescription}</label>
						<Input.TextArea
							placeholder={Strings.news.shortDescription}
							style={{ height: 80 }}
							value={shortDescription?.[language]}
							onChange={e => {
								e.preventDefault();
								this.setState({
									shortDescription: {
										...shortDescription,
										[language]: e.target.value
									},
									hasUnsavedFields: true,
								})
							}} />
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderNotification() {
		const {
			notifyUsers,
			notificationTitle,
			notificationDescription,
		} = this.state;
		const { language } = this.state;

		return (
			<ContentWrapper>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<div className="title" style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
							<em className="moon-bell" />
							<div style={{ marginLeft: '10px' }}>{Strings.notifications.send}</div>
						</div>
					</div>
					<div style={{ marginLeft: '10px' }} className="toggle-wrapper">
						<Switch
							checked={notifyUsers}
							size='default'
							onChange={() => this.setState({ notifyUsers: !notifyUsers, hasUnsavedFields: true })}
						/>
					</div>
				</div>
				{notifyUsers && <>
					<label className="SingleLabel __required">{Strings.notifications.header}</label>
					<Input
						placeholder={Strings.notifications.header}
						defaultValue={notificationTitle ? notificationTitle[language] : ''}
						value={notificationTitle && notificationTitle[language]}
						onChange={e => {
							this.setState({
								notificationTitle: { ...(notificationTitle || {}), [language]: e.target.value },
								hasUnsavedFields: true,
							});
						}}
						disabled={!notifyUsers}
					/>
					<label className="SingleLabel __required">{Strings.notifications.text}</label>
					<Input.TextArea
						placeholder={Strings.notifications.text}
						defaultValue={notificationDescription?.[language] || ''}
						value={notificationDescription?.[language] || ''}
						style={{ minHeight: '120px' }}
						onChange={e => {
							this.setState({
								notificationDescription: {
									...(notificationDescription || {} ),
									[language]: e.target.value,
								},
								hasUnsavedFields: true,
							});
						}}
						disabled={!notifyUsers}
					/>
				</>}
			</ContentWrapper>
		)
	}

	renderNewContent() {
		const { description, language } = this.state;

		return (
			<ContentWrapper>
				<label className="SingleLabel __required">{Strings.pages.content}</label>
				<Editor
					key={`editor_${language}`}
					required
					init={{ height: 500 }}
					value={description?.[language] || ''}
					onChange={(value: any) =>
						this.setState({ description: { ...description, [language]: value }, hasUnsavedFields: true })
					}
				/>
			</ContentWrapper>
	)
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.news.header}</title>
					<meta name="description" content="Description of News" />
				</Helmet>
				{this.renderGeneralInfo()}
				{this.renderNotification()}
				{this.renderNewContent()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(NewsDetails);