/*
*
* EmailTemplates
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, delayedDispatch, setBreadcrumb, updateCrumb } from 'store/actions';
import { Helmet } from 'react-helmet';
import { Table } from 'components';
import { API, Endpoints } from 'utils/api';
import { push } from 'connected-react-router';
import { toast } from 'utils/utils';
import Strings from 'utils/strings';
import './styles.scss';

export class EmailTemplates extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			templates: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.settings.emailTemplates));


		delayedDispatch(
            setBreadcrumb(() => {
                return {
                    locations: [
                        {
						  text: Strings.sidebar.settings,
						  route: '/settings',
                          icon: "preferences",
						},
						{
							text: Strings.settings.emailTemplates,
							icon: "testimonial",
						},
					],
                }
            })
		);

		this.getEmails();
	}
	
	componentDidUpdate() {
		const { dispatch } = this.props;
        dispatch(updateCrumb());
	}

	async getEmails() {
		const response = await API.get({
			url: Endpoints.uriEmailTemplate(),
		});
		if (response.ok) {
			this.setState({ templates: response.data.results });
		}
		if (response.problem)
			toast.error(response.data?.message || Strings.serverErrors.title);

	}

	render() {
		const { templates = [] } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.emailTemplates}</title>
					<meta name="description" content="Description of Email Templates" />
				</Helmet>
				<Table
					title={{
						icon: "testimonial",
						title: Strings.settings.emailTemplates
					}}
					data={templates}
					columns={[
						{
							Header: Strings.templates.single,
							id: 'key',
							accessor: (row: any) => row.key,
						},
					]}
					filterable
					actions={{
						edit: (original, value) => ({
							onClick: () => dispatch(push('email-templates/' + original._id)),
						}),
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(EmailTemplates);