/*
*
* SoilFertilization
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setLoader, setBreadcrumb, delayedDispatch, updateCrumb } from 'store/actions';
import { Helmet } from 'react-helmet';
import { ContentWrapper, Table } from 'components';
import { EditSidebar } from 'components/EditSidebar';
import { Select, Slider, Input } from "antd";
import { push } from 'connected-react-router';
import { API, Endpoints } from "utils/api";
import { toast } from 'utils/utils';
import moment from 'moment';
import Strings from 'utils/strings';
import './styles.scss';

const { Option } = Select;

export class SoilFertilization extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			soilFertilization: {},
			hasUnsavedFields: false,

			statesOptions: [],
			soilNutrientsOptions: [],

			language: 'pt',
			sidebarLanguage: 'pt',
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.fertilizing.header));

		delayedDispatch(
			setBreadcrumb(() => {
				const { language } = this.state;
				const { match, cultureName } = this.props;

				return {
					locations: [
						{
							text: Strings.sidebar.cultures,
							route: "/cultures",
							icon: "planting",
						},
						{
							text: cultureName?.[language],
							route: `/cultures/${match.params.id}`,
							icon: "planting",
						},
						{
							text: Strings.fertilizing.header,
							route: `/cultures/${match.params.id}`,
							icon: "fertilizer",
						},
						{
							text: match.params.fertilizationId === 'new' ? 'new' : this.state.soilFertilization?.country?.name,
							// route: `/parts/${match.params.id}`,
							icon: match.params.fertilizationId === 'new' ? 'adicionar-acrescentar' : 'lapis',
						},
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							onClick: this.submitFertilization,
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							isSave: true,
						},
					]
				}
			})
		);

		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	getData = async () => {
		const { language } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const [ soilNutrientsResponse ] = await Promise.all([
			API.get({
				url: Endpoints.uriSoilNutrients(),
			}),
			this.getSoilFertilization(),
		]);

		if (soilNutrientsResponse.ok && soilNutrientsResponse.data.results) {
			const soilNutrients = soilNutrientsResponse.data.results;
			this.setState({
				soilNutrients,
				soilNutrientsOptions: soilNutrients?.map((elem: any) => <Option key={elem._id} value={elem._id}>{elem.name?.[language]}</Option>),
			});
		} else {
			toast.error(soilNutrientsResponse.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	}

	getSoilFertilization = async () => {
		const { dispatch, match } = this.props;

		try {
			const response = await API.get({
				url: Endpoints.uriCultures(`${match.params.id}`),
			})
			if (response.ok) {
				const { culture } = response.data.results;

				const soilFertilization = culture.soilFertilization.find((elem: any) => elem._id === match.params.fertilizationId)

				this.setState({
					culture,
					soilFertilization: soilFertilization || {} ,
					statesOptions: soilFertilization?.country.states?.map((aux: any) => <Option key={aux._id} value={aux._id}>{aux.name}</Option>) || [],
					states: soilFertilization?.country.states || [],
				}, () => this.getCountries());
			} else {
				return toast.error(response.data?.message || Strings.serverErrors.title);
			}
		} catch (err) {
			return toast.error(err || Strings.serverErrors.title);
		} finally {
			dispatch(setLoader(false));
		}
	}

	getCountries = async () => {
		const { culture } = this.state;
		const { dispatch, match } = this.props;

		try {
			const response = await API.get({
				url: Endpoints.uriCountriesPromoters(),
			});
			if (response.ok) {
				const countries = response.data.results;

				const availableCountriesStates = JSON.parse(JSON.stringify(countries));

				if (culture.soilFertilization?.length) {
					for (let country of countries) {
						const exist = culture.soilFertilization.find((elem: any) => country._id === elem.country._id && match.params.fertilizationId !== elem._id);
						let index = -1;
						if (exist) index = availableCountriesStates?.findIndex((elem: any) => country._id === elem._id && match.params.fertilizationId !== elem._id);

						if (index !== -1) availableCountriesStates.splice(index, 1);
					}
				}

				this.setState({
					countries: availableCountriesStates,
				});
			} else {
				return toast.error(response.data?.message || Strings.serverErrors.title);
			}
		} catch (err) {
			return toast.error(err || Strings.serverErrors.title);
		} finally {
			dispatch(setLoader(false));
		}
	}

	submitFertilization = async () => {
		const { soilFertilization } = this.state;
		const { dispatch, match } = this.props;

		if (!soilFertilization.country?._id || !soilFertilization.states.length) return toast.warning(Strings.errors.invalidFields);

		const body = JSON.parse(JSON.stringify(soilFertilization));

		body.country = body.country._id;
		body.states = [];
		
		for (let state of soilFertilization.states) {
			state.state = state.state.name || state.state;
			state.soilNutrients = state.soilNutrients.map((elem: any) => ({
				soilNutrient: elem.soilNutrient._id,
				lessFrequent: elem.lessFrequent,
				frequent: elem.frequent,
				veryFrequent: elem.veryFrequent,
			}));
			body.states.push(state);
		}

		try {
			const request = match.params.fertilizationId === 'new' ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriCultures(`${match.params.id}/soil-fertilizing/${match.params.fertilizationId !== 'new' ? match.params.fertilizationId : ''}`),
				data: body,
			});
			if (response.ok) {
				const culture = response.data.results;

				dispatch(push(`/cultures/${culture._id}`));
			} else {
				return toast.error(response.data?.message || Strings.serverErrors.title);
			}
		} catch (err) {
			return toast.error(err || Strings.serverErrors.title);
		} finally {
			dispatch(setLoader(false));
		}
	}

	submitState = () => {
		const { item, soilFertilization } = this.state;
		if (!item.state) {
				return toast.error(Strings.errors.invalidFields);
		}

		if (item.index >= 0) {
			soilFertilization.states?.splice(item.index, 1, item);
		} else {
			item.soilNutrients = [];
			soilFertilization.states.push(item);
		}
		this.setState({ soilFertilization, openSidebar: false, hasUnsavedFields: true });
	}

	submitSoilNutrients = () => {
		const { item, soilFertilization, soilNutrients } = this.state;
		if (!item.soilNutrient ||
			!item.lessFrequent?.description ||
			!item.frequent?.description ||
			!item.veryFrequent?.description) {
				return toast.error(Strings.errors.invalidFields);
		}
		item.soilNutrient = soilNutrients.find((elem: any) => elem._id === (item.soilNutrient?._id || item.soilNutrient));
		item.lessFrequent.value = item.lessFrequent.value || 0;
		item.frequent.value = item.frequent.value || 0;
		item.veryFrequent.value = item.veryFrequent.value || 0;

		if (item.index >= 0) {
			soilFertilization.states[item.stateIndex]?.soilNutrients?.splice(item.index, 1, item);
		} else {
			if (!soilFertilization.states[item.stateIndex])
				soilFertilization.states[item.stateIndex] = { soilNutrients: [] };
			else if (!soilFertilization.states[item.stateIndex].soilNutrients)
				soilFertilization.states[item.stateIndex].soilNutrients = [];

			soilFertilization.states[item.stateIndex].soilNutrients.push(item);
		}
		this.setState({ soilFertilization, openSidebar: false, hasUnsavedFields: true });
	}

	openSidebar = (value: any, sidebarType: string) => {
		this.setState({
			openSidebar:true,
			item: JSON.parse(JSON.stringify(value)),
			sidebarType,
		});
	}


	closeSidebar = () => {
		this.setState({ openSidebar: false, sidebarType: null });
	}

	onChange = (field: string, value: any) => {
		const { item, states } = this.state;

		if (field === 'state') {
			this.setState({
				item: { ...item, state: states.find((elem: any) => elem._id === value) }
			});
		} else {
			this.setState({ item: { ...item, [field]: value } });
		}

		
	}

	removeState(index: any, stateId: any = null) {
		const { soilFertilization } = this.state;

		if (!stateId) {
			soilFertilization.states.splice(index, 1);
		} else {
			soilFertilization.states[stateId].soilNutrients.splice(index, 1);
		}

		this.setState({ soilFertilization });
	}

	renderSoilFertilityFields = () => {
		const { item, sidebarLanguage } = this.state;
		return (
			<>
			<label className="SingleLabel">{Strings.soilNutrients.lessFrequent}</label>
				<Slider
					min={0}
					max={100}
					tooltipVisible
					value={item?.lessFrequent?.value || 0}
					onChange={(elem: any) => {
						this.setState({
							item: {
								...item,
								lessFrequent: {
									...item.lessFrequent,
									value: elem
								},
							}
						})}
					} />
				<Input
					// type={type}
					placeholder={Strings.fields.description}
					value={item?.lessFrequent?.description?.[sidebarLanguage]}
					onChange={e => {
						e.preventDefault();

						this.setState({
							item: {
								...item,
								lessFrequent: {
									...item.lessFrequent,
									description: {
										...item.lessFrequent?.description,
										[sidebarLanguage]: e.target.value,
									},
								},
							}
						})
					}} />
				<label className="SingleLabel">{Strings.soilNutrients.frequent}</label>
				<Slider
					min={0}
					max={100}
					tooltipVisible
					value={item?.frequent?.value || 0}
					onChange={(elem: any) => {
						this.setState({
							item: {
								...item,
								frequent: {
									...item.frequent,
									value: elem
								},
							}
						})
					}} />
				<Input
					// type={type}
					placeholder={Strings.fields.description}
					value={item?.frequent?.description?.[sidebarLanguage]}
					onChange={e => {
						e.preventDefault();

						this.setState({
							item: {
								...item,
								frequent: {
									...item.frequent,
									description: {
										...item.frequent?.description,
										[sidebarLanguage]: e.target.value,
									},
								},
							}
						})
					}} />
				<label className="SingleLabel">{Strings.soilNutrients.veryFrequent}</label>
				<Slider
					min={0}
					max={100}
					tooltipVisible
					value={item?.veryFrequent?.value || 0}
					onChange={(elem: any) => {
						this.setState({
							item: {
								...item,
								veryFrequent: {
									...item.veryFrequent,
									value: elem
								},
							}
						})}
					} />
				<Input
					// type={type}
					placeholder={Strings.fields.description}
					value={item?.veryFrequent?.description?.[sidebarLanguage]}
					onChange={e => {
						e.preventDefault();

						this.setState({
							item: {
								...item,
								veryFrequent: {
									...item.veryFrequent,
									description: {
										...item.veryFrequent?.description,
										[sidebarLanguage]: e.target.value,
									},
								},
							}
						})
					}} />
			</>
		);
	}

	renderDrawer() {
		const { item, statesOptions, soilNutrientsOptions, sidebarType, sidebarLanguage } = this.state;

		const fields = sidebarType === 'state' ? [{
			field: 'state',
			value: item?.state?._id || [],
			type: 'selector',
			populated: true,
			options: statesOptions,
			name: Strings.fields.states,
			required: true,
		}] : [{
			field: 'soilNutrient',
			value: item?.soilNutrient?._id || [],
			type: 'selector',
			populated: true,
			options: soilNutrientsOptions,
			name: Strings.soilNutrients.header,
			required: true,
		}];

		return (
			<EditSidebar
				title={sidebarType === 'state' ? Strings.fields.state : Strings.soilNutrients.header}
				open={this.state.openSidebar}
				onClose={this.closeSidebar}
				onChange={this.onChange}
				onSubmit={sidebarType === 'state' ? this.submitState : this.submitSoilNutrients}
				defaultValue={this.state.item}
				language={sidebarType === 'soilNutrients' ? sidebarLanguage : undefined}
				onChangeLanguage={(lang: any) =>
					this.setState({ sidebarLanguage: lang })
				}
				closable={false}
				fields={[
					...fields,
					{
						version: moment.now(),
					}
				]}
				extraFields={sidebarType === 'soilNutrients' && this.renderSoilFertilityFields}
			/>
		);
	}

	renderStateSoilNutrients(index: number) {
		const { soilFertilization, language } = this.state;

		return (
			<>
				<Table
					title={{
						icon: "shovel",
						title: Strings.settings.soilNutrients,
					}}
					data={soilFertilization.states[index].soilNutrients}
					columns={[
						{
							Header: Strings.soilNutrients.header,
							id: 'soilNutrient',
							accessor: (row: any) => row.soilNutrient?.name?.[language],
						},
						{
							Header: Strings.soilNutrients.lessFrequent,
							id: 'lessFrequent',
							accessor: (row: any) => `${row.lessFrequent.description?.[language]} - ${row.lessFrequent.value}%`,
						},
						{
							Header: Strings.soilNutrients.frequent,
							id: 'frequent',
							accessor: (row: any) => `${row.frequent.description?.[language]} - ${row.frequent.value}%`,
						},
						{
							Header: Strings.soilNutrients.veryFrequent,
							id: 'veryFrequent',
							accessor: (row: any) => `${row.veryFrequent.description?.[language]} - ${row.veryFrequent.value}%`,
						},
					]}
					add={{
						label: String(Strings.formatString(Strings.generic.addNew, Strings.soilNutrients.header.toLowerCase())),
						onClick: () => {
							if (!soilFertilization?.country) return toast.warning(Strings.errors.selectCountryFirst)
							this.openSidebar({ stateIndex: index }, 'soilNutrients' )
						}
					}}
					actions={{
						edit: (original, value) => ({
							onClick: () => this.openSidebar({
								...original,
								stateIndex: index,
								index: soilFertilization.states[index]?.soilNutrients?.findIndex((elem: any) => elem === original)
							}, 'soilNutrients')
						}),
						remove: (original, value) => ({
							onClick: () => this.removeState(soilFertilization.states[index]?.soilNutrients?.findIndex((elem: any) => elem === original), index),
						}),
					}}
				/>
				{this.renderDrawer()}
			</>
		)
	}

	renderStatesTable() {
		const { soilFertilization } = this.state;

		return (
			<>
				<Table
					title={{
						icon: "placeholder",
						title: Strings.fields.states,
					}}
					data={soilFertilization.states}
					columns={[
						{
							Header: Strings.fertilizing.production,
							id: 'state',
							accessor: (row: any) => row.state?.name || row.state,
						},
					]}
					expanded
					renderExpanded={(elem: any) => this.renderStateSoilNutrients(elem.index)}
					add={{
						label: String(Strings.formatString(Strings.generic.addNew, Strings.fields.states.toLowerCase())),
						onClick: () => {
							if (!soilFertilization?.country) return toast.warning(Strings.errors.selectCountryFirst)
							this.openSidebar({ }, 'state' )
						}
					}}
					actions={{
						edit: (original, value) => ({
							onClick: () => this.openSidebar({
								...original,
								index: soilFertilization.states?.findIndex((elem: any) => elem === original)
							}, 'state')
						}),
						remove: (original, value) => ({
							onClick: () => this.removeState(soilFertilization.states?.findIndex((elem: any) => elem === original)),
						}),
					}}
				/>
				{this.renderDrawer()}
			</>
		)
	}

	render() {
		const { soilFertilization, countries } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.soilFertility.header}</title>
					<meta name="description" content="Description of SoilFertilization" />
				</Helmet>
				<ContentWrapper>
					<label className="SingleLabel __required">{Strings.fields.country}</label>
					<Select
						key={soilFertilization.country?._id}
						className="tagsSelector"
						style={{ width: '100%', marginBottom: 10 }}
						placeholder={Strings.fields.country}
						allowClear
						showSearch
						defaultValue={soilFertilization.country?._id}
						onChange={(elem: any) => {
							const country = countries?.find((aux: any) => aux._id === elem)
							
							this.setState({
								soilFertilization: { ...soilFertilization, country, states: [] },
								statesOptions: country?.states?.map((aux: any) => <Option key={aux._id} value={aux._id}>{aux.name}</Option>) || [],
								states: country?.states || [],
								hasUnsavedFields: true,
							})}
						}>
						{countries?.map((elem: any) => <Option key={elem._id} value={elem._id}>{elem.name}</Option>)}
					</Select>
					{this.renderStatesTable()}
				</ContentWrapper>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	cultureName: state.router?.location?.state?.cultureName,
});

export default connect(mapStateToProps)(SoilFertilization);