/*
 *
 * Products
 *
 */

import React from "react";
import { connect } from "react-redux";
import {
  setTitle,
  setLoader,
  setBreadcrumb,
  delayedDispatch,
  updateCrumb,
} from "store/actions";
import { Table } from "components";
import { Helmet } from "react-helmet";
import { push } from "connected-react-router";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";

import "./styles.scss";

export class Products extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      language: "pt",

      uploadFile: "",
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle(Strings.sidebar.products));

    delayedDispatch(
      setBreadcrumb(() => {
        return {
          locations: [
            {
              text: Strings.sidebar.products,
              //   route: "/products",
              icon: "fertilizer",
            },
          ],
        };
      })
    );

    this.getProducts();
  }

  componentDidUpdate() {
    const { dispatch } = this.props;

    dispatch(updateCrumb());
  }

  async getProducts() {
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    const response = await API.get({
      url: Endpoints.uriProducts(),
    });

    if (response.ok) {
      this.setState({ products: response.data.results });
    } else {
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  }

  async patchProduct(product: any) {
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    const response = await API.patch({
      url: Endpoints.uriProducts(product._id),
      data: { isActive: !product.isActive },
    });

    if (response.ok) {
      this.setState({ products: response.data.results });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  }

  async deleteProduct(product: any) {
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    const response = await API.delete({
      url: Endpoints.uriProducts(product._id),
    });

    if (response.ok) {
      this.setState({ products: response.data.results });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  }

  onDrag = async () => {
		const { products, hoverIndex } = this.state;
		const { dispatch } = this.props;
	
		const item = products[hoverIndex];
		const itemId = item?._id;
	
		if (!itemId || item.pos === hoverIndex) return;
	
		dispatch(setLoader(true));
	
		await API.patch({
			url: Endpoints.uriProducts(`position/${itemId}`),
			data: { pos: hoverIndex },
		})
			.then((response) => {
				if (response.ok && response.data.results) {
				this.setState({ products: response.data.results });
				} else
				toast.error(response.data?.message || Strings.serverErrors.title);
			})
			.catch((err) => {
				toast.error(err);
			});
	
		dispatch(setLoader(false));
	};

  render() {
    const { products = [], language } = this.state;
    const { dispatch } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.products}</title>
          <meta name="description" content="Description of Products" />
        </Helmet>
        <Table
          title={{
            icon: "fertilizer",
            title: Strings.sidebar.products,
          }}
          data={products}
          columns={[
            {
              Header: Strings.fields.image,
              id: "image",
              type: "image",
              accessor: (row: any) => row.image,
              maxWidth: 80,
            },
            {
              Header: Strings.products.header,
              id: "name",
              accessor: (row: any) => row.name?.[language],
            },
          ]}
          filterable
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
					  this.setState({ products: list, dragIndex, hoverIndex })
					}}
					onDragEnd={this.onDrag}
          add={{
            label: String(
              Strings.formatString(
                Strings.generic.addNew,
                Strings.products.header.toLowerCase()
              )
            ),
            onClick: () => dispatch(push("/products/new")),
          }}
          actions={{
            edit: (original, value) => ({
              onClick: () => dispatch(push(`/products/${original._id}`)),
            }),
            remove: (original, value) => ({
              onClick: () => this.deleteProduct(original),
            }),
            toggle: (original, value) => ({
              value: original.isActive,
              onChange: () => this.patchProduct(original),
            }),
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Products);
