/*
 *
 * Documents
 *
 */

import React from "react";
import { connect } from "react-redux";
import {
  setTitle,
  setLoader,
  setBreadcrumb,
  delayedDispatch,
  updateCrumb,
} from "store/actions";
import { Table } from "components";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";
import { EditSidebar } from "components/EditSidebar";
import moment from "moment";
//import "./styles.scss";

export class Documents extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth < 900 ? window.innerWidth - 20 : 900,
      tempTraining: {},
      language: "pt",
      documents: [],
      item: {},
      sidebarLanguage: "pt",
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle(Strings.sidebar.documents));

    delayedDispatch(
      setBreadcrumb(() => {
        return {
          locations: [
            {
              text: Strings.sidebar.documents,
              //route: "/documents",
              icon: "file",
            },
          ],
        };
      })
    );

    this.getDocuments();
  }

  componentDidUpdate() {
    const { dispatch } = this.props;

    dispatch(updateCrumb());
  }

  async getDocuments() {
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    try {
      const response = await API.get({
        url: Endpoints.uriDocuments(),
      });

      if (response.ok) {
        this.setState({
          documents: response.data.results || [],
        });
      }
    } catch (err) {
      console.log("API Error", err);
    }

    dispatch(setLoader(false));
  }

  onDelete = async (id: any) => {
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    await API.delete({ url: Endpoints.uriDocuments(id) })
      .then((response) => {
        if (response.ok && response.data.results) {
          this.setState({
            documents: response.data.results,
            openSidebar: false,
            item: {},
          });
        } else
          toast.error(response.data?.message || Strings.serverErrors.title);
      })
      .catch((err) => {
        toast.error(err);
      });

    dispatch(setLoader(false));
  };

  createDocument = async () => {
    const { item, documents } = this.state;
    const { dispatch } = this.props;

    dispatch(setLoader(true));
    const request = item._id ? API.put : API.post;
    const id = item._id || "";

    const data = new FormData();
    data.append("name", JSON.stringify(item.name));

    const auxFilesList: any = JSON.parse(JSON.stringify(item.file));
    for (const lang of Object.keys(item.file)) {
      if (item.file[lang]?.[0]?.file) {
        data.append(`file_${lang}`, item.file[lang][0].file);
        auxFilesList[lang] = "";
      } else if (typeof item.file[lang] === "string") {
        auxFilesList[lang] = item.file[lang];
      }
    }
    data.append("file", JSON.stringify(auxFilesList));

    const response = await request({
      url: Endpoints.uriDocuments(id),
      data,
    });

    if (response.ok) {
      if (item._id) {
        const index = documents.findIndex((elem: any) => elem._id === item._id);
        documents.splice(index, 1, response.data.results);
      } else {
        documents.push(response.data.results);
      }
      this.setState({
        documents,
        openSidebar: false,
        item: {},
      });
    } else {
      dispatch(setLoader(false));
      toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  onChange = (field: string, value: any) => {
    const { item, sidebarLanguage } = this.state;

    this.setState({
      item: {
        ...item,
        [field]: {
          ...item[field],
          [sidebarLanguage]: value,
        },
      },
    });
  };

  closeSidebar = () => {
    this.setState({ openSidebar: false });
  };

  openSidebar = (value: any) => {
    this.setState({
      openSidebar: true,
      item: JSON.parse(JSON.stringify(value)),
    });
  };

  renderDrawer() {
    const { item, sidebarLanguage } = this.state;
    return (
      <EditSidebar
        title={Strings.documents.header}
        open={this.state.openSidebar}
        onClose={this.closeSidebar}
        onChange={this.onChange}
        onSubmit={this.createDocument}
        defaultValue={item}
        language={sidebarLanguage}
        onChangeLanguage={(lang: any) =>
          this.setState({ sidebarLanguage: lang })
        }
        closable={false}
        fields={[
          {
            field: "name",
            value: item?.name?.[sidebarLanguage],
            type: "input",
            name: Strings.fields.name,
            required: true,
          },
          {
            field: "file",
            value: item?.file?.[sidebarLanguage],
            type: "filePicker",
            name: Strings.documents.file,
          },
          {
            version: moment.now(),
          },
        ]}
      />
    );
  }

  render() {
    const { documents = [], language } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.documents}</title>
          <meta name="description" content="Description of Documents" />
        </Helmet>
        <Table
          title={{
            icon: "file",
            title: Strings.sidebar.documents,
          }}
          data={documents}
          columns={[
            {
              Header: Strings.documents.header,
              id: "name",
              accessor: (row: any) => row.name?.[language],
            },
          ]}
          filterable
          add={{
            label: String(
              Strings.formatString(
                Strings.generic.addNew,
                Strings.documents.header.toLowerCase()
              )
            ),
            onClick: () => this.openSidebar({}),
          }}
          actions={{
            edit: (original, value) => ({
              onClick: () => this.openSidebar(original),
            }),
            remove: (original, value) => ({
              onClick: () => this.onDelete(original._id),
            }),
          }}
        />
        {this.renderDrawer()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Documents);
