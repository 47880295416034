/*
*
* Users
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setLoader, setTitle, delayedDispatch, setBreadcrumb, updateCrumb } from 'store/actions';
// @ts-ignore
import variables from 'styles/variables.scss';
import { Modal } from "antd";
import { Table } from 'components';
import { Helmet } from 'react-helmet';
import Strings from 'utils/strings';
import './styles.scss';
import { API, Endpoints } from 'utils/api';
// @ts-ignore
import Workbook from 'react-excel-workbook';
import "./styles.scss";

export class Users extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			exportModal: false,
			users: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.users));

		delayedDispatch(
            setBreadcrumb(() => {
                return {
                    locations: [
                        {
                          text: Strings.sidebar.users,
                          icon: "user2",
                        },
					],
                }
            })
		);

		this.getUsers();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
        dispatch(updateCrumb());
	}

	async getUsers() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const response = await API.post({
			url: Endpoints.uriUsers(`search`),
			date: {},
		});

		if (response.ok) {
			this.setState({ users: response.data.results.users || [] });
		}

		dispatch(setLoader(false));
	}

	onExport = () => {
		this.setState({ exportModal: true });
	};

	renderExport() {
		const { exportModal, users } = this.state;
		return (
			<Modal
				centered
				width='500px'
				visible={!!exportModal}
				cancelButtonProps={{ className: "displayNone" }}
				okButtonProps={{ className: "displayNone" }}
				onCancel={() => {
					this.setState({ exportModal: null });
				}}
			>
				<div
					className="BBModal"
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<h1>users.xlsx</h1>
					{exportModal && this.renderExportUsers()}
				</div>
			</Modal>
		);
	}

	renderExportUsers() {
		const { users } = this.state;
		return  <>
		<h2 style={{ color: variables.textColor, margin: '15px' }}>
			{users.length.toString().concat(' ', Strings.sidebar.users.toLowerCase())}
		</h2>
		<Workbook
			filename="users.xlsx"
			element={
				<button
					style={{
						backgroundColor: variables.primaryColor,
						borderColor: variables.primaryColor,
						color: 'white',
						padding: '10px 30px',
						borderRadius: '5px',
						cursor: 'pointer',
					}}
					onClick={() => {
						this.setState({ exportModal: null });
					}}
					className="btn btn-lg btn-primary">
					Download
				</button>
			}>
			<Workbook.Sheet data={users} name="Users">
				<Workbook.Column label={Strings.fields.name} value="name" />
				<Workbook.Column label={Strings.fields.email} value="email" />
				<Workbook.Column label={Strings.fields.acceptMarketing} value={(row: any) => row.acceptMarketing ? 'Sim' : 'Não'} />
			</Workbook.Sheet>
		</Workbook>
	</>
  }

	removeUser = async (id: string) => {
		const { dispatch } = this.props;
	
		dispatch(setLoader(true));
	
		try {
		  const response = await API.patch({
			url: Endpoints.uriUsers(`${id}/forget-user`),
		  });
	
		  if (response.ok) {
			this.setState({
			  users: response.data.results.users || [],
			});
		  }
		} catch (err) {
		  console.log("API Error", err);
		}
	
		dispatch(setLoader(false));
	};

	render() {
		const { users = [] } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.users}</title>
					<meta name="description" content="Description of Users" />
				</Helmet>
				<Table
					title={{
						icon: "user2",
						title: Strings.sidebar.users
					}}
					data={users}
					columns={[
						{
							Header: Strings.fields.name,
							id: 'name',
							accessor: (row: any) => row.name,
						},
						{
							Header: Strings.fields.email,
							id: 'email',
							accessor: (row: any) => row.email,
						},
						{
							Header: Strings.fields.acceptMarketing,
							id: 'acceptMarketing',
							accessor: (row: any) => row.acceptMarketing ? 'Sim' : 'Não',
						},
					]}
					exportable
          			exportData={() => this.onExport()}
					filterable
					actions={{
						remove: (original, value) => ({
							onClick: () => this.removeUser(original._id),
						}),
					}}
				/>
				{this.renderExport()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Users);