/*
*
* Notification
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setLoader, setBreadcrumb, delayedDispatch, updateCrumb } from 'store/actions';
import { Helmet } from 'react-helmet';
import { Row, Col, Input, DatePicker, Select } from "antd";
import { ContentWrapper } from "components";
import { EditSidebar } from 'components/EditSidebar';
import { push } from 'connected-react-router';
import Strings from 'utils/strings';
import { API, Endpoints } from "utils/api";
import { toast } from 'utils/utils';
import { DateTime } from 'luxon';
import moment from 'moment';
import './styles.scss';

const { Option } = Select;

export class Notification extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			language: 'pt',
			countries: [],
			timezones: [],
			notification: {
				timezone: "Europe/Lisbon"
			},
		};
	}

	componentDidMount() {
		const { match, dispatch } = this.props;

		// change this
		dispatch(setTitle('Notification'));

		delayedDispatch(
			setBreadcrumb(() => {
				const { language } = this.state;
				const { match } = this.props;

				return {
					locations: [
						{
						  text: Strings.sidebar.notifications,
						  route: "/notifications",
						  icon: "bell",
						},
						{
							text: match.params.id === 'new' ? 'new' : this.state.notification?.title?.[this.state.language],
							// route: `/parts/${match.params.id}`,
							icon: match.params.id === 'new' ? 'adicionar-acrescentar' : 'lapis',
						},
					],
					actions: [
						{
							type: "language",
							value: language,
							onChange: (lang: any) => this.setState({ language: lang }),
						},
						{
							type: "button",
							text: Strings.generic.save,
							onClick: this.postNotification,
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							separator: 'left',
							isSave: true,
						},
					]
				}
			})
		);

		this.getData();
		if(match.params.id !== 'new') this.getNotification();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getNotification() {
		const { match } = this.props;

		const response = await API.get({
			url: Endpoints.uriNotifications(match.params.id),
		});
	  
		if (response.ok && response.data.results) {
			this.setState({
				notification: {
					...response.data.results,
					timezone: response.data.results.timezone || "Europe/Lisbon"
				}
			});
		} else {
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
	}

	getData = async () =>  {
		const [ culturesResponse, productsResponse, countriesResponse ] = await Promise.all([
			API.get({
				url: Endpoints.uriCultures(),
			}),
			API.get({
				url: Endpoints.uriProducts(),
			}),
			API.get({
				url: Endpoints.uriCountries(),
			})
		]);
	  
		if (culturesResponse.ok && culturesResponse.data.results) {
			this.setState({ cultures: culturesResponse.data.results });
		} else {
			toast.error(culturesResponse.data?.message || Strings.serverErrors.title);
		}

		if (productsResponse.ok && productsResponse.data.results) {
			this.setState({ products: productsResponse.data.results });
		} else {
			toast.error(productsResponse.data?.message || Strings.serverErrors.title);
		}

		if (countriesResponse.ok && countriesResponse.data.results) {
			const countries = countriesResponse.data.results;
			const portugal = countries.find((elem: any) => elem.name === "Portugal");

			this.setState({
				countries,
				notification: {
					...this.state.notification,
					country: this.state.notification.country || portugal._id,
				},
				timezones: portugal.timezones,
			});
		} else {
			toast.error(countriesResponse.data?.message || Strings.serverErrors.title);
		}
	}

	isValid() {
		const { notification } = this.state;

		return notification.title && notification.description && notification.timezone && notification.scheduleDate;
	}

	postNotification = async () => {
		const { match, dispatch } = this.props;
		const { notification } = this.state;

		if(!this.isValid()) {
			return toast.warn(Strings.errors.invalidFields);
		}

		dispatch(setLoader(true));

		console.log('POST Notification')
		const request = match?.params?.id === 'new' ? API.post : API.put;

		const response = await request({
		  url: Endpoints.uriNotifications(match?.params?.id !== 'new' ? match?.params?.id : ''),
		  data: {
			...notification,
		  },
		});

		if (response.ok) {
		  	this.setState({ product: response.data.results, hasUnsavedFields: false });
		  	dispatch(push('/'));
			dispatch(push(`/notifications/${response.data.results._id}`))
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	}

	onSubmit = () => {
		const { item, notification } = this.state;

		if (!item.user) toast.error(Strings.errors.invalidFields);

		this.setState({ notification: { ...notification, user: item.user } });
	}

	onChange = (field: string, value: any) => {
		const { item, language } = this.state;
		this.setState({ item: { ...item, [field]: { ...item[field], [language]: value} } }, () => console.log('item', item));
	}

	openSidebar = (value: any) => {
		this.setState({ openSidebar:true, item: JSON.parse(JSON.stringify(value)) });
	}

	closeSidebar = () => {
		this.setState({ openSidebar: false });
	}

	renderUserDrawer() {
		const { userSearch } = this.state;

		return (
			<EditSidebar
				title={Strings.nutrients.header}
				open={this.state.openSidebar}
				onClose={this.closeSidebar}
				onChange={this.onChange}
				onSubmit={this.onSubmit}
				defaultValue={this.state.item}
				closable={false}
				fields={[
					{
						field: 'userSearch',
						value: userSearch,
						type: 'input',
						name: Strings.generic.search,
						required: true,
					},
					{
						version: DateTime.utc(),
					}
				]}
			/>
		);
	}

	renderSchedule() {
		const { notification, countries, timezones } = this.state;

		return (
			<Row gutter={[12, 12]} style={{ paddingTop: '10px' }}>
					<Col xs={24}>
						<DatePicker
							value={notification.scheduleDate ? moment(notification.scheduleDate) : null}
							format='DD-MM-YYYY HH:mm'
							style={{ width: '100%' }}
							showTime
							placeholder={Strings.publication.publicationDate}
							onChange={(date: any) => this.setState({ hasUnsavedFields: true, notification: { ...notification, scheduleDate: date } })}
						/>
					</Col>
					<Col xs={24} md={12}>
						{/* <label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.notifications.timezone}</label> */}
						<Select
							showSearch
							placeholder={Strings.fields.countries}
							optionFilterProp="children"
							style={{ width: '100%' }}
							onChange={(countryId: any) => {
								const country = countries.find((elem: any) => elem._id === countryId);
								this.setState({
									timezones: country.timezones,
									notification: {
										...notification,
										country: countryId,
										timezone: country.timezones.find((elem: any) => elem === notification.timezone),
									},
									hasUnsavedFields: true,
								})
							}}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={notification.country}
						>
							{countries.map((elem: any) => (
								<Option value={elem._id}>{elem.name}</Option>)
							)}
						</Select>
					</Col>
					<Col xs={24} md={12}>
						{/* <label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.notifications.timezone}</label> */}
						<Select
							showSearch
							placeholder={Strings.notifications.timezone}
							optionFilterProp="children"
							style={{ width: '100%' }}
							onChange={(timezone: any) =>
								this.setState({
									notification: { ...notification, timezone },
									hasUnsavedFields: true,
								})
							}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={notification.timezone}
						>
							{timezones.map((elem: any) => (
								<Option value={elem}>{elem}</Option>)
							)}
						</Select>
					</Col>
				</Row>
		)
	}

	render() {
		const { notification, cultures = [], products = [], language } = this.state;
		return (
			<ContentWrapper>
				<Helmet>
					<title>{Strings.notifications.header}</title>
					<meta name="description" content="Description of Notification" />
				</Helmet>
				<label className="SingleLabel __required">{Strings.notifications.header}</label>
				<Input
					placeholder={Strings.notifications.header}
					defaultValue={notification?.title ? notification.title[language] : ''}
					value={notification?.title && notification.title[language]}
					onChange={e => {
						this.setState({
							notification: { ...notification, title: { ...(notification?.title || {}), [language]: e.target.value } },
							hasUnsavedFields: true,
						});
					}}
				/>
				{this.renderSchedule()}
				<Row gutter={12} style={{ paddingTop: '10px' }}>
					<Col xs={24} md={12}>
							<label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.sidebar.cultures}</label>
							<Select
								showSearch
								placeholder={Strings.sidebar.cultures}
								optionFilterProp="children"
								mode="multiple"
								style={{ width: '100%' }}
								onChange={(list: any) => {
									const culture = cultures.filter((elem: any) =>
										list.find((culture: any) => culture === elem._id)
									)
									this.setState({
										notification: {
											...notification,
											cultures: culture,
										},
										hasUnsavedFields: true,
									})
								}}
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								value={notification?.cultures?.map((elem: any) => elem._id)}
								allowClear
							>
								{cultures.map((elem: any) => (
									<Option value={elem._id}>{elem.name?.[language]}</Option>)
								)}
							</Select>
					</Col>
					<Col xs={24} md={12}>
						<label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.sidebar.products}</label>
						<Select
							showSearch
							placeholder={Strings.sidebar.products}
							optionFilterProp="children"
							mode="multiple"
							style={{ width: '100%' }}
							onChange={(list: any) =>
								this.setState({
									notification: {
										...notification,
										products: products.filter((elem: any) =>
											list.find((product: any) => product === elem._id)
										),
									},
									hasUnsavedFields: true,
								})
							}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={notification?.products?.map((elem: any) => elem._id)}
							allowClear
						>
							{products.map((elem: any) => (
								<Option value={elem._id}>{elem.name?.[language]}</Option>)
							)}
						</Select>
					</Col>
				</Row>
				<label className="SingleLabel __required">{Strings.notifications.text}</label>
				<Input.TextArea
					placeholder={Strings.notifications.text}
					value={notification?.description?.[language]}
					style={{ minHeight: '120px' }}
					onChange={(e: any) =>
						this.setState({
							notification: {
								...notification,
								description: {
									...(notification?.description || {} ),
									[language]: e.target.value,
								},
							},
							hasUnsavedFields: true,
						})
					}
				/>
			</ContentWrapper>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Notification);