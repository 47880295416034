/* eslint-disable no-restricted-globals */
/*
 *
 * PageDetail
 *
 */

import React from "react";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Form, Col, Row, Input } from "antd";
import { Helmet } from "react-helmet";
import { Editor } from "components";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { toast, translate } from 'utils/utils';
import "./styles.scss";

export class PageDetail extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: null,
      content: null,
      language: 'pt',
      hasUnsavedFields: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  async componentDidMount() {
    const { language } = this.state;
    const { dispatch, match } = this.props;

    dispatch(setLoader(true));

    let page;
    if (match?.params?.id === 'new') {
      dispatch(setTitle(`${Strings.pages.single} - ${Strings.pages.new}`))
    } else {
      const response = await API.get({
        url: Endpoints.uriPages(match?.params?.id),
      });

      if (response.ok) {
        page = response.data.results.pages;
        dispatch(setTitle(`${Strings.pages.single} - ${translate(page?.title?.[language])}`))
      }
    }

    this.setState({ ...page });

    delayedDispatch(
      setBreadcrumb(() => {
        const { hasUnsavedFields, language } = this.state;

        return {
          locations: [
            {
              text: Strings.sidebar.settings,
              route: "/settings",
              icon: "preferences",
            },
            {
              text: Strings.settings.pages,
              route: "/settings/pages",
              icon: "text-files",
            },
            {
              text: this.state.title?.[this.state.language],
              icon: "file",
            },
          ],
          actions: [
            {
              type: "language",
              value: language,
              onChange: this.handleLanguageChange,
              className: "BreadcrumbLanguage",
              margin: "left",
            },
            {
              type: "button",
              text: Strings.generic.save,
              onClick: this.handleSave,
              disabled: !hasUnsavedFields,
              className: hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
              separator: "left",
              isSave: true,
            },
          ],
        };
      })
    );

    dispatch(setLoader(false));
  }

  componentDidUpdate() {
    const { dispatch } = this.props;

    dispatch(updateCrumb());
  }

  isValid() {
    // validations

    return true;
  }

  async handleSave(event: any) {
    const { match, dispatch } = this.props;
    const { title, content } = this.state;

    if (!this.isValid()) {
      return toast.warn(Strings.errors.invalidFields);
    }

    dispatch(setLoader(true))

    const response = await API.put({
      url: Endpoints.uriPages(match?.params?.id),
      data: { title, content },
    });

    if (response.ok) {
      this.setState({ ...response.data.results.pages, hasUnsavedFields: false });
    }

    dispatch(setLoader(false))
  }

  handleLanguageChange(value: any, options?: any) {
    this.setState({ language: value });
  }

  render() {
    const { title, content, language } = this.state;
    const { match } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{match?.params?.id === 'new' ? Strings.pages.new : translate(title)}</title>
          <meta name="description" content="Description of Page Detail" />
        </Helmet>
        <Form key={`page_${language}_${title}_${content}`} layout="vertical" name="page">
          <Form.Item
            label={Strings.pages.title}
            name="title"
            initialValue={title?.[language] || ''}
            rules={[{ required: true, message: Strings.errors.fillField }]}>
              <Input
                placeholder={Strings.placeholders.titlePage}
                value={title?.[language] || ''}
                onChange={(e: any) => {
                  const { value } = e.target;
                  this.setState({
                    title: { ...title, [language]: value },
                    hasUnsavedFields: true,
                  });
                }}
              />
          </Form.Item>
        </Form>
        <Row>
          <Col xs={24}>
            <span className="SingleLabel __required">{Strings.pages.content}</span>
            <Editor
              key={`editor_${language}`}
              required
              init={{ height: 500 }}
              value={content?.[language]}
              onChange={(value: any) =>
                this.setState({ content: { ...content, [language]: value }, hasUnsavedFields: true })
              }
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps)(PageDetail);
