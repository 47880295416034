/*
*
* Pages
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, delayedDispatch, setBreadcrumb, updateCrumb } from 'store/actions';
import { Helmet } from 'react-helmet';
import { Table } from 'components';
import { API , Endpoints } from 'utils/api';
import { push } from 'connected-react-router';
import Strings from 'utils/strings';
import './styles.scss';

export class Pages extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			pages: [],
			language: 'pt',
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.settings.pages));

		delayedDispatch(
            setBreadcrumb(() => {
                return {
                    locations: [
                        {
						  text: Strings.sidebar.settings,
						  route: '/settings',
                          icon: "preferences",
						},
						{
							text: Strings.settings.pages,
							icon: "text-files",
						},
					],
                }
            })
		);

		this.getPages();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
        dispatch(updateCrumb());
	}

	getPages = async () => {
		const response = await API.get({
			url: Endpoints.uriPages(),
		});
		
		if (response.ok) {
			const { pages } = response.data.results;
			this.setState({ pages });
		}
	}

	patchPage = async ( original : any) => {
		let body = {isActive: !original.isActive};
		const response= await API.patch({
			url: Endpoints.uriPages(original._id),
			data: body,
		});

		if(response.ok){
			this.setState({ pages: response.data.results.pages });
		}
	}

	render() {
		const { pages = [], language } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.pages}</title>
					<meta name="description" content="Description of Pages" />
				</Helmet>
				<Table
					title={{
						icon: "text-files",
						title: Strings.settings.pages
					}}
					data={pages}
					columns={[
						{
							Header: Strings.pages.single,
							id: 'title',
							accessor: (row: any) => row.title?.[language],
						},
					]}
					filterable
					actions={{
						edit: (original, value) => ({
							onClick: () => dispatch(push(`/settings/pages/${original._id}`)),
						}),
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Pages);