/*
*
* AgronomicServices
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setLoader, delayedDispatch, setBreadcrumb, updateCrumb, } from 'store/actions';
import { Helmet } from 'react-helmet';
import { push } from "connected-react-router";
import { Table } from "components";
import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";
import Strings from 'utils/strings';
import './styles.scss';

export class AgronomicServices extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			language: "pt",
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.settings.agronomicServices));

		delayedDispatch(
		  setBreadcrumb(() => {
			return {
			  locations: [
				{
				  text: Strings.sidebar.settings,
				  route: "/settings",
				  icon: "preferences",
				},
				{
				  text: Strings.settings.agronomicServices,
				  icon: "agriculture",
				},
			  ],
			};
		  })
		);
	
		this.getAgronomicServices();
	}

	componentDidUpdate() {
	  const { dispatch } = this.props;
  
	  dispatch(updateCrumb());
	}

	getAgronomicServices = async () => {
		const { dispatch } = this.props;
	
		dispatch(setLoader(true));
	
		try {
		  const response = await API.get({
			url: Endpoints.uriAgronomicServices(),
		  });
	
		  if (response.ok) {
			this.setState({
			  agronomicServices: response.data.results || [],
			});
		  }
		} catch (err) {
		  console.log("API Error", err);
		}
	
		dispatch(setLoader(false));
	}

	onDrag = async () => {
		const { agronomicServices, hoverIndex } = this.state;
		const { dispatch } = this.props;
	
		const item = agronomicServices[hoverIndex];
		const itemId = item?._id;
	
		if (!itemId || item.pos === hoverIndex) return;
	
		dispatch(setLoader(true));
	
		await API.patch({
		  url: Endpoints.uriAgronomicServices(`position/${itemId}`),
		  data: { pos: hoverIndex },
		})
		  .then((response) => {
			if (response.ok && response.data.results) {
			  this.setState({ agronomicServices: response.data.results });
			} else
			  toast.success(response.data?.message || Strings.serverErrors.title);
		  })
		  .catch((err) => {
			toast.error(err);
		  });
	
		dispatch(setLoader(false));
	  };

	onDelete = async (id: any)  => {
		const { dispatch } = this.props;
		dispatch(setLoader(true));
	
		await API.delete({
			url: Endpoints.uriAgronomicServices(id),
		})
			.then((response) => {
				if (response.ok && response.data.results) {
					this.setState({ agronomicServices: response.data.results });
				} else
					toast.success(response.data?.message || Strings.serverErrors.title);
			})
			.catch((err) => {
				toast.error(err);
			});
	
		dispatch(setLoader(false));
	}

	render() {
		const { agronomicServices, language } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.agronomicServices}</title>
					<meta name="description" content="Description of AgronomicServices" />
				</Helmet>
				<Table
					title={{
						icon: "agriculture",
						title: Strings.settings.agronomicServices,
					}}
					data={agronomicServices}
					columns={[
						{
							Header: Strings.fields.image,
							id: "image",
							accessor: (row: any) => row.image,
							type: "image",
							maxWidth: 65,
						},
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => row.name?.[language],
						},
					]}
					filterable
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
						this.setState({ agronomicServices: list, dragIndex, hoverIndex })
					}}
					onDragEnd={this.onDrag}
					add={{
						label: String(
							Strings.formatString(
								Strings.generic.addNew,
								Strings.agronomicServices.header.toLowerCase()
							)
						),
						onClick: () => dispatch(push("/settings/agronomic-services/new")),
					}}
					actions={{
						edit: (original, value) => ({
							onClick: () => dispatch(push(`/settings/agronomic-services/${original._id}`))
						}),
						remove: (original, value) => ({
							onClick: () => this.onDelete(original._id),
						}),
					}}
					/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(AgronomicServices);