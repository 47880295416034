// TODO: Need better solution for this
const API_URL = process.env.API_URL;

export default {
  uriLogin() {
    console.log("url", API_URL);
    return `${API_URL}/auth/login`;
  },

  uriAnalytics(id = "") {
    return `${API_URL}/analytics${id}`;
  },

  uriLogs() {
    return `${API_URL}/statistics/logs`;
  },

  uriLogout() {
    return `${API_URL}/auth/logout`;
  },

  uriStaff(id = "") {
    return `${API_URL}/staff/${id}`;
  },

  uriPromoters(id = "") {
    return `${API_URL}/promoters/${id}`;
  },

  uriRecoverPassword(id = "") {
    return `${API_URL}/staff/recover-password/${id}`;
  },

  uriStaffPassword() {
    return `${API_URL}/staff/password`;
  },

  uriStaffEmail() {
    return `${API_URL}/staff/email`;
  },

  uriImages() {
    return `${API_URL}/images`;
  },

  uriPages(id = "") {
    return `${API_URL}/pages/${id}`;
  },

  uriEmailTemplate(id = "") {
    return `${API_URL}/email-templates/${id}`;
  },

  uriUsers(id = "") {
    return `${API_URL}/users/${id}`;
  },

  uriProducts(id = "") {
    return `${API_URL}/products/${id}`;
  },

  uriImportPromoters() {
    return `${API_URL}/import/promoters`;
  },

  uriCultures(id = "") {
    return `${API_URL}/cultures/${id}`;
  },

  uriNutrients(id = "") {
    return `${API_URL}/nutrients/${id}`;
  },

  uriSoilNutrients(id = "") {
    return `${API_URL}/soil-nutrients/${id}`;
  },

  uriFertilizingTypes(id = "") {
    return `${API_URL}/fertilizing-types/${id}`;
  },

  uriDichotomousKey(id = "") {
    return `${API_URL}/keys/${id}`;
  },

  uriFormations(id = "") {
    return `${API_URL}/formations/${id}`;
  },

  uriNews(id = "") {
    return `${API_URL}/news/${id}`;
  },

  uriDocuments(id = "") {
    return `${API_URL}/documents/${id}`;
  },

  uriNotifications(id = "") {
    return `${API_URL}/schedule-notifications/${id}`;
  },

  uriLines(id = "") {
    return `${API_URL}/categories/${id}`;
  },

  uriCountries(id = "") {
    return `${API_URL}/misc/countries/general/${id}`;
  },

  uriCountriesPromoters(id = "") {
    return `${API_URL}/misc/countries/promoters`;
  },

  uriAgronomicServices(id = "") {
    return `${API_URL}/agronomic-services/${id}`;
  },

  uriTimezones() {
    return `${API_URL}/misc/timezones`;
  },

  uriLibraryTypes(id = "") {
    return `${API_URL}/formation-categories/${id}`;
  },
};
