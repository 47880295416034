/* eslint-disable eqeqeq */
/*
 *
 * Product
 *
 */

import React from "react";
import { connect } from "react-redux";
import {
  setTitle,
  setLoader,
  setBreadcrumb,
  delayedDispatch,
  updateCrumb,
} from "store/actions";
import { Helmet } from "react-helmet";
import {
  ContentWrapper,
  Icon,
  Table,
  Editor,
  SimpleLogs,
} from "components";
import { EditSidebar } from "components/EditSidebar";
import { Row, Col, Input, Button, Select, Switch } from "antd";
import Dropzone from "react-dropzone";
import { push } from "connected-react-router";
import Compressor from "compressorjs";
import NumberFormat from "react-number-format";
import { SketchPicker } from "react-color";
import moment from "moment";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { toast, translate } from "utils/utils";
import placeholder from "assets/images/placeholders/image.jpg";
import "./styles.scss";

const { Option } = Select;

export class Product extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      product: { isActive: true },
      logs: [],
      language: "pt",
      sidebarLanguage: "pt",
      fileSidebarLanguage: "pt",
      nutrients: [],
      attachedFiles: [],
      cultures: [],
      formulaFilesToDelete: [],
      showColor: false,
      linesOptions: [],
      culturesOptions: [],
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    // change this
    dispatch(setTitle(Strings.products.header));

    delayedDispatch(
      setBreadcrumb(() => {
        const { language, product } = this.state;

        return {
          locations: [
            {
              text: Strings.sidebar.products,
              route: "/products",
              icon: "fertilizer",
            },
            {
              text:
                match.params.id === "new"
                  ? "new"
                  : this.state.product?.name?.[language],
              // route: `/parts/${match.params.id}`,
              icon:
                match.params.id === "new" ? "adicionar-acrescentar" : "lapis",
            },
          ],
          actions: [
            {
              type: "switch",
              text: Strings.dashboard.active,
              value: product?.isActive,
              onClick: () =>
                this.setState({
                  product: { ...product, isActive: !product?.isActive },
                  hasUnsavedFields: true,
                }),
              small: {
                text: true,
                switch: true,
              },
            },
            {
              type: "language",
              value: language,
              separator: "left",
              onChange: (lang: any) => this.setState({ language: lang }),
            },
            {
              type: "button",
              text: Strings.generic.save,
              onClick: this.postProduct,
              disabled: !this.state.hasUnsavedFields,
              className: this.state.hasUnsavedFields
                ? "BreadcrumbButtonSuccess"
                : "",
              separator: "left",
              isSave: true,
            },
          ],
        };
      })
    );

    this.getData();
  }

  componentDidUpdate() {
    const { dispatch } = this.props;
    dispatch(updateCrumb());
  }

  async getData() {
    const { language } = this.state;
    const { dispatch, match } = this.props;
    const id = match?.params?.id !== "new" ? match?.params?.id : "";

    dispatch(setLoader(true));

    const [nutrientsResponse, linesResponse, culturesResponse, documentsResponse, productResponse] =
      await Promise.all([
        API.get({
          url: Endpoints.uriNutrients(),
        }),
        API.get({
          url: Endpoints.uriLines(),
        }),
        API.get({
          url: Endpoints.uriCultures(),
        }),
        API.get({
          url: Endpoints.uriDocuments(),
        }),
        API.get({
          url: Endpoints.uriProducts(id),
        })
      ]);
    if (nutrientsResponse.ok && nutrientsResponse.data.results) {
      this.setState({ nutrients: nutrientsResponse.data.results });
    } else {
      toast.error(
        nutrientsResponse.data?.message || Strings.serverErrors.title
      );
    }

    if (linesResponse.ok && linesResponse.data.results) {
      this.setState({
        linesOptions: linesResponse.data.results.map((elem: any) => (
          <Option key={elem._id} value={elem._id}>
            {elem.name?.[language]}
          </Option>
        )),
      });
    } else {
      toast.error(linesResponse.data?.message || Strings.serverErrors.title);
    }

    if (culturesResponse.ok && culturesResponse.data.results) {
      this.setState({
        cultures: culturesResponse.data.results,
        culturesOptions: culturesResponse.data.results.map((elem: any) => (
          <Option key={elem._id} value={elem._id}>
            {elem.name?.[language]}
          </Option>
        )),
      });
    } else {
      toast.error(culturesResponse.data?.message || Strings.serverErrors.title);
    }

    if (documentsResponse.ok && documentsResponse.data.results) {
      this.setState({ attachedFiles: documentsResponse.data.results });
    } else {
      toast.error(
        documentsResponse.data?.message || Strings.serverErrors.title
      );
    }

    if (productResponse.ok) {
      this.setState({
        product: productResponse.data.results.product,
        hasUnsavedFields: false,
        logs: productResponse.data.results.logs,
      });
    } else {
      return toast.error(productResponse.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  }

  isValid() {
    const { product } = this.state;
    return product.logo || product.image || product.name;
  }

  postProduct = async () => {
    const { match, dispatch } = this.props;
    const { product } = this.state;

    if (!this.isValid()) {
      return toast.warn(Strings.errors.invalidFields);
    }

    dispatch(setLoader(true));

    const body = new FormData();

    body.append("name", JSON.stringify(product.name));
    body.append("slogan", JSON.stringify(product.slogan || {}));
    body.append("color", product.color || '');

    if (product.image?.file) {
      body.append("product", product.image.file);
    } else if (product.image) body.append("image", product.image);

    if (product.logo?.file) {
      body.append("logo", product.logo.file);
    } else if (product.logo) body.append("logo", product.logo);

    body.append("attachedFiles", JSON.stringify(product.attachedFiles || []));

    body.append("shortInfo", JSON.stringify(product.shortInfo || {}));
    body.append("info", JSON.stringify(product.info || {}));

    body.append("fertiberiaProduct", product.fertiberiaProduct || false);
    body.append("adpProduct", product.adpProduct || false);

    body.append("availablePortugal", product.availablePortugal || false);
    body.append("availableSpain", product.availableSpain || false);
    body.append("availableFrance", product.availableFrance || false);
    body.append("availableOthers", product.availableOthers || false);

    body.append("isActive", product.isActive || false);

    const request = match?.params?.id === "new" ? API.post : API.put;

    const response = await request({
      url: Endpoints.uriProducts(
        match?.params?.id !== "new" ? match?.params?.id : ""
      ),
      data: body,
    });

    if (response.ok) {
      this.setState({
        product: response.data.results,
        hasUnsavedFields: false,
      });
      dispatch(push("/"));
      dispatch(push(`/products/${response.data.results._id}`));
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  onDrop = (files: any, field: string) => {
    if (!field) return;

    try {
      const file = files[files.length - 1];

      new Compressor(file, {
        // quality: 1,
        // maxWidth: 400,
        mimeType: "image/png",
        success: (result: any) => {
          this.getBase64(result).then((res) => {
            this.setState((state: any) => ({
              product: {
                ...state.product,
                [field]: { file: result, preview: res },
              },
              hasUnsavedFields: true,
            }));
          });
        },
      });
    } catch (err) {
      toast.warning(Strings.errors.notSupportedFile);
    }
  };

  hoverOnFile = (index: any) => {
    this.setState({ fileToDelete: index });
  };

  hoverOffFile = () => {
    this.setState({ fileToDelete: null });
  };

  addPDFFile = (file: any) => {
    const { product, language } = this.state;

    if (!product.attachedFiles) product.attachedFiles = [];
    if (!product.attachedFiles[language]) product.attachedFiles[language] = [];

    this.setState({
      product: {
        ...product,
        attachedFiles: { ...(product?.attachedFiles || {}), [language]: file },
      },
      hasUnsavedFields: true,
    });
  };

  removeFile = () => {
    const { product, language } = this.state;

    product.attachedFiles[language] = undefined;

    this.setState({
      product: {
        ...product,
        attachedFiles: {
          ...product.attachedFiles,
          [language]: product.attachedFiles[language],
        },
      },
      hasUnsavedFields: true,
    });
  };

  removeFormulaFile = (id: any, index: number) => {
    const { product } = this.state;

    const formula = product.formulas.findIndex((elem: any) => elem._id == id);

    product.formulas[formula].attachedFiles.splice(index, 1);

    const fileToRemove = product.formulas[formula].attachedFiles[index];
    const formulaFilesToDelete: any = this.state.formulaFilesToDelete;

    // eslint-disable-next-line array-callback-return
    Object.keys(fileToRemove).map((lang: any) => {
      if (typeof fileToRemove[lang]?.[0] === "string")
        formulaFilesToDelete.push(fileToRemove[lang][0]);
    });

    this.setState({ product, formulaFilesToDelete });
  };

  onDropFile = async (files: any) => {
    try {
      let reader = new FileReader();
      const addPDFFile = this.addPDFFile;

      reader.onload = function (e: any) {
        let blob = new Blob([new Uint8Array(e.target.result)], {
          type: files[0].type,
        });
        addPDFFile({ file: blob, fileName: files[0].name });
      };
      reader.readAsArrayBuffer(files[0]);
      this.setState({ fileToDelete: null });
    } catch (err) {
      toast.warning(Strings.errors.notSupportedFile);
    }
  };

  isValidFormula = () => {
    const { item } = this.state;

    return item && item.name;
  };

  onSubmit = () => {
    const { item } = this.state;

    if (!this.isValidFormula())
      return toast.error(Strings.errors.invalidFields);

    if (item && (item._id || item.index >= 0)) this.editFormula(item);
    else this.addFormula(item);
  };

  addFormula = async (formula: any) => {
    const { dispatch, match } = this.props;

    const body = {
      name: formula.name,
      category: formula.category,
      cultures: formula.cultures?.map((elem: any) => ({
        ...elem,
        culture: elem.culture?._id || null,
      })),
      nutrientFormulas: formula.nutrientFormulas?.map((elem: any) => ({
        nutrient: elem.nutrient._id,
        percentage: elem.percentage,
      })),
      attachedFiles: formula.attachedFiles?.map((elem: any) => elem._id) || [],
      availablePortugal: formula.availablePortugal || false,
      availableSpain: formula.availableSpain || false,
      availableFrance: formula.availableFrance || false,
      availableOthers: formula.availableOthers || false,
    };

    dispatch(setLoader(true));

    const response = await API.post({
      url: Endpoints.uriProducts(`${match?.params?.id}/formula`),
      data: body,
    });

    if (response.ok) {
      this.setState({ product: response.data.results, openSidebar: false });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  editFormula = async (formula: any) => {
    const { dispatch, match } = this.props;

    const body = {
      name: formula.name,
      category: formula.category,
      information: formula.information,
      moreInformation: formula.moreInformation,
      cultures: formula.cultures.map((elem: any) => ({
        ...elem,
        culture: elem.culture?._id || null,
      })),
      nutrientFormulas: formula.nutrientFormulas.map((elem: any) => ({
        nutrient: elem.nutrient._id,
        percentage: elem.percentage,
      })),
      attachedFiles: formula.attachedFiles?.map((elem: any) => elem._id) || [],
      availablePortugal: formula.availablePortugal || false,
      availableSpain: formula.availableSpain || false,
      availableFrance: formula.availableFrance || false,
      availableOthers: formula.availableOthers || false,
    };

    dispatch(setLoader(true));

    const response = await API.put({
      url: Endpoints.uriProducts(`${match?.params?.id}/formula/${formula._id}`),
      data: body,
    });

    if (response.ok) {
      this.setState({ product: response.data.results, openSidebar: false });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  deleteFormula = async (id: number) => {
    const { dispatch, match } = this.props;

    dispatch(setLoader(true));

    const response = await API.delete({
      url: Endpoints.uriProducts(`${match?.params?.id}/formula/${id}`),
    });

    if (response.ok) {
      this.setState({ product: response.data.results, openSidebar: false });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  onChange = (field: string, value: any) => {
    const { item, sidebarLanguage } = this.state;
    if (field === 'name' || field === 'information' || field === 'moreInformation') {
      this.setState({ item: { ...item, [field]: { ...item[field], [sidebarLanguage]: value } } });
    } else {
      this.setState({ item: { ...item, [field]: value } });
    }
  };

  openSidebar = (value: any) => {
    this.setState({
      openSidebar: true,
      item: JSON.parse(JSON.stringify(value)),
    });
  };

  closeSidebar = () => {
    this.setState({ openSidebar: false });
  };

  openProductFileSidebar = (value: any) => {
    this.setState({
      openProductFileSidebar: true,
      item: JSON.parse(JSON.stringify(value)),
    });
  };

  closeProductFileSidebar = () => {
    this.setState({ openProductFileSidebar: false });
  };

  deleteProductFile = (index: any) => {
    const { product } = this.state;
    product.attachedFiles.splice(index, 1);
    this.setState({ product });
  };

  onChangeFile = (field: string, value: any) => {
    const { itemFile, fileSidebarLanguage } = this.state;

    this.setState({
      itemFile: {
        ...itemFile,
        [field]: {
          ...itemFile[field],
          [fileSidebarLanguage]: value,
        },
      },
    });
  };

  onChangeProductFile = (field: string, value: any) => {
    const { item, fileSidebarLanguage } = this.state;

    this.setState({
      item: {
        ...item,
        [field]: {
          ...item[field],
          [fileSidebarLanguage]: value,
        },
      },
    });
  };

  onChangeCropAndDose = (field: string, value: any) => {
    const { itemCropsAndDoses, cultures, fileSidebarLanguage } = this.state;
    if (field === "culture") {
      this.setState({
        itemCropsAndDoses: {
          ...itemCropsAndDoses,
          [field]: cultures.find((elem: any) => elem._id === value),
        },
      });
    } else {
      this.setState({
        itemCropsAndDoses: {
          ...itemCropsAndDoses,
          [field]: {
            ...itemCropsAndDoses[field],
            [fileSidebarLanguage]: value,
          },
        },
      });
    }
  };

  isValidFile = () => {
    const { itemFile } = this.state;

    return itemFile.name && itemFile.url;
  };

  onSubmitFile = () => {
    const { itemFile } = this.state;

    if (!this.isValidFile()) {
      return toast.error(Strings.errors.invalidFields);
    }

    this.addFile(itemFile);
  };

  onSubmitProductFile = () => {
    const { item, product } = this.state;

    if (!item.name || !item.url) {
      return toast.error(Strings.errors.invalidFields);
    }

    if (!product.attachedFiles || !product.attachedFiles.length)
      product.attachedFiles = [];

    if (item._id) {
      const index = product.attachedFiles.findIndex(
        (elem: any) => elem._id === item._id
      );
      product.attachedFiles.splice(index, 1, item);
    } else {
      product.attachedFiles.push(item);
    }
    this.setState({ product, openProductFileSidebar: false });
  };

  onSubmitCropAndDose = () => {
    const { itemCropsAndDoses } = this.state;

    if (!itemCropsAndDoses.description) {
      return toast.error(Strings.errors.invalidFields);
    }

    if (itemCropsAndDoses.index >= 0) {
      this.editCropAndDose();
    } else {
      this.addCropAndDose();
    }
  };

  addFile = async (file: any) => {
    const { item } = this.state;
    const auxFiles = item.attachedFiles
      ? JSON.parse(JSON.stringify(item.attachedFiles))
      : [];
    auxFiles.push(file);
    this.setState({
      item: { ...item, attachedFiles: auxFiles },
      openFilesSidebar: false,
    });
  };

  closeFilesSidebar = () => {
    this.setState({ openFilesSidebar: false });
  };

  openCropsAndDosesSidebar = (value: any) => {
    this.setState({
      openCropsAndDosesSidebar: true,
      itemCropsAndDoses: JSON.parse(JSON.stringify(value)),
    });
  };

  closeCropsAndDosesSidebar = () => {
    this.setState({ openCropsAndDosesSidebar: false });
  };

  isValidNutrient = () => {
    const { tempNutrient } = this.state;

    return (
      tempNutrient && tempNutrient.nutrient?.name && tempNutrient.percentage
    );
  };

  addNutrient = () => {
    const { item, tempNutrient } = this.state;

    if (!this.isValidNutrient())
      return toast.error(Strings.errors.invalidFields);

    if (item.nutrientFormulas && item.nutrientFormulas.length)
      item.nutrientFormulas.push(tempNutrient);
    else item.nutrientFormulas = [tempNutrient];
    // const auxFormulas = product.formulas ? JSON.parse(JSON.stringify(product.formulas)) : [];
    // auxFormulas[index].isActive = !auxFormulas[index].isActive;
    this.setState({ item, tempNutrient: null });
  };

  addCropAndDose = () => {
    const { item, itemCropsAndDoses } = this.state;

    if (!itemCropsAndDoses.description)
      return toast.error(Strings.errors.invalidFields);

    if (item.cultures && item.cultures.length)
      item.cultures.push(itemCropsAndDoses);
    else item.cultures = [itemCropsAndDoses];
    this.setState({
      item,
      itemCropsAndDoses: null,
      openCropsAndDosesSidebar: false,
    });
  };

  editCropAndDose = () => {
    const { item, itemCropsAndDoses } = this.state;

    if (!itemCropsAndDoses.description)
      return toast.error(Strings.errors.invalidFields);

    item.cultures.splice(itemCropsAndDoses.index, 1, itemCropsAndDoses);
    
    this.setState({
      item,
      itemCropsAndDoses: null,
      openCropsAndDosesSidebar: false,
    });
  };

  deleteNutrient = (id: any) => {
    const { item } = this.state;
    item.nutrientFormulas = item.nutrientFormulas.filter(
      (elem: any) => elem.nutrient._id !== id
    );
    this.setState({ item });
  };

  deleteCropAndDose = (index: any) => {
    const { item } = this.state;
    item.cultures.splice(index, 1);
    this.setState({ item });
  };

  deleteFile = (index: any) => {
    const { item } = this.state;
    item.attachedFiles.splice(index, 1);
    this.setState({ item });
  };

  renderExtraSidebarFields = () => {
    const {
      item,
      tempNutrient,
      nutrients,
      language,
      attachedFiles,
    } = this.state;

    return (
      <div>
        <Row>
          <Col xs={12}>
            <label className="SingleLabel" style={{ height: "inherit" }}>
              {Strings.nutrients.header}
            </label>
          </Col>
          <Col xs={8}>
            <label className="SingleLabel" style={{ height: "inherit" }}>
              {Strings.generic.percentage}
            </label>
          </Col>
          <Col xs={4}></Col>
        </Row>
        {item &&
          item.nutrientFormulas &&
          item.nutrientFormulas.map((elem: any) => (
            <div>
              <Row>
                <Col xs={12}>{elem.nutrient.name[language]}</Col>
                <Col xs={8}>{elem.percentage}%</Col>
                <Col
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div className="ActionOption">
                    <Icon
                      style={{ cursor: "pointer" }}
                      name="trash"
                      onClick={() => this.deleteNutrient(elem.nutrient?._id)}
                    />
                  </div>
                </Col>
              </Row>
              <div className="Separator" />
            </div>
          ))}
        <Row>
          {/* <Input
						placeholder={Strings.nutrients.header}
						value={tempNutrient?.name || ''}
						style={{ marginRight: '5px' }}
						onChange={e => {
							this.setState({
								tempNutrient: { ...tempNutrient, name: e.target.value },
								hasUnsavedFields: true,
							});
						}}
					/> */}
          <Col xs={12}>
            <Select
              showSearch
              allowClear
              placeholder={Strings.nutrients.header}
              optionFilterProp="children"
              onChange={(id: any) =>
                this.setState({
                  tempNutrient: {
                    ...tempNutrient,
                    nutrient: nutrients.find((elem: any) => elem._id === id),
                  },
                  hasUnsavedFields: true,
                })
              }
              style={{ marginRight: "5px", width: "97%" }}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={tempNutrient?.nutrient?.name?.[language]}
            >
              {nutrients.map(
                (elem: any) =>
                  elem.name?.[language] &&
                  (!item?.nutrientFormulas ||
                    !item.nutrientFormulas.length ||
                    !item.nutrientFormulas.find(
                      (auxNutrient: any) =>
                        auxNutrient.nutrient._id === elem._id
                    )) && (
                    <Option value={elem._id}>{elem.name[language]}</Option>
                  )
              )}
            </Select>
          </Col>
          <Col xs={12}>
            <NumberFormat
              key={`${JSON.stringify(item?.nutrientFormulas)}`}
              className="inputClass ant-input"
              value={tempNutrient?.percentage}
              placeholder={Strings.generic.percentage}
              style={{ textAlign: "right" }}
              thousandSeparator
              max={100}
              suffix="%"
              onValueChange={(valueFloat: any) => {
                if (
                  Number(valueFloat.floatValue) >= 0 &&
                  Number(valueFloat.floatValue) <= 100
                )
                  this.setState({
                    tempNutrient: {
                      ...tempNutrient,
                      percentage: valueFloat.floatValue,
                    },
                    hasUnsavedFields: true,
                  });
              }}
              decimalScale={3}
              fixedDecimalScale
            />
          </Col>
          {/* <Input
						placeholder={Strings.generic.percentage}
						defaultValue={tempNutrient?.percentage || ''}
						type="number"
						value={tempNutrient?.percentage || ''}
						onChange={e => {
							if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100)
								this.setState({
									tempNutrient: { ...tempNutrient, percentage: e.target.value },
									hasUnsavedFields: true,
								});
						}}
					/> */}
        </Row>
        <Row style={{ justifyContent: "flex-end" }}>
          <Button
            className="submitButton"
            onClick={this.addNutrient}
            type="primary"
            style={{ margin: "10px 0" }}
          >
            {Strings.formatString(
              Strings.generic.addGeneric,
              Strings.nutrients.header
            )}
          </Button>
        </Row>
        {/* Add Crops and doses */}
        <Row>
          {item?.cultures?.length ? (
            <>
              <Col xs={12}>
                <label className="SingleLabel" style={{ height: "inherit" }}>
                  {Strings.sidebar.cultures}
                </label>
              </Col>
              <Col xs={8}>
                <label className="SingleLabel" style={{ height: "inherit" }}>
                  {Strings.products.doses}
                </label>
              </Col>
              <Col xs={4}></Col>
            </>
          ) : (
            <label
              className="SingleLabel SecondaryLabel __marginTop"
              style={{ height: "inherit", margin: "auto" }}
            >
              {Strings.products.noCropsWithDosesAdded}
            </label>
          )}
        </Row>
        {item?.cultures?.map((elem: any) => (
          <div>
            <Row>
              <Col xs={12}>
                {`${elem.culture?.name?.[language] || ""} ${
                  elem.title?.[language] || ""
                }`}
              </Col>
              <Col xs={8}>{elem.description[language]}</Col>
              <Col
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div className="ActionOption">
                    <Icon
                    name="pencil-outline"
                    onClick={() =>
                        this.openCropsAndDosesSidebar(
                        {
                            ...elem,
                            index: item.cultures.findIndex((culture: any) => culture == elem),
                        })
                    }
                    />
                </div>
                <div style={{ display: "flex" }}>
                  <div className="ActionOption">
                    <Icon
                      name="trash"
                      onClick={() =>
                        this.deleteCropAndDose(
                          item.cultures.findIndex((crop: any) => crop === elem)
                        )
                      }
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div className="Separator" />
          </div>
        ))}
        <Row style={{ justifyContent: "flex-end" }}>
          <Button
            className="submitButton"
            onClick={() => this.openCropsAndDosesSidebar({})}
            type="primary"
            style={{ margin: "10px 0" }}
          >
            {Strings.formatString(
              Strings.generic.addGeneric,
              Strings.products.cropAndDose
            )}
          </Button>
        </Row>

        {/* Add Files */}
        <Col xs={12}>
          <label className="SingleLabel" style={{ height: "inherit" }}>
            {Strings.documents.documents}
          </label>
        </Col>
        <Select
          showSearch
          allowClear
          placeholder={Strings.documents.documents}
          optionFilterProp="children"
          mode="multiple"
          onChange={(files: any) =>
            this.setState({
              item: {
                ...item,
                attachedFiles: attachedFiles.filter((elem: any) =>
                  files.find((file: any) => file === elem._id)
                ),
              },
              hasUnsavedFields: true,
            })
          }
          style={{ marginRight: "5px", width: "97%" }}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={item?.attachedFiles?.map((elem: any) => elem._id)}
        >
          {attachedFiles?.map((elem: any) => (
            <Option value={elem._id}>{elem.name[language]}</Option>
          ))}
        </Select>
        {this.renderCropsAndDosesDrawer()}

        {/* Availability */}
        <label className="SingleLabel" style={{ height: "inherit", marginTop: 20 }}>
          {Strings.products.formulaAvailability}
        </label>
        <div
          className={`General_ColorFul_Switch ${
            item?.availablePortugal ? "__active" : ""
          }`}
          style={{ marginTop: 5 }}
        >
          <span>{Strings.products.availablePortugal}</span>
          <Switch
            className={`Switch ${
              item?.availablePortugal ? "__active" : ""
            }`}
            checked={item?.availablePortugal}
            size="small"
            onChange={() =>
              this.setState({
                item: {
                  ...item,
                  availablePortugal: !item?.availablePortugal,
                },
                hasUnsavedFields: true,
              })
            }
          />
        </div>
        <div
          className={`General_ColorFul_Switch ${
            item?.availableSpain ? "__active" : ""
          }`}
          style={{ marginTop: 5 }}
        >
          <span>{Strings.products.availableSpain}</span>
          <Switch
            className={`Switch ${
              item?.availableSpain ? "__active" : ""
            }`}
            checked={item?.availableSpain}
            size="small"
            onChange={() =>
              this.setState({
                item: {
                  ...item,
                  availableSpain: !item?.availableSpain,
                },
                hasUnsavedFields: true,
              })
            }
          />
        </div>
        <div
          className={`General_ColorFul_Switch ${
            item?.availableFrance ? "__active" : ""
          }`}
          style={{ marginTop: 5 }}
        >
          <span>{Strings.products.availableFrance}</span>
          <Switch
            className={`Switch ${
              item?.availableFrance ? "__active" : ""
            }`}
            checked={item?.availableFrance}
            size="small"
            onChange={() =>
              this.setState({
                item: {
                  ...item,
                  availableFrance: !item?.availableFrance,
                },
                hasUnsavedFields: true,
              })
            }
          />
        </div>
        <div
          className={`General_ColorFul_Switch ${
            item?.availableOthers ? "__active" : ""
          }`}
          style={{ marginTop: 5 }}
        >
          <span>{Strings.products.availableOthers}</span>
          <Switch
            className={`Switch ${
              item?.availableOthers ? "__active" : ""
            }`}
            checked={item?.availableOthers}
            size="small"
            onChange={() =>
              this.setState({
                item: {
                  ...item,
                  availableOthers: !item?.availableOthers,
                },
                hasUnsavedFields: true,
              })
            }
          />
        </div>
      </div>
    );
  };

  renderDrawer() {
    const { item, linesOptions, sidebarLanguage } = this.state;
    return (
      <EditSidebar
        title={Strings.products.formula}
        open={this.state.openSidebar}
        onClose={this.closeSidebar}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        language={sidebarLanguage}
        onChangeLanguage={(lang: any) =>
          this.setState({ sidebarLanguage: lang })
        }
        defaultValue={this.state.item}
        fields={[
          {
            field: "name",
            value: item?.name?.[sidebarLanguage],
            type: "input",
            name: Strings.fields.name,
            required: true,
          },
          {
            field: "category",
            value: item?.category?.name?.[sidebarLanguage] || item?.nutrient,
            type: "selector",
            populated: true,
            options: linesOptions,
            name: Strings.lines.header,
            required: true,
          },
          {
            field: "information",
            value: item?.information?.[sidebarLanguage],
            type: "textArea",
            name: Strings.products.formulaInformation,
            required: true,
          },
          {
            field: "moreInformation",
            value: item?.moreInformation?.[sidebarLanguage],
            type: "textArea",
            name: Strings.products.formulaMoreInformation,
            required: true,
          },
          {
            version: moment.now(),
          },
        ]}
        extraFields={this.renderExtraSidebarFields}
      />
    );
  }

  renderCropsAndDosesDrawer() {
    const { itemCropsAndDoses, fileSidebarLanguage, culturesOptions } =
      this.state;
    return (
      <EditSidebar
        title={Strings.products.formulaFile}
        open={this.state.openCropsAndDosesSidebar}
        onClose={this.closeCropsAndDosesSidebar}
        onChange={this.onChangeCropAndDose}
        onSubmit={this.onSubmitCropAndDose}
        defaultValue={this.state.itemCropsAndDoses}
        language={fileSidebarLanguage}
        onChangeLanguage={(lang: any) =>
          this.setState({ fileSidebarLanguage: lang })
        }
        closable={false}
        fields={[
          {
            field: "culture",
            value: itemCropsAndDoses?.culture?._id,
            type: "selector",
            populated: true,
            options: culturesOptions,
            name: Strings.cultures.header,
          },
          {
            field: "title",
            value: itemCropsAndDoses?.title?.[fileSidebarLanguage],
            type: "input",
            name: Strings.cultures.header,
          },
          {
            field: "description",
            value: itemCropsAndDoses?.description?.[fileSidebarLanguage],
            type: "input",
            name: Strings.products.dose,
            required: true,
          },
          {
            version: moment.now(),
          },
        ]}
      />
    );
  }

  onDrag = async () => {
		const { product, hoverIndex } = this.state;
		const { dispatch } = this.props;
	
		const item = product.formulas[hoverIndex];
		const itemId = item?._id;
	
		if (!itemId || item.pos === hoverIndex) return;
	
		dispatch(setLoader(true));
	
		await API.patch({
			url: Endpoints.uriProducts(`${product._id}/formula/position/${itemId}`),
			data: { pos: hoverIndex },
		})
			.then((response) => {
				if (response.ok && response.data.results) {
				  this.setState({ product: response.data.results });
				} else
				  toast.error(response.data?.message || Strings.serverErrors.title);
			})
			.catch((err) => {
				toast.error(err);
			});
	
		dispatch(setLoader(false));
	};

  renderFormulasTable() {
    const { product } = this.state;

    return (
      <ContentWrapper>
        <Table
          title={{
            icon: "formula",
            title: Strings.products.formulas,
          }}
          data={product?.formulas || []}
          columns={[
            {
              Header: Strings.products.formula,
              id: "name",
              accessor: (row: any) => translate(row.name),
            },
          ]}
          filterable
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
					  this.setState({
              product: { ...product, formulas: list },
              dragIndex,
              hoverIndex
            })
					}}
					onDragEnd={this.onDrag}
          add={{
            label: String(
              Strings.formatString(
                Strings.generic.addNew,
                Strings.products.formula.toLowerCase()
              )
            ),
            onClick: () => this.openSidebar({}),
          }}
          actions={{
            edit: (original, value) => ({
              onClick: () =>
                this.openSidebar({ ...original, index: value.index }),
            }),
            remove: (original, value) => ({
              onClick: () => this.deleteFormula(original._id),
            }),
          }}
        />
        {this.renderDrawer()}
      </ContentWrapper>
    );
  }

  renderColorPicker() {
    const { product, showColor, tempColor } = this.state;

    return (
      <>
        <label className="SingleLabel">{Strings.products.color}</label>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              onClick={() =>
                this.setState({ showColor: true, tempColor: product?.color || '#fff' })
              }
              style={{ flex: 1 }}
              className="S2G_BO_color_picker_block"
            >
              <div
                className="S2G_BO_color_preview"
                style={{ backgroundColor: product?.color || '#fff' }}
              />
              {!showColor && (
                <div className="S2G_BO_color_overlay">
                  {Strings.formatString(
                    Strings.generic.editGeneric,
                    Strings.products.color
                  )}
                </div>
              )}
            </div>
            {showColor && (
              <React.Fragment>
                <div
                  onClick={() => {
                    this.setState({
                      showColor: false,
                      product: {
                        ...product,
                        color: tempColor,
                      },
                      hasUnsavedFields: true,
                    });
                  }}
                  className="S2G_BO_color_action_block"
                >
                  <div className="S2G_BO_color_action apply">
                    <em className="moon-correct-symbol" />
                  </div>
                  <span className="S2G_BO_color_action_label">
                    {Strings.generic.apply}
                  </span>
                </div>
                <div
                  onClick={() => {
                    this.setState({
                      tempColor: product.color,
                      showColor: false,
                    });
                  }}
                  className="S2G_BO_color_action_block"
                >
                  <div className="S2G_BO_color_action cancel">
                    <em className="moon-close1" />
                  </div>
                  <span className="S2G_BO_color_action_label">
                    {Strings.generic.cancel}
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
          <div
            style={{
              height: showColor ? 360 : 0,
              overflow: "hidden",
              transition: "0.5s",
              position: "absolute",
              margin: 50,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SketchPicker
                color={tempColor}
                className="S2G_BO_color_picker_single"
                onChangeComplete={(color: any) => {
                  const rgba = `rgba(${Object.values(color.rgb).join(", ")})`;
                  const auxColor = rgba;
                  this.setState({ tempColor: auxColor });
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { product, logs, language, attachedFiles } = this.state;
    const { match, user } = this.props;
    const isSysAdmin = (Boolean(user) && user.role === "sysadmin") || false;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.products.header}</title>
          <meta name="description" content="Description of Product" />
        </Helmet>
        <ContentWrapper>
          <Row gutter={[24, 24]} style={{ marginBottom: 0 }}>
            <Col xs={24} sm={12} lg={6} style={{ maxHeight: "320px" }}>
              <label className="SingleLabel __required">
                {Strings.fields.logo}
              </label>
              <div className="ImageContainer" style={{ height: "100%" }}>
                <div className="Image" style={{ height: "300px", margin: 0 }}>
                  <img
                    style={{ objectFit: "contain", width: "100%" }}
                    alt={Strings.fields.image}
                    src={product?.logo?.preview || product?.logo || placeholder}
                  />
                  {/* <div className="img" style={{ backgroundImage: `url(${product?.logo?.preview || product?.logo || placeholder})` }} /> */}
                  <div className="ImageOverlay">
                    <Dropzone
                      accept="image/jpg, image/jpeg, image/png"
                      className="ImageOverlayOption"
                      onDrop={(files) => this.onDrop(files, "logo")}
                    >
                      <Icon name="pencil-outline" />
                    </Dropzone>
                    <div
                      className={`ImageOverlayOption${
                        Boolean(product?.logo) ? "" : " __disabled"
                      }`}
                      onClick={() =>
                        this.setState({ product: { ...product, logo: null } })
                      }
                    >
                      <Icon name="trash" />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={6} style={{ maxHeight: "320px" }}>
              <label className="SingleLabel __required">
                {Strings.fields.image}
              </label>
              <div className="ImageContainer" style={{ height: "100%" }}>
                <div className="Image" style={{ height: "300px", margin: 0 }}>
                  <img
                    alt={Strings.fields.image}
                    src={
                      product?.image?.preview || product?.image || placeholder
                    }
                  />
                  <div className="ImageOverlay">
                    <Dropzone
                      accept="image/jpg, image/jpeg, image/png"
                      className="ImageOverlayOption"
                      onDrop={(files) => this.onDrop(files, "image")}
                    >
                      <Icon name="pencil-outline" />
                    </Dropzone>
                    <div
                      className={`ImageOverlayOption${
                        Boolean(product?.image) ? "" : " __disabled"
                      }`}
                      onClick={() =>
                        this.setState({ product: { ...product, image: null } })
                      }
                    >
                      <Icon name="trash" />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              <label className="SingleLabel __required">
                {Strings.fields.name}
              </label>
              <Input
                placeholder={Strings.fields.name}
                defaultValue={product?.name?.[language] || ""}
                value={product?.name?.[language] || ""}
                onChange={(e) => {
                  this.setState({
                    product: {
                      ...product,
                      name: { ...product.name, [language]: e.target.value },
                    },
                    hasUnsavedFields: true,
                  });
                }}
              />
              <label className="SingleLabel">{Strings.products.slogan}</label>
              {/* <Input
								placeholder={Strings.products.slogan}
								defaultValue={product?.slogan?.[language] || ''}
								value={product?.slogan?.[language] || ''}
								onChange={e => {
									this.setState({
										product: { ...product, slogan: { ...product.slogan, [language]: e.target.value } },
										hasUnsavedFields: true,
									});
								}}
							/> */}
              <Input.TextArea
                placeholder={Strings.products.slogan}
                style={{ height: 65 }}
                value={product?.slogan?.[language]}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({
                    product: {
                      ...product,
                      slogan: {
                        ...product.slogan,
                        [language]: e.target.value,
                      },
                    },
                    hasUnsavedFields: true,
                  });
                }}
              />
              {this.renderColorPicker()}
              <label className="SingleLabel" style={{ height: "65" }}>
                {Strings.documents.documents}
              </label>
              <Select
                showSearch
                allowClear
                placeholder={Strings.documents.documents}
                optionFilterProp="children"
                mode="multiple"
                onChange={(files: any) =>
                  this.setState({
                    product: {
                      ...product,
                      attachedFiles: attachedFiles.filter((elem: any) =>
                        files.find((file: any) => file === elem._id)
                      ),
                    },
                    hasUnsavedFields: true,
                  })
                }
                style={{ marginRight: "5px", width: "97%" }}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={product?.attachedFiles?.map((elem: any) => elem._id)}
              >
                {attachedFiles?.map((elem: any) => (
                  <Option value={elem._id}>{elem.name[language]}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <div
                className={`General_ColorFul_Switch ${
                  product?.fertiberiaProduct ? "__active" : ""
                }`}
              >
                <span>{Strings.products.fertiberiaProduct}</span>
                <Switch
                  className={`Switch ${
                    product?.fertiberiaProduct ? "__active" : ""
                  }`}
                  checked={product?.fertiberiaProduct}
                  size="small"
                  onChange={() =>
                    this.setState({
                      product: {
                        ...product,
                        fertiberiaProduct: !product?.fertiberiaProduct,
                      },
                      hasUnsavedFields: true,
                    })
                  }
                />
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div
                className={`General_ColorFul_Switch ${
                  product?.adpProduct ? "__active" : ""
                }`}
              >
                <span>{Strings.products.adpProduct}</span>
                <Switch
                  className={`Switch ${product?.adpProduct ? "__active" : ""}`}
                  checked={product?.adpProduct}
                  size="small"
                  onChange={() =>
                    this.setState({
                      product: { ...product, adpProduct: !product?.adpProduct },
                      hasUnsavedFields: true,
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <label className="SingleLabel" style={{ marginTop: "5px" }}>
            {Strings.products.productAvailability}
          </label>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={6}>
              <div
                className={`General_ColorFul_Switch ${
                  product?.availablePortugal ? "__active" : ""
                }`}
                style={{ marginTop: 5 }}
              >
                <span>{Strings.products.availablePortugal}</span>
                <Switch
                  className={`Switch ${
                    product?.availablePortugal ? "__active" : ""
                  }`}
                  checked={product?.availablePortugal}
                  size="small"
                  onChange={() =>
                    this.setState({
                      product: {
                        ...product,
                        availablePortugal: !product?.availablePortugal,
                      },
                      hasUnsavedFields: true,
                    })
                  }
                />
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div
                className={`General_ColorFul_Switch ${
                  product?.availableSpain ? "__active" : ""
                }`}
                style={{ marginTop: 5 }}
              >
                <span>{Strings.products.availableSpain}</span>
                <Switch
                  className={`Switch ${
                    product?.availableSpain ? "__active" : ""
                  }`}
                  checked={product?.availableSpain}
                  size="small"
                  onChange={() =>
                    this.setState({
                      product: {
                        ...product,
                        availableSpain: !product?.availableSpain,
                      },
                      hasUnsavedFields: true,
                    })
                  }
                />
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div
                className={`General_ColorFul_Switch ${
                  product?.availableFrance ? "__active" : ""
                }`}
                style={{ marginTop: 5 }}
              >
                <span>{Strings.products.availableFrance}</span>
                <Switch
                  className={`Switch ${
                    product?.availableFrance ? "__active" : ""
                  }`}
                  checked={product?.availableFrance}
                  size="small"
                  onChange={() =>
                    this.setState({
                      product: {
                        ...product,
                        availableFrance: !product?.availableFrance,
                      },
                      hasUnsavedFields: true,
                    })
                  }
                />
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div
                className={`General_ColorFul_Switch ${
                  product?.availableOthers ? "__active" : ""
                }`}
                style={{ marginTop: 5 }}
              >
                <span>{Strings.products.availableOthers}</span>
                <Switch
                  className={`Switch ${
                    product?.availableOthers ? "__active" : ""
                  }`}
                  checked={product?.availableOthers}
                  size="small"
                  onChange={() =>
                    this.setState({
                      product: {
                        ...product,
                        availableOthers: !product?.availableOthers,
                      },
                      hasUnsavedFields: true,
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <label className="SingleLabel" style={{ marginTop: "5px" }}>
            {Strings.products.shortInfo}
          </label>
          <Input.TextArea
            placeholder={Strings.products.shortInfo}
            style={{ height: 100 }}
            value={product?.shortInfo?.[language]}
            onChange={(e) => {
              e.preventDefault();
              this.setState({
                product: {
                  ...product,
                  shortInfo: {
                    ...product.shortInfo,
                    [language]: e.target.value,
                  },
                },
                hasUnsavedFields: true,
              });
            }}
          />
          <label className="SingleLabel" style={{ marginTop: "5px" }}>
            {Strings.products.generalInfo}
          </label>
          <Editor
            key={`editor_${language}`}
            required
            init={{ height: 500 }}
            value={product?.info?.[language]}
            onChange={(value: any) =>
              this.setState({
                product: {
                  ...product,
                  info: { ...product.info, [language]: value },
                },
                hasUnsavedFields: true,
              })
            }
          />
        </ContentWrapper>
        {match.params.id !== "new" && this.renderFormulasTable()}
        {(isSysAdmin && match.params.id !== "new" && <SimpleLogs logs={logs} />) || null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Product);
