/*
*
* Cultures
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setLoader, setBreadcrumb, delayedDispatch, updateCrumb } from 'store/actions';
import { Table } from 'components';
import { Helmet } from 'react-helmet';
import { push } from 'connected-react-router';
import Strings from 'utils/strings';
import { API, Endpoints } from "utils/api";
import { toast } from 'utils/utils';
import './styles.scss';

export class Cultures extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			language: 'pt'
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.sidebar.cultures));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
						  text: Strings.sidebar.cultures,
						//   route: "/settings/cultures",
						  icon: "planting",
						},
					],
				}
			})
		);

		this.getCultures();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
	
		dispatch(updateCrumb());
	}

	async getCultures() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));
		const response = await API.get({
			url: Endpoints.uriCultures(),
		});
	  
		if (response.ok) {
			this.setState({ cultures: response.data.results });
		} else {
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
		dispatch(setLoader(false));
	}

	deleteCulture = async (id: string) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));
		const response = await API.delete({
			url: Endpoints.uriCultures(id),
		});
	  
		if (response.ok) {
			this.setState({ cultures: response.data.results });
		} else {
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
		dispatch(setLoader(false));
	}

	toogleCulture = async (culture: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));
		const response = await API.patch({
			url: Endpoints.uriCultures(culture._id),
			data: { isActive: !culture.isActive },
		});
	  
		if (response.ok) {
			this.setState({ cultures: response.data.results });
		} else {
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
		dispatch(setLoader(false));
	}

	onChange = (field: string, value: any) => {
		const { item } = this.state;
		this.setState({ item: { ...item, [field]: value } });
	}

	onDrag = async () => {
		const { cultures, hoverIndex } = this.state;
		const { dispatch } = this.props;
	
		const item = cultures[hoverIndex];
		const itemId = item?._id;
	
		if (!itemId || item.pos === hoverIndex) return;
	
		dispatch(setLoader(true));
	
		await API.patch({
			url: Endpoints.uriCultures(`position/${itemId}`),
			data: { pos: hoverIndex },
		})
			.then((response) => {
				if (response.ok && response.data.results) {
				this.setState({ cultures: response.data.results });
				} else
				toast.error(response.data?.message || Strings.serverErrors.title);
			})
			.catch((err) => {
				toast.error(err);
			});
	
		dispatch(setLoader(false));
	};

	render() {
		const { cultures = [], language } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.cultures}</title>
					<meta name="description" content="Description of Cultures" />
				</Helmet>
				<Table
					title={{
						icon: "planting",
						title: Strings.sidebar.cultures
					}}
					data={cultures}
					columns={[
						{
							Header: Strings.fields.image,
							id: 'image',
							type: 'image',
							accessor: (row: any) => row.image,
							maxWidth: 80,
						},
						{
							Header: Strings.cultures.header,
							id: 'name',
							accessor: (row: any) => row.name?.[language],
						},
					]}
					filterable
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
					  this.setState({ cultures: list, dragIndex, hoverIndex })
					}}
					onDragEnd={this.onDrag}
					add={{
						label: String(Strings.formatString(Strings.generic.addNew, Strings.cultures.header.toLowerCase())),
						onClick: () => dispatch(push('/cultures/new'))
					}}
					actions={{
						edit: (original, value) => ({
							onClick: () => dispatch(push(`/cultures/${original._id}`))
						}),
						remove: (original, value) => ({
							onClick: () => this.deleteCulture(original._id),
						}),
						toggle: (original, value) => ({
							value: original.isActive,
							onChange: () => this.toogleCulture(original),
						}),
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Cultures);