/* eslint-disable eqeqeq */
/*
 *
 * Culture
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setLoader, setBreadcrumb, delayedDispatch, updateCrumb } from "store/actions";
import { Table } from "components";
import { EditSidebar } from "components/EditSidebar";
import { Helmet } from "react-helmet";
import { ContentWrapper, Icon, SelectableTabs, SimpleLogs } from "components";
import { Row, Col, Input, Button, Select } from "antd";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import { push } from "connected-react-router";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { toast, translate } from "utils/utils";
import moment from "moment";
import placeholder from "assets/images/placeholders/image.jpg";
import "./styles.scss";

const { Option } = Select;

export class Culture extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			language: "pt",
			sidebarLanguage: "pt",
			DeficiencySidebarLanguage: "pt",
			nutrientsRemovel: [],
			formulas: [],
			selectedNutrientsTab: "nutrientsDeficiency",
			item: {},
			itemDeficiencyExtra: {},
			formulasOptions: [],
			logs: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.cultures.header));

		delayedDispatch(
			setBreadcrumb(() => {
				const { isActive, language } = this.state;
				const { match } = this.props;

				return {
					locations: [
						{
							text: Strings.sidebar.cultures,
							route: "/cultures",
							icon: "planting",
						},
						{
							text: match.params.id === "new" ? "new" : this.state.name?.[language],
							// route: `/parts/${match.params.id}`,
							icon: match.params.id === "new" ? "adicionar-acrescentar" : "lapis",
						},
					],
					actions: [
						{
							type: "switch",
							text: Strings.dashboard.active,
							value: isActive,
							onClick: () =>
								this.setState({ isActive: !isActive, hasUnsavedFields: true }),
							small: {
								text: true,
								switch: true,
							},
						},
						{
							type: "language",
							value: language,
							separator: "left",
							onChange: (lang: any) => this.setState({ language: lang }),
						},
						{
							type: "button",
							text: Strings.generic.save,
							onClick: this.postCulture,
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							separator: "left",
							isSave: true,
						},
					],
				};
			})
		);

		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { language } = this.state;
		const { dispatch, match } = this.props;
		const id = match?.params?.id !== "new" ? match?.params?.id : "";

		dispatch(setLoader(true));

		const [nutrientsResponse, productsResponse, culturesResponse] = await Promise.all([
			API.get({
				url: Endpoints.uriNutrients(),
			}),
			API.get({
				url: Endpoints.uriProducts(),
			}),
			API.get({
				url: Endpoints.uriCultures(id),
			}),
		]);

		if (nutrientsResponse.ok && nutrientsResponse.data.results) {
			const nutrientsOptions = nutrientsResponse.data.results.map((elem: any) => (
				<Option key={elem._id} value={elem._id}>
					{elem.name?.[language]}
				</Option>
			));

			this.setState({
				nutrients: nutrientsResponse.data.results,
				deficienciesOptions: nutrientsOptions,
				toxicitiesOptions: nutrientsOptions,
				nutrientsImportantOptions: nutrientsOptions,
				nutrientsRemovelOptions: nutrientsOptions,
			});
		} else {
			toast.error(nutrientsResponse.data?.message || Strings.serverErrors.title);
		}

		if (productsResponse.ok && productsResponse.data.results) {
			this.setState({
				defaultProducts: productsResponse.data.results,
				productsOptions: productsResponse.data.results.map((elem: any) => (
					<Option key={elem._id} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)),
			});
		} else {
			toast.error(productsResponse.data?.message || Strings.serverErrors.title);
		}

		if (culturesResponse.ok && culturesResponse.data) {
			this.setCulture(culturesResponse.data.results);
		} else {
			toast.error(culturesResponse.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	}

	isValid() {
		const { name } = this.state;
		return name;
	}

	postCulture = async () => {
		const { match, dispatch } = this.props;
		const {
			image,
			imageLandscape,
			name,
			nutrientsImportant,
			nutrientsRemovel,
			products,
			isActive,
		} = this.state;

		if (!this.isValid()) {
			return toast.warn(Strings.errors.invalidFields);
		}

		dispatch(setLoader(true));

		const body = new FormData();

		body.append("name", JSON.stringify(name));

		if (image && image.file) {
			body.append("image", image.file);
		} else if (image) body.append("image", image);

		if (imageLandscape && imageLandscape.file) {
			body.append("imageLandscape", imageLandscape.file);
		} else if (imageLandscape) body.append("imageLandscape", imageLandscape);

		// if (attachedFiles && Object.keys(attachedFiles).length > 0) {
		//     const auxFilesList = JSON.parse(JSON.stringify({}));

		//     for (const lang of Object.keys(attachedFiles)) {
		//         if (attachedFiles[lang] && attachedFiles[lang].file) {
		//         body.append(`file_${lang}`, attachedFiles[lang].file);
		//         } else if (typeof attachedFiles[lang] === "string") {
		//         auxFilesList[lang] = attachedFiles[lang];
		//         }
		//     }

		//     body.append("attachedFiles", JSON.stringify(auxFilesList));
		// }

		const nutrientsImportantRemovel = [
			...nutrientsRemovel.map((elem: any) => ({
				...elem,
				nutrient: elem.nutrient?._id,
			})),
			...nutrientsImportant.map((elem: any) => ({
				nutrient: elem.nutrient?._id,
				text: elem.text,
			})),
		];

		if (nutrientsRemovel)
			body.append("nutrientsImportantRemovel", JSON.stringify(nutrientsImportantRemovel));
		if (products)
			body.append("products", JSON.stringify(products.map((elem: any) => elem._id)));
		body.append("isActive", isActive || false);

		for (const pair of body.entries()) {
			console.log(`${pair[0]}, ${pair[1]}`);
		}

		const request = match?.params?.id === "new" ? API.post : API.put;

		const response = await request({
			url: Endpoints.uriCultures(match?.params?.id !== "new" ? match?.params?.id : ""),
			data: body,
		});

		if (response.ok) {
			if (match?.params?.id === "new") {
				dispatch(push("/"));
				dispatch(push(`/cultures/${response.data.results._id}`));
			} else {
				this.setCulture({ culture: response.data.results, logs: null });
			}
			toast.success(response.data?.message);
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	setCulture = ({ culture, logs }: any) => {
		const { nutrients, defaultProducts, language } = this.state;
		if (!culture) culture = {};
		culture.nutrientsDeficiency = [];
		culture.nutrientsToxicity = [];
		culture.nutrientsImportant = [];
		culture.nutrientsRemovel = [];

		if (culture.nutrientsDeficiencyToxicity) {
			for (let nutrient of culture.nutrientsDeficiencyToxicity) {
				const auxCorrections = nutrient.corrections
					? JSON.parse(JSON.stringify(nutrient.corrections))
					: [];
				let i = 0;
				for (let correction of auxCorrections) {
					correction.formulas =
						correction.product?.formulas
							?.filter((elem: any) => correction.formulas.includes(elem._id));
					auxCorrections[i] = correction;
					i++;
				}

				nutrient.corrections = auxCorrections;

				if (nutrient.type === "deficiency") {
					culture.nutrientsDeficiency.push(nutrient);
				} else if (nutrient.type === "toxicity") {
					culture.nutrientsToxicity.push(nutrient);
				}
			}
		}
		if (culture.nutrientsImportantRemovel) {
			for (let nutrient of culture.nutrientsImportantRemovel) {
				if (nutrient.type === "important") {
					culture.nutrientsImportant.push(nutrient);
				} else if (nutrient.type === "removel") {
					culture.nutrientsRemovel.push(nutrient);
				}
			}
		}
		const deficienciesOptions = nutrients.map(
			(elem: any) =>
				!culture?.nutrientsDeficiency?.find(
					(product: any) => product?.nutrient?._id === elem._id
				) && (
					<Option key={elem._id} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		const toxicitiesOptions = nutrients.map(
			(elem: any) =>
				!culture?.nutrientsToxicity?.find(
					(product: any) => product?.nutrient?._id === elem._id
				) && (
					<Option key={`toxicity_${elem._id}`} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		const nutrientsImportantOptions = nutrients.map(
			(elem: any) =>
				!culture?.nutrientsImportant?.find(
					(nutrient: any) => nutrient?.nutrient?._id === elem._id
				) && (
					<Option key={`important_${elem._id}`} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		const nutrientsRemovelOptions = nutrients.map(
			(elem: any) =>
				!culture?.nutrientsRemovel?.find(
					(nutrient: any) => nutrient?.nutrient?._id === elem._id
				) && (
					<Option key={`removel_${elem._id}`} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		const productsOptions = defaultProducts.map(
			(elem: any) =>
				!culture?.products?.find((product: any) => product?.product?._id === elem._id) && (
					<Option key={`product_${elem._id}`} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		this.setState({
			...culture,
			deficienciesOptions,
			toxicitiesOptions,
			nutrientsImportantOptions,
			nutrientsRemovelOptions,
			productsOptions,
			openSidebar: false,
			hasUnsavedFields: false,
			logs: logs || this.state.logs,
		});
	};

	getBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	onDrop = (files: any, newVar: string) => {
		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				// quality: 0.9,
				// maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						console.log(result);
						this.setState((state: any) => ({
							[newVar]: { file: result, preview: res },
							hasUnsavedFields: true,
						}));
					});
				},
			});
		} catch (err) {
			toast.warning(Strings.errors.notSupportedFile);
		}
	};

	hoverOnFile = (index: any) => {
		this.setState({ fileToDelete: index });
	};

	hoverOffFile = () => {
		this.setState({ fileToDelete: null });
	};

	// setPDFFile = (file: any) => {
	//     let { attachedFiles, language } = this.state;

	//     if (!attachedFiles) attachedFiles = {};
	//     if (!attachedFiles[language]) attachedFiles[language] = [];

	//     this.setState({
	//     attachedFiles: { ...attachedFiles, [language]: file },
	//     hasUnsavedFields: true,
	//     });
	// };

	// removeFile = () => {
	//     const { attachedFiles, language } = this.state;

	//     attachedFiles[language] = undefined;

	//     this.setState({
	//     attachedFiles: { ...attachedFiles, [language]: attachedFiles[language] },
	//     hasUnsavedFields: true,
	//     });
	// };

	// onDropFile = async (files: any) => {
	//     try {
	//     const file = files[files.length - 1];
	//     let reader = new FileReader();
	//     const setPDFFile = this.setPDFFile;

	//     reader.onload = function (e: any) {
	//         let blob = new Blob([new Uint8Array(e.target.result)], {
	//         type: file.type,
	//         });
	//         setPDFFile({ file: blob, fileName: file.name });
	//     };
	//     reader.readAsArrayBuffer(file);
	//     this.setState({ fileToDelete: null });
	//     } catch (err) {
	//     toast.warning(Strings.errors.notSupportedFile);
	//     }
	// };

	submitImportantNutrient = () => {
		const { item } = this.state;
		if (item && item.index >= 0) this.editImportantNutrient(item);
		else this.addImportantNutrient(item);
	};

	addImportantNutrient = async (nutrient: any) => {
		// Add Nutrient
		const { nutrientsImportant = [], nutrients, language } = this.state;

		if (!nutrient.nutrient || !nutrient.text) return toast.warn(Strings.errors.invalidFields);

		nutrientsImportant.push({
			nutrient: nutrients.find((elem: any) => elem._id === nutrient.nutrient),
			text: nutrient.text,
			type: "important",
		});

		const nutrientsImportantOptions = nutrients.map(
			(elem: any) =>
				!nutrientsImportant.find(
					(nutrient: any) => nutrient?.nutrient?._id === elem._id
				) && (
					<Option key={`important_${elem._id}`} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		this.setState({
			nutrientsImportant,
			nutrientsImportantOptions,
			openSidebar: false,
			hasUnsavedFields: true,
		});
	};

	editImportantNutrient = async (nutrient: any) => {
		// Edit Nutrient
		const { nutrientsImportant = [], nutrients, language } = this.state;

		if ((!nutrient.nutrient && !nutrient._id) || !nutrient.text)
			return toast.warn(Strings.errors.invalidFields);

		const auxNutrient =
			typeof nutrient.nutrient === "string"
				? nutrients.find((elem: any) => elem._id === nutrient.nutrient)
				: nutrient.nutrient;

		nutrientsImportant.splice(nutrient.index, 1, {
			nutrient: auxNutrient,
			text: nutrient.text,
			type: "important",
		});

		const nutrientsImportantOptions = nutrients.map(
			(elem: any) =>
				!nutrientsImportant.find(
					(nutrient: any) => nutrient?.nutrient?._id === elem._id
				) && (
					<Option key={`important_${elem._id}`} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		this.setState({
			nutrientsImportant,
			nutrientsImportantOptions,
			openSidebar: false,
			hasUnsavedFields: true,
		});
	};

	deleteImportantNutrient = async (index: any) => {
		// Delete Nutrient
		const { nutrientsImportant = [], nutrientsImportantOptions, language } = this.state;

		const nutrient = nutrientsImportant[index].nutrient;

		nutrientsImportantOptions.push(
			<Option key={`important_${nutrient._id}`} value={nutrient._id}>
				{nutrient.name?.[language]}
			</Option>
		);

		nutrientsImportant.splice(index, 1);

		this.setState({
			nutrientsImportant,
			nutrientsImportantOptions,
			hasUnsavedFields: true,
		});
	};

	submitNutrient = () => {
		const { item } = this.state;

		if (item && item.index >= 0) this.editNutrient(item);
		else this.addNutrient(item);
	};

	addNutrient = async (nutrient: any) => {
		// Add Nutrient
		const { nutrientsRemovel = [], nutrients, language } = this.state;

		if (!nutrient.nutrient || !nutrient.text) return toast.warn(Strings.errors.invalidFields);

		nutrientsRemovel.push({
			nutrient: nutrients.find((elem: any) => elem._id === nutrient.nutrient),
			text: nutrient.text,
			type: "removel",
		});

		const nutrientsRemovelOptions = nutrients.map(
			(elem: any) =>
				!nutrientsRemovel.find((nutrient: any) => nutrient?.nutrient?._id === elem._id) && (
					<Option key={`removel_${elem._id}`} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		this.setState({
			nutrientsRemovel,
			nutrientsRemovelOptions,
			openSidebar: false,
			hasUnsavedFields: true,
		});
	};

	editNutrient = async (nutrient: any) => {
		// Edit Nutrient
		const { nutrientsRemovel = [], nutrients, language } = this.state;

		if ((!nutrient.nutrient && !nutrient._id) || !nutrient.text)
			return toast.warn(Strings.errors.invalidFields);

		const auxNutrient =
			typeof nutrient.nutrient === "string"
				? nutrients.find((elem: any) => elem._id === nutrient.nutrient)
				: nutrient.nutrient;

		nutrientsRemovel.splice(nutrient.index, 1, {
			nutrient: auxNutrient,
			text: nutrient.text,
			type: "removel",
		});

		const nutrientsRemovelOptions = nutrients.map(
			(elem: any) =>
				!nutrientsRemovel.find((nutrient: any) => nutrient?.nutrient?._id === elem._id) && (
					<Option key={`removel_${elem._id}`} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		this.setState({
			nutrientsRemovel,
			nutrientsRemovelOptions,
			openSidebar: false,
			hasUnsavedFields: true,
		});
	};

	deleteNutrient = async (index: any) => {
		// Delete Nutrient
		const { nutrientsRemovel = [], nutrientsRemovelOptions, language } = this.state;

		const nutrient = nutrientsRemovel[index].nutrient;

		nutrientsRemovelOptions.push(
			<Option key={`removel_${nutrient._id}`} value={nutrient._id}>
				{nutrient.name?.[language]}
			</Option>
		);

		nutrientsRemovel.splice(index, 1);

		this.setState({
			nutrientsRemovel,
			nutrientsRemovelOptions,
			hasUnsavedFields: true,
		});
	};

	submitDeficiency = async () => {
		// Submit deficiency
		const { item, defaultProducts } = this.state;
		const { match, dispatch } = this.props;

		if (!item || !item.nutrient || (!item.symptoms && !item.corrections))
			return toast.warning(Strings.errors.invalidFields);

		dispatch(setLoader(true));

		const body = new FormData();

		body.append("type", "deficiency");
		body.append(
			"nutrient",
			typeof item.nutrient === "string" ? item.nutrient : item.nutrient?._id
		);

		const auxCorrections = JSON.parse(JSON.stringify(item.corrections || []));
		let i = 0;
		for (let correction of auxCorrections) {
			console.log("correction", JSON.parse(JSON.stringify(correction)));
			if (!correction.product.name)
				correction.product = defaultProducts.find(
					(elem: any) => elem._id === correction.product
				);
			correction.formulas = correction.formulas.map((elem: any) => elem._id);
			// correction.formulas =
			//     correction.product.formulas
			//     ?.filter((elem: any) => correction.formulas.includes(elem.name))
			//     .map((elem: any) => elem._id) || [];
			correction.product = correction.product._id;
			auxCorrections[i] = correction;
			i++;
		}

		body.append("corrections", JSON.stringify(auxCorrections));

		if (item.symptoms) {
			const symptoms = [];
			let index = 0;
			for (const symptom of item.symptoms) {
				if (symptom.image && symptom.image.file) {
					body.append(`symptoms_${index}`, symptom.image.file);
				}

				symptoms.push({
					...symptom,
					value: `${index}`,
				});
				index++;
			}

			body.append("symptoms", JSON.stringify(symptoms));
		}

		for (const pair of body.entries()) {
			console.log(`${pair[0]}, ${pair[1]}`);
		}

		const request = item._id ? API.put : API.post;

		const response = await request({
			url: Endpoints.uriCultures(`${match.params.id}/nutrient/${item._id || ""}`),
			data: body,
		});

		if (response.ok) {
			toast.success(response.data?.message);

            const culture = response.data.results
			this.setCulture({ culture, logs: null });
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	// editDeficiency = () => {
	// 	// Edit deficiency
	// 	const { nutrientsDeficiency = [], item, nutrients, deficienciesOptions, language } = this.state;

	// 	const index = item.index;
	// 	const nutrient = item.nutrient ? nutrients.find((elem: any) => elem._id === item.nutrient) : item;

	// 	nutrientsDeficiency.splice(index, 1, { ...nutrient, symptoms: item.symptoms, correction: item.correction });

	// 	const auxDeficienciesOptions = item.nutrient ? nutrients.map((elem: any) =>
	// 		!nutrientsDeficiency.find((product: any) =>
	// 			product?._id === elem._id
	// 		) && <Option
	// 				key={elem._id}
	// 				value={elem._id}>
	// 				{elem.name?.[language]}
	// 			</Option>) : deficienciesOptions;

	// 	this.setState({ nutrientsDeficiency, deficienciesOptions: auxDeficienciesOptions, openSidebar: false, hasUnsavedFields: true });
	// }

	// deleteDeficiency = async (index: any) => {
	// 	// Delete deficiencies
	// 	const { nutrientsDeficiency = [], deficienciesOptions, language } = this.state;

	// 	deficienciesOptions.push(<Option key={nutrientsDeficiency[index]._id} value={nutrientsDeficiency[index]._id}>{nutrientsDeficiency[index].name?.[language]}</Option>);

	// 	nutrientsDeficiency.splice(index, 1);

	// 	this.setState({ nutrientsDeficiency, deficienciesOptions, hasUnsavedFields: true });
	// }

	deleteDeficiency = async (id: any) => {
		// Add deficiency
		const { nutrients, language } = this.state;
		const { match, dispatch } = this.props;

		dispatch(setLoader(true));

		const response = await API.delete({
			url: Endpoints.uriCultures(`${match.params.id}/nutrient/${id}`),
		});

		if (response.ok) {
			toast.success(response.data?.message);

			const culture = response.data.results;

			culture.nutrientsDeficiency = [];
			culture.nutrientsToxicity = [];
			if (culture.nutrientsDeficiencyToxicity) {
				for (let nutrient of culture.nutrientsDeficiencyToxicity) {
					if (nutrient.type === "deficiency") {
						culture.nutrientsDeficiency.push(nutrient);
					} else if (nutrient.type === "toxicity") {
						culture.nutrientsToxicity.push(nutrient);
					}
				}
			}

			const deficienciesOptions = nutrients.map(
				(elem: any) =>
					!culture.nutrientsDeficiency.find(
						(product: any) => product?.nutrient?._id === elem._id
					) && (
						<Option key={elem._id} value={elem._id}>
							{elem.name?.[language]}
						</Option>
					)
			);

			this.setState({
				...culture,
				deficienciesOptions,
				openSidebar: false,
				hasUnsavedFields: false,
			});
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	// submitToxicity = () => {
	// 	const { item } = this.state;

	// 	if (item._id) {
	// 		this.editToxicity();
	// 	} else {
	// 		this.addToxicity();
	// 	}
	// }

	submitToxicity = async () => {
		// Submit toxicity
		const { item, nutrients, defaultProducts, language } = this.state;
		const { match, dispatch } = this.props;

		dispatch(setLoader(true));

		const body = new FormData();

		body.append("type", "toxicity");
		body.append(
			"nutrient",
			item.nutrient?._id || item.nutrient
		);

		const auxCorrections = JSON.parse(JSON.stringify(item.corrections || []));
		let i = 0;
		console.log("corrections", item.corrections);
		for (let correction of auxCorrections) {
			if (!correction.product.name)
				correction.product = defaultProducts.find(
					(elem: any) => elem._id === correction.product
				);
			correction.formulas =
				correction.product.formulas
					?.filter((elem: any) => correction.formulas.includes(elem.name))
					.map((elem: any) => elem._id) || [];
			correction.product = correction.product._id;
			auxCorrections[i] = correction;
			i++;
		}

		body.append("corrections", JSON.stringify(auxCorrections));

		// body.append("correction", JSON.stringify(item.correction));

		if (item.symptoms) {
			const symptoms = [];
			let index = 0;
			for (const symptom of item.symptoms) {
				if (symptom.image && symptom.image.file)
					body.append(`symptoms_${index}`, symptom.image.file);

				symptoms.push({
					...symptom,
					value: `${index}`,
				});
				index++;
			}

			body.append("symptoms", JSON.stringify(symptoms));
		}

		for (const pair of body.entries()) {
			console.log(`${pair[0]}, ${pair[1]}`);
		}

		const request = item._id ? API.put : API.post;

		const response = await request({
			url: Endpoints.uriCultures(`${match.params.id}/nutrient/${item._id || ""}`),
			data: body,
		});

		if (response.ok) {
			toast.success(response.data?.message);

			const culture = response.data.results;

			culture.nutrientsDeficiency = [];
			culture.nutrientsToxicity = [];

			if (culture.nutrientsDeficiencyToxicity) {
				for (let nutrient of culture.nutrientsDeficiencyToxicity) {
					const auxCorrections = nutrient.corrections
						? JSON.parse(JSON.stringify(nutrient.corrections))
						: [];
					let i = 0;
					for (let correction of auxCorrections) {
						correction.formulas =
							correction.product?.formulas
								?.filter((elem: any) => correction.formulas.includes(elem._id));
						auxCorrections[i] = correction;
						i++;
					}

					nutrient.corrections = auxCorrections;

					if (nutrient.type === "deficiency") {
						culture.nutrientsDeficiency.push(nutrient);
					} else if (nutrient.type === "toxicity") {
						culture.nutrientsToxicity.push(nutrient);
					}
				}
			}

			const toxicitiesOptions = nutrients.map(
				(elem: any) =>
					!culture.nutrientsToxicity.find(
						(product: any) => product?.nutrient?._id === elem._id
					) && (
						<Option key={`toxicity_${elem._id}`} value={elem._id}>
							{elem.name?.[language]}
						</Option>
					)
			);

			this.setState({
				...culture,
				toxicitiesOptions,
				openSidebar: false,
				hasUnsavedFields: false,
			});
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	// addToxicity = async () => {
	// 	// Add Toxicity
	// 	const { nutrientsToxicity = [], item, nutrients, language } = this.state;

	// 	nutrientsToxicity.push({ ...nutrients.find((elem: any) => elem._id === item.nutrient), symptoms: item.symptoms, correction: item.correction });

	// 	const toxicitiesOptions = nutrients.map((elem: any) =>
	// 		!nutrientsToxicity.find((product: any) =>
	// 			product?._id === elem._id
	// 		) && <Option
	// 				key={elem._id}
	// 				value={elem._id}>
	// 				{elem.name?.[language]}
	// 			</Option>);

	// 	this.setState({ nutrientsToxicity, toxicitiesOptions, openSidebar: false, hasUnsavedFields: true });
	// }

	// editToxicity = () => {
	// 	// Edit Toxicity
	// 	const { nutrientsToxicity = [], item, nutrients, toxicitiesOptions, language } = this.state;

	// 	const index = item.index;
	// 	const nutrient = item.nutrient ? nutrients.find((elem: any) => elem._id === item.nutrient) : item;

	// 	nutrientsToxicity.splice(index, 1, { ...nutrient, symptoms: item.symptoms, correction: item.correction });

	// 	const auxToxicitiesOptions = item.nutrient ? nutrients.map((elem: any) =>
	// 		!nutrientsToxicity.find((product: any) =>
	// 			product?._id === elem._id
	// 		) && <Option
	// 				key={elem._id}
	// 				value={elem._id}>
	// 				{elem.name?.[language]}
	// 			</Option>) : toxicitiesOptions;

	// 	this.setState({ nutrientsToxicity, deficienciesOptions: auxToxicitiesOptions, openSidebar: false, hasUnsavedFields: true });
	// }

	// deleteToxicity = async (index: any) => {
	// 	// Delete Toxicity
	// 	const { nutrientsToxicity = [], toxicitiesOptions, language } = this.state;

	// 	toxicitiesOptions.push(<Option key={nutrientsToxicity[index]._id} value={nutrientsToxicity[index]._id}>{nutrientsToxicity[index].name?.[language]}</Option>);

	// 	nutrientsToxicity.splice(index, 1);

	// 	this.setState({ nutrientsToxicity, toxicitiesOptions, hasUnsavedFields: true });
	// }

	deleteToxicity = async (id: any) => {
		// Add deficiency
		const { nutrients, language } = this.state;
		const { match, dispatch } = this.props;

		dispatch(setLoader(true));

		const response = await API.delete({
			url: Endpoints.uriCultures(`${match.params.id}/nutrient/${id}`),
		});

		if (response.ok) {
			toast.success(response.data?.message);

			const culture = response.data.results;

			culture.nutrientsDeficiency = [];
			culture.nutrientsToxicity = [];

			if (culture.nutrientsDeficiencyToxicity) {
				for (let nutrient of culture.nutrientsDeficiencyToxicity) {
					if (nutrient.type === "deficiency") {
						culture.nutrientsDeficiency.push(nutrient);
					} else if (nutrient.type === "toxicity") {
						culture.nutrientsToxicity.push(nutrient);
					}
				}
			}

			const toxicitiesOptions = nutrients.map(
				(elem: any) =>
					!culture.nutrientsToxicity.find(
						(product: any) => product?.nutrient?._id === elem._id
					) && (
						<Option key={`toxicity_${elem._id}`} value={elem._id}>
							{elem.name?.[language]}
						</Option>
					)
			);

			this.setState({
				...culture,
				toxicitiesOptions,
				openSidebar: false,
				hasUnsavedFields: false,
			});
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	};

	isValidProduct = () => {
		const { item } = this.state;

		return item && item.name;
	};

	submitProduct = async () => {
		// Add Product
		const { item } = this.state;

		// if(!this.isValidProduct()) return toast.error(Strings.errors.invalidFields);

		// if (item && (item._id || item.index >= 0))
		// 	this.editProduct();
		// else
		// 	this.addProduct();

		this.setState({ products: item.products, openSidebar: false });
	};

	// addProduct = async () => {
	// 	const { item, products, defaultProducts, language } = this.state;

	// 	products.push(item);

	// 	const productsOptions = defaultProducts.map((elem: any) =>
	// 		!products.find((product: any) =>
	// 			product?._id === elem._id
	// 		) && <Option
	// 				key={elem._id}
	// 				value={elem._id}>
	// 				{elem.name?.[language]}
	// 			</Option>);

	// 	this.setState({
	// 		products,
	// 		productsOptions,
	// 		hasUnsavedFields: true,
	// 		openSidebar: false,
	// 	});
	// }

	// editProduct = async () => {
	// 	const { item, products, defaultProducts, language } = this.state;
	// 	const index = products.findIndex((elem: any) => elem._id === item._id);

	// 	products.splice(index, 1, item);

	// 	const productsOptions = defaultProducts.map((elem: any) =>
	// 		!products.find((product: any) =>
	// 			product?._id === elem._id
	// 		) && <Option
	// 				key={elem._id}
	// 				value={elem._id}>
	// 				{elem.name?.[language]}
	// 			</Option>);

	// 	this.setState({
	// 		products,
	// 		productsOptions,
	// 		hasUnsavedFields: true,
	// 		openSidebar: false,
	// 	});
	// }

	deleteProduct = async (index: any) => {
		// Delete Product
		const { products = [], defaultProducts, language } = this.state;

		// productsOptions.push(<Option key={product._id} value={product._id}>{product.name?.[language] || product.name?.['pt']}</Option>);

		products.splice(index, 1);

		const productsOptions = defaultProducts.map(
			(elem: any) =>
				!products.find((product: any) => product?._id === elem._id) && (
					<Option key={`product_${elem._id}`} value={elem._id}>
						{elem.name?.[language]}
					</Option>
				)
		);

		this.setState({ products, productsOptions, hasUnsavedFields: true });
	};

	onChange = (field: string, value: any) => {
		const { item, defaultProducts, sidebarLanguage } = this.state;

		if (field === "name" || field === "text" || field === "value")
			this.setState({
				item: {
					...item,
					[field]: { ...item[field], [sidebarLanguage]: value },
				},
			});
		else if (field === "product") {
			const product = defaultProducts.find((elem: any) => elem._id === value);
			this.setState({
				item: product,
			});
		} else this.setState({ item: { ...item, [field]: value } });
	};

	openSidebar = (value: any, sidebarType: string) => {
		this.setState({
			openSidebar: true,
			item: JSON.parse(JSON.stringify(value)),
			sidebarType,
		});
	};

	closeSidebar = () => {
		this.setState({ openSidebar: false, sidebarType: null });
	};

	onClickTag(tag: any) {
		const { item } = this.state;
		// const { allEquipments, unique } = this.props;

		if (!tag) return;
		if (!item.products) item.products = [];

		const index = item?.products?.findIndex((tg: any) => tg._id === tag._id);
		if (index !== -1) {
			// this.removeTag(selectedEquipment, index);
			item?.products?.splice(index, 1);
		} else {
			item?.products?.push(tag);
		}

		this.setState({ item, hasUnsavedFields: true });
	}

	renderProductsExtraFields = () => {
		const { item, defaultProducts, language } = this.state;

		return (
			<div className="TagInput_tag_list">
				{defaultProducts?.map((tag: any) => {
					const selected = item?.products?.find((p: any) => p._id === tag._id);
					const className = `TagInput_sidebar_tag animated fadeIn ${
						selected ? "selected" : ""
					}`;
					return (
						<React.Fragment key={`product_category_${tag._id}`}>
							<div className={className} onClick={() => this.onClickTag(tag)}>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										width: "calc(100% - 30px)",
									}}
								>
									{tag.image ? (
										<div style={{ display: "inline-block" }}>
											<div
												style={{
													backgroundImage: `url(${tag.image})`,
													height: 35,
													width: 35,
													marginRight: 10,
													border: "1px solid #f0f0f0",
													boxShadow: "1px 2px 6px 0px #c7c7c7",
													backgroundPosition: "center center",
													backgroundSize: "cover",
												}}
											/>
										</div>
									) : null}
									<span>
										<span>{tag.name?.[language]}</span>
									</span>
								</div>
								<div
									className={`TagInput_sidebar_tag_option ${
										selected ? "selected" : ""
									}`}
								/>
							</div>
						</React.Fragment>
					);
				})}
			</div>
		);
	};

	onChangeDeficiencyExtra = (field: string, value: any) => {
		const { itemDeficiencyExtra, DeficiencySidebarLanguage, defaultProducts } = this.state;

		if (field === "description") {
			this.setState({
				itemDeficiencyExtra: {
					...itemDeficiencyExtra,
					[field]: {
						...itemDeficiencyExtra[field],
						[DeficiencySidebarLanguage]: value,
					},
				},
			});
		} else if (field === "product") {
			const product = defaultProducts.find((elem: any) => elem._id === value);

			const formulasOptions = product.formulas.map((elem: any) => (
				<Option key={`formula_${elem._id}`} value={elem._id}>
					{translate(elem.name)}
				</Option>
			));

			this.setState({
				itemDeficiencyExtra: {
					...itemDeficiencyExtra,
					[field]: product,
				},
				formulasOptions,
			});
		} else if (field === "formulas") {
			const formulas =
				itemDeficiencyExtra.product.formulas?.filter((elem: any) =>
					value.includes(elem._id)
				) || [];

			this.setState({
				itemDeficiencyExtra: {
					...itemDeficiencyExtra,
					[field]: formulas,
				},
			});
		} else {
			this.setState({
				itemDeficiencyExtra: {
					...itemDeficiencyExtra,
					[field]: value,
				},
			});
		}
	};

	isValidDeficiencyExtra = () => {
		const { itemDeficiencyExtra, openDeficiencyExtraSidebar } = this.state;

		if (openDeficiencyExtraSidebar === "symptom") {
			return itemDeficiencyExtra.description;
		} else {
			return itemDeficiencyExtra.product && itemDeficiencyExtra.formulas?.length;
		}
	};

	onSubmitDeficiencyExtra = () => {
		const { openDeficiencyExtraSidebar, itemDeficiencyExtra } = this.state;

		if (!this.isValidDeficiencyExtra()) {
			return toast.warning(Strings.errors.invalidFields);
		}

		if (openDeficiencyExtraSidebar === "symptom") {
			if (itemDeficiencyExtra.index >= 0) this.editDeficiencySymptom(itemDeficiencyExtra);
			else this.addDeficiencySymptom(itemDeficiencyExtra);
		} else {
			if (itemDeficiencyExtra.index >= 0) this.editDeficiencyCorrection(itemDeficiencyExtra);
			else this.addDeficiencyCorrection(itemDeficiencyExtra);
		}
	};

	addDeficiencySymptom = async (symptom: any) => {
		const { item } = this.state;

		if (!Array.isArray(item.symptoms)) item.symptoms = [];
		item.symptoms.push(symptom);
		this.setState({
			item,
			openDeficiencyExtraSidebar: false,
		});
	};

	editDeficiencySymptom = (symptom: any) => {
		const { item } = this.state;
		item.symptoms.splice(symptom.index, 1, symptom);
		this.setState({ item, openDeficiencyExtraSidebar: false });
	};

	deleteDeficiencySymptom = (index: any) => {
		const { item } = this.state;
		item.symptoms.splice(index, 1);
		this.setState({ item });
	};

	addDeficiencyCorrection = async (correction: any) => {
		const { item } = this.state;
		const auxCorrection = item.corrections ? JSON.parse(JSON.stringify(item.corrections)) : [];
		auxCorrection.push(correction);
		this.setState({
			item: { ...item, corrections: auxCorrection },
			openDeficiencyExtraSidebar: false,
		});
	};

	editDeficiencyCorrection = (correction: any) => {
		const { item } = this.state;
		item.corrections.splice(correction.index, 1, correction);
		this.setState({ item, openDeficiencyExtraSidebar: false });
	};

	deleteDeficiencyCorrection = (index: any) => {
		const { item } = this.state;
		item.corrections.splice(index, 1);
		this.setState({ item });
	};

	deleteFertilization = async (id: any) => {
		const { dispatch, match } = this.props;
		// Delete Fertilization
		dispatch(setLoader(true));
		try {
			const response = await API.delete({
				url: Endpoints.uriCultures(`${match.params.id}/fertilizing/${id}`),
			});
			if (response.ok) {
				const { fertilizing } = response.data.results;

				this.setState({ fertilizing });
			} else {
				return toast.error(response.data?.message || Strings.serverErrors.title);
			}
		} catch (err) {
			return toast.error(err || Strings.serverErrors.title);
		} finally {
			dispatch(setLoader(false));
		}
	};

	deleteSoilFertility = async (id: any) => {
		const { dispatch, match } = this.props;
		// Delete Fertilization
		dispatch(setLoader(true));
		try {
			const response = await API.delete({
				url: Endpoints.uriCultures(`${match.params.id}/soil-fertilizing/${id}`),
			});
			if (response.ok) {
				const { soilFertilization } = response.data.results;

				this.setState({ soilFertilization });
			} else {
				return toast.error(response.data?.message || Strings.serverErrors.title);
			}
		} catch (err) {
			return toast.error(err || Strings.serverErrors.title);
		} finally {
			dispatch(setLoader(false));
		}
	};

	openDeficiencyExtraDrawer = (value: any, sidebarType: any) => {
		if (sidebarType === "correction") {
			const formulasOptions = value.product
				? value.product.formulas.map((elem: any) => (
						<Option key={`formula_${elem._id}`} value={elem._id}>
							{translate(elem.name)}
						</Option>
				  ))
				: [];
			this.setState({ formulasOptions });
		}
		this.setState({
			openDeficiencyExtraSidebar: sidebarType,
			itemDeficiencyExtra: JSON.parse(JSON.stringify(value)),
		});
	};

	closeDeficiencyExtraDrawer = () => {
		this.setState({ openDeficiencyExtraSidebar: false });
	};

	renderDeficiencyExtraDrawer() {
		const {
			openDeficiencyExtraSidebar,
			itemDeficiencyExtra,
			DeficiencySidebarLanguage,
			productsOptions,
			formulasOptions,
		} = this.state;

		const fields =
			openDeficiencyExtraSidebar === "symptom"
				? [
						{
							field: "image",
							value: itemDeficiencyExtra?.image,
							type: "image",
							name: Strings.fields.image,
						},
						{
							field: "description",
							value: itemDeficiencyExtra?.description?.[DeficiencySidebarLanguage],
							type: "textArea",
							name: Strings.fields.description,
							required: true,
						},
				  ]
				: [
						{
							field: "product",
							value: itemDeficiencyExtra?.product?._id,
							populated: true,
							options: productsOptions,
							type: "selector",
							name: Strings.products.header,
							required: true,
						},
						{
							field: "formulas",
							value: itemDeficiencyExtra?.formulas,
							populated: true,
							options: formulasOptions,
							type: "tagsSelector",
							name: Strings.products.formulas,
							required: true,
						},
				  ];

		return (
			<EditSidebar
				title={
					openDeficiencyExtraSidebar === "symptom"
						? Strings.cultures.symptom
						: Strings.cultures.correction
				}
				open={this.state.openDeficiencyExtraSidebar}
				onClose={this.closeDeficiencyExtraDrawer}
				onChange={this.onChangeDeficiencyExtra}
				onSubmit={this.onSubmitDeficiencyExtra}
				defaultValue={this.state.itemDeficiencyExtra}
				language={openDeficiencyExtraSidebar === "symptom" && DeficiencySidebarLanguage}
				onChangeLanguage={(lang: any) =>
					openDeficiencyExtraSidebar === "symptom" &&
					this.setState({ DeficiencySidebarLanguage: lang })
				}
				closable={false}
				fields={[
					...fields,
					{
						version: moment.now(),
					},
				]}
			/>
		);
	}

	renderExtraDeficienciesFields = (sidebarType: string) => {
		const { item, sidebarLanguage } = this.state;

		return (
			<>
				{/* <label className="SingleLabel __required">{sidebarType === 'deficiency' ? Strings.cultures.correctionOfDeficiency : Strings.cultures.correctionOfToxicity}</label>
                    <Input
                        placeholder={Strings.fields.name}
                        defaultValue={item.correction?.[sidebarLanguage] || ''}
                        value={item.correction?.[sidebarLanguage] || ''}
                        onChange={e => {
                            this.setState({
                                item: {
                                    ...item,
                                    correction: { ...item.correction, [sidebarLanguage]: e.target.value },
                                },
                                hasUnsavedFields: true,
                            });
                        }}
                    /> */}
				{/* Add Symptoms */}
				<Row>
					{item?.symptoms?.length ? (
						<label className="SingleLabel" style={{ height: "inherit" }}>
							{Strings.cultures.symptoms}
						</label>
					) : (
						<label
							className="SingleLabel SecondaryLabel __marginTop"
							style={{ height: "inherit", margin: "auto" }}
						>
							{Strings.cultures.noSymptomsAdded}
						</label>
					)}
				</Row>
				{item?.symptoms?.map((elem: any) => (
					<div>
						<Row>
							<Col xs={6}>
								<img
									alt={Strings.fields.image}
									style={{ height: 70, width: 70, objectFit: "cover" }}
									src={elem.image?.preview || elem.image || placeholder}
								/>
							</Col>
							<Col xs={15}>{elem.description[sidebarLanguage]}</Col>
							<Col
								xs={3}
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<div style={{ display: "flex", width: "100%", paddingLeft: 6 }}>
									<div className="ActionOption">
										<Icon
											name="pencil-outline"
											onClick={() =>
												this.openDeficiencyExtraDrawer(
													{
														...elem,
														index: item.symptoms.findIndex(
															(symptom: any) =>
																symptom.description ==
																elem.description
														),
													},
													"symptom"
												)
											}
										/>
									</div>
									<div className="ActionOption">
										<Icon
											name="trash"
											onClick={() =>
												this.deleteDeficiencySymptom(
													item.symptoms.findIndex(
														(file: any) => file == elem
													)
												)
											}
										/>
									</div>
								</div>
							</Col>
						</Row>
						<div className="Separator" />
					</div>
				))}
				<Row style={{ justifyContent: "flex-end" }}>
					<Button
						className="submitButton"
						onClick={() => this.openDeficiencyExtraDrawer({}, "symptom")}
						type="primary"
						style={{ margin: "10px 0" }}
					>
						{Strings.formatString(Strings.generic.addGeneric, Strings.cultures.symptom)}
					</Button>
				</Row>
				{/* Add Corrections */}
				<Row>
					{item?.corrections?.length ? (
						<label className="SingleLabel" style={{ height: "inherit" }}>
							{Strings.cultures.correction}
						</label>
					) : (
						<label
							className="SingleLabel SecondaryLabel __marginTop"
							style={{ height: "inherit", margin: "auto" }}
						>
							{Strings.cultures.noCorrectionsAdded}
						</label>
					)}
				</Row>
				{item?.corrections?.map((elem: any) => (
					<div>
						<Row>
							<Col xs={6}>{elem.product?.name?.[sidebarLanguage]}</Col>
							<Col xs={15}>
								{elem.formulas.map((elem: any) => (
									<p>{elem.name?.[sidebarLanguage] || elem.name}</p>
								))}
							</Col>
							<Col
								xs={3}
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<div style={{ display: "flex", width: "100%", paddingLeft: 6 }}>
									<div className="ActionOption">
										<Icon
											name="pencil-outline"
											onClick={() =>
												this.openDeficiencyExtraDrawer(
													{
														...elem,
														index: item.corrections.findIndex(
															(correction: any) =>
																correction.product == elem.product
														),
													},
													"correction"
												)
											}
										/>
									</div>
									<div className="ActionOption">
										<Icon
											name="trash"
											onClick={() => {
												this.deleteDeficiencyCorrection(
													item.corrections.findIndex(
														(correction: any) =>
															correction.product == elem.product
													)
												)
											}}
										/>
									</div>
								</div>
							</Col>
						</Row>
						<div className="Separator" />
					</div>
				))}
				<Row style={{ justifyContent: "flex-end" }}>
					<Button
						className="submitButton"
						onClick={() => this.openDeficiencyExtraDrawer({}, "correction")}
						type="primary"
						style={{ margin: "10px 0" }}
					>
						{Strings.formatString(
							Strings.generic.addGeneric,
							Strings.cultures.correction
						)}
					</Button>
				</Row>
				{this.renderDeficiencyExtraDrawer()}
			</>
		);
	};

	renderDrawer() {
		const {
			item,
			sidebarLanguage,
			language,
			sidebarType,
			deficienciesOptions = [],
			toxicitiesOptions = [],
			nutrientsImportantOptions = [],
			nutrientsRemovelOptions = [],
		} = this.state;

		let onSubmit = () => {};

		let fields: any = [];
		let title = "";

		if (sidebarType === "nutrientsImportant") {
			title = Strings.cultures.mostImportantNutrients;
			fields = [
				{
					field: "nutrient",
					value: item?.nutrient?.name?.[sidebarLanguage] || item?.nutrient,
					type: "selector",
					populated: true,
					options: nutrientsImportantOptions,
					name: Strings.nutrients.header,
					required: true,
				},
				{
					field: "text",
					value: item?.text?.[sidebarLanguage],
					type: "textArea",
					height: "150px",
					name: Strings.fields.description,
					required: true,
				},
			];
			onSubmit = this.submitImportantNutrient;
		} else if (sidebarType === "nutrientsRemovel") {
			title = Strings.cultures.nutrientsRemovel;
			fields = [
				{
					field: "nutrient",
					value: item?.nutrient?.name?.[language] || item?.nutrient,
					type: "selector",
					populated: true,
					options: nutrientsRemovelOptions,
					name: Strings.nutrients.header,
					required: true,
				},
				{
					field: "text",
					value: item?.text?.[sidebarLanguage],
					type: "input",
					name: Strings.nutrients.value,
					required: true,
				},
			];
			onSubmit = this.submitNutrient;
		} else if (sidebarType === "deficiency") {
			title = Strings.cultures.deficiency;
			fields = [
				{
					field: "nutrient",
					value:
						item?.nutrient?.name?.[language] || item?.nutrient?._id || item?.nutrient,
					type: "selector",
					populated: true,
					options: deficienciesOptions,
					name: Strings.nutrients.header,
					required: true,
				},
			];
			onSubmit = this.submitDeficiency;
		} else if (sidebarType === "toxicity") {
			title = Strings.cultures.toxicity;
			fields = [
				{
					field: "nutrient",
					value: item?.nutrient?.name?.[language] || item?._id || item?.nutrient,
					type: "selector",
					populated: true,
					options: toxicitiesOptions,
					name: Strings.nutrients.header,
					required: true,
				},
			];
			onSubmit = this.submitToxicity;
		} else if (sidebarType === "product") {
			title = Strings.products.header;
			// fields = [{
			// 	field: 'product',
			// 	value: item?.product?.name?.[language] || item?.product?._id,
			// 	type: 'selector',
			// 	populated: true,
			// 	options: productsOptions,
			// 	name: Strings.products.header,
			// 	required: true,
			// }];
			fields = [];
			onSubmit = this.submitProduct;
		}

		return (
			<EditSidebar
				title={title}
				open={this.state.openSidebar}
				onClose={this.closeSidebar}
				onChange={this.onChange}
				onSubmit={onSubmit}
				defaultValue={this.state.item}
				language={sidebarType !== "product" ? sidebarLanguage : undefined}
				onChangeLanguage={(lang: any) => this.setState({ sidebarLanguage: lang })}
				closable={false}
				fields={[
					...fields,
					{
						version: moment.now(),
					},
				]}
				extraFields={
					sidebarType === "product"
						? this.renderProductsExtraFields
						: sidebarType === "deficiency" || sidebarType === "toxicity"
						? () => this.renderExtraDeficienciesFields(sidebarType)
						: () => {}
				}
			/>
		);
	}

	renderGeneralInfo() {
		const { name, image, imageLandscape, language } = this.state;

		return (
			<ContentWrapper>
				<label className="SingleLabel __required">{Strings.fields.name}</label>
				<Input
					placeholder={Strings.fields.name}
					defaultValue={name?.[language] || ""}
					value={name?.[language] || ""}
					onChange={(e) => {
						this.setState({
							name: { ...name, [language]: e.target.value },
							hasUnsavedFields: true,
						});
					}}
				/>
				<Row gutter={12}>
					<Col xs={24} md={12}>
						<label className="SingleLabel">{Strings.fields.image}</label>
						<div className="ImageContainer">
							<div className="Image" style={{ height: "320px" }}>
								<img
									alt={Strings.fields.image}
									src={image?.preview || image || placeholder}
								/>
								<div className="ImageOverlay">
									<Dropzone
										accept="image/jpg, image/jpeg, image/png"
										className="ImageOverlayOption"
										onDrop={(files) => this.onDrop(files, "image")}
									>
										<Icon name="pencil-outline" />
									</Dropzone>
									<div
										className={`ImageOverlayOption${
											Boolean(image) ? "" : " __disabled"
										}`}
										onClick={() => this.setState({ image: null })}
									>
										<Icon name="trash" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<label className="SingleLabel">{Strings.cultures.imageLandscape}</label>
						<div className="ImageContainer">
							<div className="Image" style={{ height: "320px" }}>
								<img
									alt={Strings.cultures.imageLandscape}
									src={imageLandscape?.preview || imageLandscape || placeholder}
								/>
								<div className="ImageOverlay">
									<Dropzone
										accept="image/jpg, image/jpeg, image/png"
										className="ImageOverlayOption"
										onDrop={(files) => this.onDrop(files, "imageLandscape")}
									>
										<Icon name="pencil-outline" />
									</Dropzone>
									<div
										className={`ImageOverlayOption${
											Boolean(imageLandscape) ? "" : " __disabled"
										}`}
										onClick={() => this.setState({ imageLandscape: null })}
									>
										<Icon name="trash" />
									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderImporantNutrientsTable() {
		const { nutrientsImportant, language } = this.state;

		return (
			<div style={{ marginBottom: "15px" }}>
				<Table
					title={{
						icon: "molecule",
						title: Strings.cultures.mostImportantNutrients,
					}}
					data={nutrientsImportant}
					columns={[
						{
							Header: Strings.nutrients.header,
							id: "name",
							accessor: (row: any) =>
								row.nutrient?.isFormula ? `${row.nutrient?.name?.[language]} (${row.nutrient?.formula})` : row.nutrient?.name?.[language],
						},
					]}
					add={{
						label: String(
							Strings.formatString(
								Strings.generic.addNew,
								Strings.nutrients.header.toLowerCase()
							)
						),
						onClick: () => this.openSidebar({}, "nutrientsImportant"),
					}}
					actions={{
						edit: (original, value) => ({
							onClick: () =>
								this.openSidebar(
									{ ...original, index: value.index },
									"nutrientsImportant"
								),
						}),
						remove: (original, value) => ({
							onClick: () => this.deleteImportantNutrient(value.index),
						}),
					}}
				/>
			</div>
		);
	}

	renderNutrientsTable() {
		const { nutrientsRemovel, language } = this.state;

		return (
			<div style={{ marginBottom: "15px" }}>
				<Table
					title={{
						icon: "molecule",
						title: Strings.cultures.nutrientsRemovel,
					}}
					data={nutrientsRemovel}
					columns={[
						{
							Header: Strings.nutrients.header,
							id: "name",
							accessor: (row: any) =>
								row.nutrient?.isFormula ? `${row.nutrient?.name?.[language]} (${row.nutrient?.formula})` : row.nutrient?.name?.[language],
						},
					]}
					add={{
						label: String(
							Strings.formatString(
								Strings.generic.addNew,
								Strings.nutrients.header.toLowerCase()
							)
						),
						onClick: () => this.openSidebar({}, "nutrientsRemovel"),
					}}
					actions={{
						edit: (original, value) => ({
							onClick: () =>
								this.openSidebar(
									{ ...original, index: value.index },
									"nutrientsRemovel"
								),
						}),
						remove: (original, value) => ({
							onClick: () => this.deleteNutrient(value.index),
						}),
					}}
				/>
			</div>
		);
	}

	renderProductsTable() {
		const { products, language } = this.state;

		return (
			<div style={{ marginBottom: "15px" }}>
				<Table
					title={{
						icon: "fertilizer",
						title: Strings.sidebar.products,
					}}
					data={products}
					columns={[
						{
							Header: Strings.fields.image,
							id: "image",
							type: "image",
							accessor: (row: any) => row.image,
							maxWidth: 80,
						},
						{
							Header: Strings.products.header,
							id: "name",
							accessor: (row: any) => row.name?.[language],
						},
					]}
					add={{
						label: String(
							Strings.formatString(
								Strings.generic.addNew,
								Strings.products.header.toLowerCase()
							)
						),
						onClick: () => this.openSidebar({ products }, "product"),
					}}
					actions={{
						remove: (original, value) => ({
							onClick: () => this.deleteProduct(value.index),
						}),
					}}
				/>
			</div>
		);
	}

	renderDeficienciesTable() {
		const { nutrientsDeficiency = [], language } = this.state;

		return (
			<Table
				title={{
					icon: "oil",
					title: Strings.cultures.deficiencies,
				}}
				data={nutrientsDeficiency}
				columns={[
					{
						Header: Strings.nutrients.header,
						id: "name",
						accessor: (row: any) =>
							row.nutrient?.isFormula ? `${row.nutrient?.name?.[language]} (${row.nutrient?.formula})` : row.nutrient?.name?.[language],
					},
				]}
				add={{
					label: String(
						Strings.formatString(
							Strings.generic.addNew,
							Strings.cultures.deficiency.toLowerCase()
						)
					),
					onClick: () => this.openSidebar({}, "deficiency"),
				}}
				actions={{
					edit: (original, value) => ({
						onClick: () =>
							this.openSidebar(
								{
									...original,
									index: nutrientsDeficiency.findIndex(
										(elem: any) => elem._id === original._id
									),
								},
								"deficiency"
							),
					}),
					remove: (original, value) => ({
						onClick: () => this.deleteDeficiency(original._id),
					}),
				}}
			/>
		);
	}

	renderToxicitiesTable() {
		const { nutrientsToxicity = [], language } = this.state;

		return (
			<Table
				title={{
					icon: "flask",
					title: Strings.cultures.toxicities,
				}}
				data={nutrientsToxicity}
				columns={[
					{
						Header: Strings.cultures.toxicity,
						id: "name",
						accessor: (row: any) =>
							row.nutrient?.isFormula ? `${row.nutrient?.name?.[language]} (${row.nutrient?.formula})` : row.nutrient?.name?.[language],
					},
				]}
				add={{
					label: String(
						Strings.formatString(
							Strings.generic.addNew,
							Strings.cultures.toxicity.toLowerCase()
						)
					),
					onClick: () => this.openSidebar({}, "toxicity"),
				}}
				actions={{
					edit: (original, value) => ({
						onClick: () =>
							this.openSidebar(
								{
									...original,
									index: nutrientsToxicity.findIndex(
										(elem: any) => elem._id === original._id
									),
								},
								"toxicity"
							),
					}),
					remove: (original, value) => ({
						onClick: () => this.deleteToxicity(original._id),
					}),
				}}
			/>
		);
	}

	renderDeficienciesAndToxicities() {
		const { selectedNutrientsTab } = this.state;
		return (
			<SelectableTabs
				selectedTab={selectedNutrientsTab}
				tabs={[
					{
						key: "nutrientsDeficiency",
						icon: "oil",
						title: Strings.cultures.deficiencies,
						onClick: () =>
							this.setState({ selectedNutrientsTab: "nutrientsDeficiency" }),
						renderScreen: () => this.renderDeficienciesTable(),
					},
					{
						key: "nutrientsToxicity",
						icon: "flask",
						title: Strings.cultures.toxicities,
						onClick: () => this.setState({ selectedNutrientsTab: "nutrientsToxicity" }),
						renderScreen: () => this.renderToxicitiesTable(),
					},
				]}
			/>
		);
	}

	renderFertilization() {
		const { fertilizing, language } = this.state;
		const { dispatch, match } = this.props;

		return (
			<Table
				title={{
					icon: "fertilizer",
					title: Strings.fertilizing.header,
				}}
				data={fertilizing}
				columns={[
					{
						Header: Strings.fields.country,
						id: "country",
						accessor: (row: any) => row.country.name,
					},
					{
						Header: Strings.fields.states,
						id: "states",
						accessor: (row: any) => row.states.join(", "),
					},
					{
						Header: Strings.fertilizing.productionDestiny,
						id: "productionDestiny",
						accessor: (row: any) => row.productionDestiny?.[language],
					},
				]}
				add={{
					label: String(
						Strings.formatString(
							Strings.generic.addNew,
							Strings.fertilizing.header.toLowerCase()
						)
					),
					onClick: () =>
						dispatch(
							push(`/cultures/${match.params.id}/fertilization/new`, {
								cultureName: this.state.name,
							})
						),
				}}
				actions={{
					edit: (original, value) => ({
						onClick: () =>
							dispatch(
								push(`/cultures/${match.params.id}/fertilization/${original._id}`, {
									cultureName: this.state.name,
								})
							),
					}),
					remove: (original, value) => ({
						onClick: () => this.deleteFertilization(original._id),
					}),
				}}
			/>
		);
	}

	renderStateSoilNutrients(state: any) {
		const { language } = this.state;

		return (
			<Table
				title={{
					icon: "shovel",
					title: Strings.settings.soilNutrients,
				}}
				data={state.soilNutrients}
				columns={[
					{
						Header: Strings.soilNutrients.header,
						id: "soilNutrient",
						accessor: (row: any) => row.soilNutrient?.name?.[language],
					},
					{
						Header: Strings.soilNutrients.lessFrequent,
						id: "lessFrequent",
						accessor: (row: any) =>
							`${row.lessFrequent.description?.[language]} - ${row.lessFrequent.value}%`,
					},
					{
						Header: Strings.soilNutrients.frequent,
						id: "frequent",
						accessor: (row: any) =>
							`${row.frequent.description?.[language]} - ${row.frequent.value}%`,
					},
					{
						Header: Strings.soilNutrients.veryFrequent,
						id: "veryFrequent",
						accessor: (row: any) =>
							`${row.veryFrequent.description?.[language]} - ${row.veryFrequent.value}%`,
					},
				]}
			/>
		);
	}

	renderCountryStates(soilFertilization: any) {
		return (
			<Table
				title={{
					icon: "placeholder",
					title: Strings.fields.states,
				}}
				data={soilFertilization.states}
				columns={[
					{
						Header: Strings.fields.state,
						id: "state",
						accessor: (row: any) => row.state,
					},
				]}
				expanded
				renderExpanded={(elem: any) => this.renderStateSoilNutrients(elem.original)}
			/>
		);
	}

	renderSoilFertility() {
		const { soilFertilization } = this.state;
		const { dispatch, match } = this.props;

		return (
			<Table
				title={{
					icon: "shovel",
					title: Strings.soilFertility.header,
				}}
				data={soilFertilization}
				columns={[
					{
						Header: Strings.fields.country,
						id: "country",
						accessor: (row: any) => row.country.name,
					},
				]}
				expanded
				renderExpanded={(elem: any) => this.renderCountryStates(elem.original)}
				add={{
					label: String(
						Strings.formatString(
							Strings.generic.addNew,
							Strings.soilFertility.header.toLowerCase()
						)
					),
					onClick: () =>
						dispatch(
							push(`/cultures/${match.params.id}/soil-fertilization/new`, {
								cultureName: this.state.name,
							})
						),
				}}
				actions={{
					edit: (original, value) => ({
						onClick: () =>
							dispatch(
								push(
									`/cultures/${match.params.id}/soil-fertilization/${original._id}`,
									{ cultureName: this.state.name }
								)
							),
					}),
					remove: (original, value) => ({
						onClick: () => this.deleteSoilFertility(original._id),
					}),
				}}
			/>
		);
	}

	render() {
		const { match, user } = this.props;
		const { logs } = this.state;
		const isSysAdmin = (Boolean(user) && user.role === "sysadmin") || false;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.cultures.header}</title>
					<meta name="description" content="Description of Culture" />
				</Helmet>
				{this.renderGeneralInfo()}
				<Row gutter={12}>
					<Col xs={24} md={12}>
						{this.renderImporantNutrientsTable()}
					</Col>
					<Col xs={24} md={12}>
						{this.renderNutrientsTable()}
					</Col>
				</Row>
				{this.renderProductsTable()}
				{match.params.id !== "new" && (
					<>
						{this.renderDeficienciesAndToxicities()}
						{this.renderFertilization()}
						{this.renderSoilFertility()}
					</>
				)}
				{this.renderDrawer()}
				{(isSysAdmin && match.params.id !== "new" && <SimpleLogs logs={logs} />) || null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user,
});

export default connect(mapStateToProps)(Culture);
