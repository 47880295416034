import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "components";
import {
  Dashboard,
  Login,
  RecoverPassword,
  Staff,
  Logs,
  Settings,
  AcceptInvite,
  Pages,
  EmailTemplates,
  PageDetail,
  EmailDetail,
  Promoters,
  Promoter,
  Cultures,
  Culture,
  Fertilization,
  SoilFertilization,
  DichotomousKey,
  Nutrients,
  Products,
  Product,
  News,
  Users,
  NewsDetails,
  Notifications,
  Notification,
  Documents,
  Formations,
  Formation,
  SoilNutrients,
  FertilizingTypes,
  Lines,
  AgronomicServices,
  AgronomicService,
  LibraryTypes,
  Error500,
} from "screens";

export const offlinePages = [
  "/login",
  "/register",
  "/accept-invite",
  "/change-password",
  "/confirm",
];

export class Routes extends React.Component<any, any> {
  shouldComponentUpdate(nextProps: any) {
    const { user, token } = this.props;
    const isLoggedIn = Boolean(user && token);
    const willBeLoggedIn = Boolean(nextProps.user && nextProps.token);

    return isLoggedIn !== willBeLoggedIn;
  }

  componentDidUpdate() {
    const elem = document.getElementById("app_content");

    if (elem) {
      elem.scrollTop = 0;
    }
  }

  render() {
    const { token, router, user } = this.props;
    const { location } = router;

    if (token && user) {
      location.pathname = "/dashboard";
    } else {
      location.pathname = "/login";
    }

    const path = location.pathname.split("/")[1];
    const isAdmin =
      (Boolean(user) &&
        (user.role === "owner" ||
          user.role === "admin" ||
          user.role === "sysadmin")) ||
      false;
    const isOwner =
      (Boolean(user) && (user.role === "owner" || user.role === "sysadmin")) ||
      false;
    const isSysAdmin = (Boolean(user) && user.role === "sysadmin") || false;

    if (
      offlinePages.indexOf(location.pathname) !== -1 ||
      offlinePages.indexOf(`/${path}`) !== -1
    ) {
      return (
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/change-password" component={RecoverPassword} />
          <Route
            exact
            path="/change-password/:id/:code"
            component={RecoverPassword}
          />
          <Route
            exact
            path="/accept-invite/:id/:code"
            component={AcceptInvite}
          />
          <Route
            exact
            path="/confirm/:email/:code"
            component={() => <h1>Confirm Account</h1>}
          />
          <Route exact path="/500" component={Error500} />
          <Redirect to="/login" />
        </Switch>
      );
    }

    return (
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        {/* <PrivateRoute exact path="/users" component={Users} /> */}
        <PrivateRoute exact path="/cultures" component={Cultures} />
        <PrivateRoute exact path="/cultures/:id" component={Culture} />
        <PrivateRoute
          exact
          path="/cultures/:id/fertilization/:fertilizationId"
          component={Fertilization}
        />
        <PrivateRoute
          exact
          path="/cultures/:id/soil-fertilization/:fertilizationId"
          component={SoilFertilization}
        />
        <PrivateRoute exact path="/nutrients" component={Nutrients} />
        <PrivateRoute exact path="/products" component={Products} />
        <PrivateRoute exact path="/products/:id" component={Product} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/news" component={News} />
        <PrivateRoute exact path="/news/:id" component={NewsDetails} />
        <PrivateRoute exact path="/notifications" component={Notifications} />
        <PrivateRoute
          exact
          path="/notifications/:id"
          component={Notification}
        />
        <PrivateRoute exact path="/documents" component={Documents} />
        <PrivateRoute exact path="/formations" component={Formations} />
        <PrivateRoute exact path="/formations/:id" component={Formation} />
        <PrivateRoute exact path="/lines" component={Lines} />
        {isOwner && <PrivateRoute exact path="/staff" component={Staff} />}
        {isOwner && (
          <PrivateRoute exact path="/promoters" component={Promoters} />
        )}
        {isOwner && (
          <PrivateRoute exact path="/promoters/:id" component={Promoter} />
        )}
        {isAdmin && (
          <PrivateRoute exact path="/settings" component={Settings} />
        )}
        {isAdmin && (
          <PrivateRoute exact path="/settings/pages" component={Pages} />
        )}
        {isAdmin && (
          <PrivateRoute
            exact
            path="/settings/pages/:id"
            component={PageDetail}
          />
        )}
        {isAdmin && (
          <PrivateRoute
            exact
            path="/settings/email-templates"
            component={EmailTemplates}
          />
        )}
        {isAdmin && (
          <PrivateRoute
            exact
            path="/settings/email-templates/:id"
            component={EmailDetail}
          />
        )}
        {isAdmin && (
          <PrivateRoute
            exact
            path="/settings/soil-nutrients/"
            component={SoilNutrients}
          />
        )}
        {isAdmin && (
          <PrivateRoute
            exact
            path="/settings/fertilizing-types/"
            component={FertilizingTypes}
          />
        )}
        {isAdmin && (
          <PrivateRoute
            exact
            path="/settings/library-types"
            component={LibraryTypes}
          />
        )}
        {isAdmin && (
          <PrivateRoute
            exact
            path="/settings/agronomic-services"
            component={AgronomicServices}
          />
        )}
        {isAdmin && (
          <PrivateRoute
            exact
            path="/settings/agronomic-services/:id"
            component={AgronomicService}
          />
        )}
        {isAdmin && (
          <PrivateRoute
            exact
            path="/settings/dichotomous-key/:id?"
            component={DichotomousKey}
          />
        )}
        {isSysAdmin && <PrivateRoute exact path="/logs" component={Logs} />}
        <PrivateRoute exact path="/500" component={Error500} />
        <Redirect to="/dashboard" />
      </Switch>
    );
  }
}

const mapStateToProps = (state: any) => ({
  router: state.router,
  user: state.user,
  token: state.token,
});

export default connect(mapStateToProps)(Routes);
