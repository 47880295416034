import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  /* cSpell:enable */
  /* cSpell.language:pt,pt_PT */
  pt: {
    // Generic
    generic: {
      edit: "Editar",
      done: "Concluir",
      accept: "Aceitar",
      cancel: "Cancelar",
      remove: "Remover",
      delete: "Eliminar",
      success: "Sucesso",
      active: "Ativar",
      expand: "Expandir",
      toggle: "Toggle",
      save: "Guardar",
      yes: "Sim",
      no: "Não",
      apply: "Aplicar",
      item: "Artigo",
      items: "Artigos",
      submit: "Submeter",
      continue: "Continuar",
      confirm: "Confirmar",
      finish: "Terminar",
      back: "Voltar",
      oops: "Oops",
      error: "Erro",
      retry: "Tentar novamente",
      reset: "Reset",
      search: "Pesquisar",
      noResults: "Sem Resultados",
      clickHereTo: "Clique aqui para {0}",
      requiredField: "{0} é um campo obrigatório",
      duplicateEmail:
        "Este email já está a ser utilizado. Por favor utilize outro email.",
      emptyList: "Sem {0} na lista",
      leavePage: "Sair da Página?",
      leave: "Sair",
      stay: "Ficar",
      open: "Abrir",
      close: "Fechar",
      change: "Mudar",
      free: "Grátis",
      send: "Enviar",
      selected: "Selecionado",
      selecteds: "Selecionados",
      swalEditTitle: "Cancelar Alteração?",
      swalEditDescription:
        "Cancelar edição vai apagar toda a informação não guardada",
      leaveDescription:
        "Ao sair desta página vai apagar toda a informação não guardada",
      swalDeleteTitle: "Apagar {0}?",
      swalDeleteDescription:
        "Ao sair desta página vai apagar todos os {0} na sua lista",
      genericSelect: "Selecionar {0}",
      select: "Selecionar",
      addGeneric: "Adicionar {0}",
      moreOptions: "Mais Opções",
      all: "Tudo",
      enable: "Ativar",
      newF: "Nova",
      newM: "Novo",
      addNewF: "Adicionar nova {0}",
      addNewM: "Adicionar novo {0}",
      addNew: "Adicionar {0}",
      default: "Pré-Definido",
      editGeneric: "Editar {0}",
      infinite: "Infinito",
      details: "Detalhes",
      noItemsSelected: "Nenhum Item Selecionado",
      clearList: "Limpar lista",
      loading: "A Carregar...",
      view: "Ver",
      actions: "Ações",
      invalidUrl: "URL inválido. Por favor remova o prefixo HTTP.",
      shop: "Loja",
      noEntries: "Sem Valores",
      chatWalktroughText1:
        "Adicionámos diversas FAQ que podem ajudar a conhecer e entender como funciona o produto Shop2Grow. Essas FAQ estão disponíveis no ícone de chat no canto inferior direito do ecrã.",
      chatWalktroughText2:
        "Estamos também disponíveis, através de chat, para ajudar na melhor maneira de criar e definir a sua loja.",
      skip: "Saltar Walkthrough",
      walkTrough: "Walkthrough",
      clone: "Clonar",
      download: "Download",
      upload: "Upload",
      template: "Download Template",
      export: "Exportar",
      import: "Importar",
      defaultItem: "um item",
      defaultItemRecipient: "este/esta {0}",
      demo: "Demo",
      previewOnly: "Apenas Preview",
      selectPaymentMethod:
        "Por favor, selecione um método de pagamento antes de atualizar a sua subscrição.",
      basic: "Básico",
      advanced: "Avançado",
      editMode: "Modo de Edição",
      print: "Imprimir",
      code: "Codigo",
      percentage: "Percentagem",
      yesDelete: "Sim, apagar!",
    },

    fields: {
      name: "Nome",
      address: "Morada",
      addresses: "Moradas",
      country: "País",
      state: "Distrito",
      counties: "Concelhos",
      county: "Concelho",
      city: "Cidade",
      type: "Tipo",
      zipCode: "Código Postal",
      description: "Descrição",
      phone: "Telefone",
      phoneNumber: "Número de Telefone",
      phoneShort: "Tel.:",
      mobilePhone: "Telemóvel",
      email: "Email",
      acceptMarketing: "Marketing",
      seoUrl: "SEO Url",
      tags: "Tags",
      ccNumber: "Número do CC",
      billingAddress: "Morada de Faturação",
      shippingAddress: "Morada de Envio",
      vat: "VAT",
      password: "Password",
      message: "Mensagem",
      image: "Imagem",
      images: "Imagens",
      logo: "Logo",
      imgPlaceholder:
        "Tente arrastar imagens para aqui, ou clique para fazer upload",
      filesPlaceholder:
        "Tente arrastar o ficheiro para aqui ou clique para selecionar o ficheiro",
      subTotal: "Subtotal",
      total: "Total",
      quantity: "Quantidade",
      ordered: "Qtd. Encomendada",
      totalQuantity: "Quantidade Total",
      discount: "Desconto",
      registerDate: "Data Registo",
      invalidEmail: "Email não é valido",
      tax: "Taxa",
      addedOn: "Adicionado",
      startDate: "Data Inicial",
      endDate: "Data Final",
      startHour: "Hora Inicial",
      endHour: "Hora Final",
      filename: "Nome do Ficheiro",
      gender: "Género",
      waitingForResults: "À espera do nome ou SKU...",
      link: "Link",
      date: "Data",
      otherRegions: "Outras Zonas",
      taxValues: "Taxas",
      previewLink: "Link de Pré-Visualização",
      clickToUpload: "Clique para fazer o Upload",
      photo: "Foto",
      file: "Ficheiro",

      // Select
      selectCountry: "Selecionar País",
      selectState: "Selecionar Estado",
      selectFilters: "Selecionar Filtros",
      selectDiscount: "Selecionar Desconto",

      countries: "Países",
      states: "Distritos",

      // Pairs
      valuePair: "{0} / {1}",
      placeHolderPair: "{0} => {1}",

      code: "Abreviatura",
      bccEmail: "Email para receber encomendas",
    },

    language: {
      header: "Idioma",
      defaultLanguage: "Idioma Padrão",
      languageNotActive:
        "Guarde as atualizações antes de proceder à alteração do idioma padrão.",
      portuguese: "Português",
      english: "Inglês",
      german: "Alemão",
      french: "Francês",
      spanish: "Espanhol",
    },

    sidebar: {
      dashboard: "Dashboard",
      profile: "Perfil",
      information: "Informação",
      promoters: "Promotores",
      settings: "Definições",
      logs: "Logs",
      users: "Utilizadores",
      staff: "Staff",
      products: "Produtos",
      cultures: "Culturas",
      nutrients: "Nutrientes",
      news: "Noticias",
      formations: "Biblioteca",
      notifications: "Notificações",
      documents: "Documentos",
      fertilizingTypes: "Tipos de Fertilizante",
    },

    header: {
      daysLeft: "dias restantes de trial",
      upgrade: "Upgrade",
      account: "Conta",
      maintenance: "Manutenção",
      visitStore: "Visitar Loja",
      deleteSuccess: "Dados Temporários apagados com sucesso",
      extend: "Aumentar Trial",
    },

    // Screens
    authentication: {
      welcome: "Bem-vindo",
      header: "Autenticação",
      register: "Registo",
      login: "Login",
      logout: "Logout",
      signIn: "Autenticar",
      confirmAccount: "Confirmar Conta",
      name: "Nome",
      namePlaceholder: "O seu nome",
      email: "Email",
      emailPlaceholder: "O seu email",
      password: "Password",
      passwordPlaceholder: "A sua password",
      newPassword: "Nova password",
      newPasswordPlaceholder: "Inserir nova password",
      confirmPassword: "Confirmar Password",
      confirmPasswordPlaceholder: "Repetir a password",
      forgotPassword: "Esqueceu-se da password?",
      loginFooter: "Não tem conta? Registe-se aqui",
      registerFooter: "Já tem conta? Autentique-se aqui.",
      resendCode1: "Não recebeu o seu código de confirmação? ",
      resendCode2: "Reenviar código de ativação",
      sendEmail: "Enviar Email",
      recoverPassword: "Recuperar password",
      changePassword: "Mudar Password",
      changeEmail: "Mudar Email",
      newEmail: "Novo Email",
      newEmailConfirm: "Confirmar Novo Email",
      repeatEmail: "Repetir Email",
      currentEmail: "Email Atual",
      currentPassword: "Password Atual",
      emailSent: "Email Enviado com Sucesso",
      repeatPassword: "Repetir Password",
      rememberedPassword: "Lembrou-se da password?",
      adminPassword: "Password do Administrador",
      notLoggedIn:
        "Necessita de estar autenticado para ter acesso a esta página",
      noMatchPassword: "As passwords não coincidem",
      acceptSubtitle:
        "Por favor insira uma password para confirmar a sua conta.",
      goBackToLogin: "Ir para o ecrã de início de sessão",
      resendInviteEmail: "Reenviar email de convite",
      confirmResendInviteEmail: "Reenviar email de convite para {0}?",
    },

    acceptInvite: {
      header: "Aceitar Convite",
      title: "Olá {0}, {1} convidou-o(a) a gerir {2}.",
      subTitle: "Insira uma password para confirmar a sua conta",
      expired: "Este convite expirou",
    },

    notFound: {
      header: "Página Inexistente",
    },

    importModal: {
      fileName: "Nome do Ficheiro",
      size: "Tamanho",
      import: "Importar",
    },

    errors: {
      invalidArgs: "Argumentos inválidos ou em falta",
      invalidFields: "Campos inválidos ou em falta",
      emailIsInvalid: "O formato de email inserido não é válido",
      videoUrlIsInvalid: "O formato de url inserido não é válido",
      fillEmail: "Insira um email por favor",
      fillPassword: "Insira uma password por favor",
      pleaseFillFormCorrectly: "Por favor preencha o formulário correctamente",
      fillField: "Este campo é de preenchimento obrigatório",
      notSupportedFile: "Tipo de ficheiro não suportado",
      nameIsEmpty: "O campo nome é de preenchimento obrigatório",
      passwordsDontMatch:
        "Os campos 'Nova password' e 'Confirmar password' não coincidem",
      emailIsEmpty: "O campo email é de preenchimento obrigatório",
      fileTooBigorInvalid:
        "O tamanho da imagem é superior a 8MB ou tem um formato inválido",
      couldNotImportProduct: "Não foi possível importar produtos",
      selectCountryFirst: "selecione primeiro o país",
      cantDrag: "Não é permitido ordernar elementos neste momento",
    },

    profile: {
      profilePicture: "Foto de Perfil",
      role: "Cargo",
      editedSuccessfully: "Perfil editado com sucesso",
    },

    placeholders: {
      name: "O seu nome",
      email: "o_seu@email.com",
      phoneNumber: "+351265000000",
      language: "Selecione uma língua",
      role: "Selecione um cargo",
      currentPassword: "password actual",
      newPassword: "nova password",
      confirmPassword: "nova password",
      newEmail: "novo@email.com",
      page: "pagina",
      titlePage: "Título da Página",
      link: "https://www.google.com/",
    },

    staff: {
      owner: "Dono",
      admin: "Administrador",
      editedSuccessfully: "Staff editado com sucesso",
      passwordChangedSuccessfully: "Password editada com sucesso",
      emailChangedSuccessfully: "Email editado com sucesso",
      wrongPassword: "Password errada",
      emailInUse: "Email em uso",
      status: "Estado",
      confirmed: "Confirmado",
      pending: "Pendente",
    },

    ranges: {
      today: "Hoje",
      thisMonth: "Este mês",
      lastMonth: "Último mês",
      thisYear: "Este ano",
      lastYear: "Último ano",
    },

    dashboard: {
      period: "Período",
      active: "Ativo",
      homepage: "Homepage",
      inStock: "Em Stock",
      daily: 'Diário',
      monthly: 'Mensal',
      newUsers: "Novos Utilizadores",
      newUsersContent: "Novos Utilizadores Conteúdo",
      uses: "Utilizações",
      androidUse: "Utilização Android",
      androidUseContent: "Utilização Android Conteúdo",
      iOSUse: "Utilização iOS",
      iOSUseContent: "Utilização iOS Conteúdo",
      webUse: "Utilização Web",
      webUseContent: "Utilização Web Conteúdo",
    },

    settings: {
      pages: "Páginas",
      emailTemplates: "Templates de E-mails",
      soilNutrients: "Nutrientes do Solo",
      fertilizingTypes: "Tipos de Fertilizante",
      lines: "Modos de aplicação",
      dichotomousKey: "Chave Dicotómica",
      agronomicServices: "Serviços Agronómicos",
      libraryTypes: "Tipos de Biblioteca",
    },

    pages: {
      single: "Página",
      title: "Título",
      content: "Conteúdo",
      new: "Nova Página",
      isLink: "Redirecionamento",
    },

    lines: {
      header: "Modo de aplicação",
      icon: "Icon",
    },

    templates: {
      single: "Template",
    },

    emails: {
      single: "E-mail",
      tags: "Tags",
      preview: "Pré-visualização",
      subject: "Assunto",
      cc: "CC",
    },

    cultures: {
      header: "Cultura",
      file: "Ficheiro da cultura",
      deficiencies: "Carências",
      deficiency: "Carência",
      correctionOfDeficiency: "Correções da Carência",
      symptoms: "Sintomas",
      symptom: "Sintoma",
      noSymptomsAdded: "Sem Sintomas Adicionados",
      correction: "Correção",
      noCorrectionsAdded: "Sem Correções Adicionadas",
      toxicities: "Toxicidades",
      toxicity: "Toxicidade",
      correctionOfToxicity: "Correções da Toxicidade",
      mostImportantNutrients: "Nutrientes Importantes",
      nutrientsRemovel: "Extração de nutrientes",
      imageLandscape: "Imagem de Cabeçalho",
    },

    fertilizing: {
      header: "Adubação",
      productionDestiny: "Destino da Produção",
      usualProduction: "Adubação habitual",
      expectedProduction: "Adubação esperada",
      production: "Produção",
      season: "Época",
      doses: "Doses",
      dose: "Dose",
      maxConcentration: "Concentração Máxima",
      observations: "Observações",
      fertilizingType: "Tipo de Adubação",
    },

    soilFertility: {
      header: "Fertilidade do solo",
    },

    nutrients: {
      header: "Nutriente",
      value: "Valor",
      valueLimitError: "O valor deverá ser inferior a 1000",
      formula: "Formula Química",
    },

    soilNutrients: {
      header: "Nutriente do Solo",
      veryFrequent: "Muito Frequente",
      frequent: "Frequente",
      lessFrequent: "Menos Frequente",
    },

    fertilizingTypes: {
      header: "Tipo de Fertilizante",
    },

    libraryTypes: {
      header: "Tipo de Biblioteca",
    },

    dichotomousKeys: {
      header: "Chave Dicotómica",
      name: "Nome",
      description: "Description",
      createKey: "Crie uma Chave Dicotómica",
      createName: "Indique o Nome",
      createDescription: "Indique a Descrição",
      warning:
        "Como tem um nutriente selecionado não pode criar mais chaves filhas",
    },

    agronomicServices: {
      header: "Serviço Agronómico",
      attachedFiles: 'Ficheiros do Serviço',
      analysisTypes: 'Tipos de Análise',
      analisysType: 'Tipo de Análise',
      observation: "Observação",
      values: 'Valores',

      //Analisys Types
      complete: 'Completa',
      routine: 'Rotina',
      special: 'Especial',
    },

    products: {
      header: "Produto",
      shortInfo: "Pequena descrição do Produto",
      generalInfo: "Informação Geral do Produto",
      files: "Ficheiros do produto",
      advantages: "Vantagens do produto",
      formulas: "Fórmulas",
      formula: "Fórmula",
      formulaInformation: "Informação",
      formulaMoreInformation: "Mais Informação",
      formulaFiles: "Ficheiros da fórmula",
      formulaFile: "Ficheiro da fórmula",
      fertiberiaProduct: "Produto Fertiberia",
      adpProduct: "Produto ADP",
      productAvailability: "Disponibilidade do Produto",
      formulaAvailability: "Disponibilidade da Fórmula",
      availablePortugal: "Disponível em Portugal",
      availableSpain: "Disponível em Espanha",
      availableFrance: "Disponível em França",
      availableOthers: "Disponível noutros",
      color: "Cor do Produto",
      cropsAndDoses: "Culturas e dosagens",
      doses: "Dosagens",
      dose: "Dosagem",
      cropAndDose: "Cultura e dosagem",
      noCropsWithDosesAdded: "Sem Culturas e doses adicionados",
      slogan: "Slogan",
    },

    documents: {
      header: "Documento",
      file: "Ficheiro",
      documents: "Documentos",
    },

    news: {
      header: "Notícia",
      schedulePublication: "Agendar Publicação",
      shortDescription: "Pequena Descrição da Notícia",
      primaryImage: "Imagem Principal",
      secondaryImage: "Imagem dos Detalhes",
    },

    notifications: {
      header: "Notificação",
      send: "Enviar Notificação",
      sending: "Envio",
      text: "Texto da Notificação",
      sendDate: "Data de Envio",
      sendHour: "Hora de Envio",
      user: "Utilizador",
      timezone: "Timezone",
    },

    publication: {
      published: "Publicado",
      publicationDate: "Data de Publicação",
      publicationHour: "Hora de Publicação",
    },

    formation: {
      header: "Biblioteca",
      type: "Tipo de formação",
      video: "Video",
      files: "Ficheiros da Formação",
      noFilesAdded: "Sem Ficheiros Adicionados",
      youtubeLink: "Link do Youtube",
    },

    promoters: {
      header: "Promotor",
      workAreas: "Áreas de Trabalho",
      importPromoters: "Importar Promotores",
    },

    serverErrors: {
      title: "Erro de Servidor",
      subTitle: "Não é você, somos nós!",
      refresh: "Voltar à Página Anterior",
      backToDashboard: "Ir para o Dashboard",
    },

    logs: {
      dateRange: "Date Range",
      userId: "User Id",
      url: "Url",
      token: "Token",
      codes: "Codes",
      methods: "Methods",
      sources: "Sources",
      types: "Types",
    },
  },
  // /* cSpell.language:en */
  // en: {
  // 	// Generic
  // 	generic: {
  // 		edit: 'Edit',
  // 		done: 'Done',
  // 		accept: 'Accept',
  // 		cancel: 'Cancel',
  // 		remove: 'Remove',
  // 		delete: 'Delete',
  // 		success: 'Success',
  // 		active: 'Active',
  // 		expand: 'Expand',
  // 		toggle: 'Toggle',
  // 		save: 'Save',
  // 		yes: 'Yes',
  // 		no: 'No',
  // 		apply: 'Apply',
  // 		item: 'Item',
  // 		items: 'Items',
  // 		submit: 'Submit',
  // 		continue: 'Continue',
  // 		confirm: 'Confirm',
  // 		finish: 'Finish',
  // 		back: 'Back',
  // 		oops: 'Oops',
  // 		error: 'Error',
  // 		retry: 'Retry',
  // 		reset: 'Reset',
  // 		search: 'Search',
  // 		noResults: 'No Results',
  // 		clickHereTo: 'Click here to {0}',
  // 		requiredField: '{0} is a required field',
  // 		duplicateEmail: 'This email is already taken. Please use another email.',
  // 		emptyList: 'No {0} in List',
  // 		leavePage: 'Leave Page?',
  // 		leave: 'Leave',
  // 		stay: 'Stay',
  // 		open: 'Open',
  // 		close: 'Close',
  // 		change: 'Change',
  // 		free: 'Free',
  // 		send: 'Send',
  // 		selected: 'Selected',
  // 		selecteds: 'Selected',
  // 		swalEditTitle: 'Cancel Edit?',
  // 		swalEditDescription: 'Cancelling edit will erase all the unsaved information!',
  // 		leaveDescription: 'Leaving this page will erase all the unsaved information!',
  // 		swalDeleteTitle: 'Delete {0}?',
  // 		swalDeleteDescription: "By leaving this page, you'll erase all the {0} on your list",
  // 		genericSelect: 'Select {0}',
  // 		select: 'Select',
  // 		addGeneric: 'Add {0}',
  // 		moreOptions: 'More Options',
  // 		all: 'All',
  // 		enable: 'Enable',
  // 		newF: 'New',
  // 		newM: 'New',
  // 		addNewF: 'Add new {0}',
  // 		addNewM: 'Add new {0}',
  // 		default: 'Default',
  // 		editGeneric: 'Edit {0}',
  // 		infinite: 'Infinite',
  // 		details: 'Details',
  // 		noItemsSelected: 'No Items Selected',
  // 		clearList: 'Clear List',
  // 		loading: 'Loading...',
  // 		view: 'View',
  // 		actions: 'Actions',
  // 		invalidUrl: 'Invalid URL. Please remove HTTP prefix.',
  // 		shop: 'Shop',
  // 		noEntries: 'No Entries',
  // 		chatWalktroughText1:
  // 			"We've added several FAQs that will help you know and understand how the Shop2Grow product works. These FAQs are available from the chat icon at the bottom right of the screen.",
  // 		chatWalktroughText2:
  // 			'We are also available through chat to help you the best way on to setting up your store.',
  // 		skip: 'Skip',
  // 		walkTrough: 'walkthrough',
  // 		clone: 'Clone',
  // 		download: 'Download',
  // 		upload: 'Upload',
  // 		template: 'Download Template',
  // 		export: 'Export',
  // 		import: 'Import',
  // 		defaultItem: 'an item',
  // 		defaultItemRecipient: 'this {0}',
  // 		demo: 'Demo',
  // 		previewOnly: 'Preview Only',
  // 		selectPaymentMethod: 'Please select a payment method before updating your subscription.',
  // 		basic: 'Basic',
  // 		advanced: 'Advanced',
  // 		editMode: 'Edit Mode',
  // 		print: 'Print',
  // 		code: 'Code',
  // 	},

  // 	fields: {
  // 		name: 'Name',
  // 		address: 'Address',
  // 		addresses: 'Addresses',
  // 		country: 'Country',
  // 		state: 'State',
  // 		city: 'City',
  // 		zipCode: 'Zip Code',
  // 		description: 'Description',
  // 		phone: 'Phone',
  // 		phoneNumber: 'Phone Number',
  // 		phoneShort: 'Tel.:',
  // 		mobilePhone: 'Mobile Number',
  // 		email: 'Email',
  // 		seoUrl: 'SEO Url',
  // 		tags: 'Tags',
  // 		ccNumber: 'CC Number',
  // 		billingAddress: 'Billing Address',
  // 		shippingAddress: 'Shipping Address',
  // 		vat: 'VAT',
  // 		password: 'Password',
  // 		message: 'Message',
  // 		image: 'Image',
  // 		images: 'Images',
  // 		imgPlaceholder: 'Try dropping some images here, or click to select files to upload',
  // 		filesPlaceholder: 'Try dropping your file here, or click to select a file',
  // 		subTotal: 'Subtotal',
  // 		total: 'Total',
  // 		quantity: 'Quantity',
  // 		ordered: 'Ordered',
  // 		totalQuantity: 'Total Quantity',
  // 		discount: 'Discount',
  // 		registerDate: 'Register Date',
  // 		invalidEmail: 'Email is not Valid',
  // 		tax: 'Tax',
  // 		addedOn: 'Added On',
  // 		startDate: 'Start Date',
  // 		endDate: 'End Date',
  // 		startHour: 'Start Hour',
  // 		endHour: 'End Hour',
  // 		filename: 'Filename',
  // 		gender: 'Gender',
  // 		waitingForResults: 'Waiting for Name or SKU...',
  // 		link: 'Link',
  // 		date: 'Date',
  // 		otherRegions: 'Other Zones',
  // 		taxValues: 'Tax Values',
  // 		previewLink: 'Preview Link',
  // 		clickToUpload: 'Click to Upload',

  // 		// Select
  // 		selectCountry: 'Select Country',
  // 		selectState: 'Select State',
  // 		selectFilters: 'Select Filters',
  // 		selectDiscount: 'Select Discount',

  // 		countries: 'Countries',
  // 		states: 'States',

  // 		// Pairs
  // 		valuePair: '{0} / {1}',
  // 		placeHolderPair: '{0} => {1}',

  // 		code: 'Abbreviation',
  // 		bccEmail: 'Email to receive orders',
  // 	},

  // 	language: {
  // 		header: 'Language',
  // 		defaultLanguage: 'Default Language',
  // 		languageNotActive: 'Save the changes before changing the default language.',
  // 		portuguese: 'Portuguese',
  // 		english: 'English',
  // 		german: 'German',
  // 		french: 'French',
  // 		spanish: 'Spanish',
  // 	},

  // 	sidebar: {
  // 		dashboard: 'Dashboard',
  // 		profile: 'Profile',
  // 		information: 'Information',
  // 		staff: 'Staff',
  // 		settings: 'Settings',
  // 		logs: 'Logs',
  // 		users: 'Users',
  // 	},

  // 	header: {
  // 		daysLeft: 'days left on trial',
  // 		upgrade: 'Upgrade',
  // 		account: 'Account',
  // 		maintenance: 'Maintenance',
  // 		visitStore: 'Visit Store',
  // 		deleteSuccess: 'Dummy data deleted successfully',
  // 		extend: 'Extend Trial',
  // 	},

  // 	// Screens
  // 	authentication: {
  // 		welcome: 'Welcome',
  // 		header: 'Authentication',
  // 		register: 'Register',
  // 		login: 'Login',
  // 		logout: 'Logout',
  // 		signIn: 'Sign in',
  // 		confirmAccount: 'Confirm Account',
  // 		name: 'Name',
  // 		namePlaceholder: 'Your name',
  // 		email: 'Email',
  // 		emailPlaceholder: 'Your email',
  // 		password: 'Password',
  // 		passwordPlaceholder: 'Your password',
  // 		newPassword: 'New password',
  // 		newPasswordPlaceholder: 'Enter new password',
  // 		confirmPassword: 'Confirm Password',
  // 		confirmPasswordPlaceholder: 'Repeat your password',
  // 		forgotPassword: 'Forgot your password? Click here.',
  // 		loginFooter: 'No account? Register here',
  // 		registerFooter: 'Do you have an account? Login here',
  // 		resendCode1: 'Have you not received your confirmation code? ',
  // 		resendCode2: 'Resend activation code',
  // 		sendEmail: 'Send Email',
  // 		recoverPassword: 'Recover Password',
  // 		changePassword: 'Change Password',
  // 		changeEmail: 'Change Email',
  // 		newEmail: 'New Email',
  // 		repeatEmail: 'Repeat Email',
  // 		newEmailConfirm: 'Confirm New Email',
  // 		currentEmail: 'Current Email',
  // 		currentPassword: 'Current Password',
  // 		emailSent: 'Email Sent Successfully',
  // 		repeatPassword: 'Repeat Password',
  // 		rememberedPassword: 'Did you remember your password?',
  // 		adminPassword: 'Admin Password',
  // 		notLoggedIn: 'You need to be logged in to access this page',
  // 		noMatchPassword: 'Passwords don\'t match',
  // 		acceptSubtitle: 'Please input a password to confirm your account.',
  // 		goBackToLogin: 'Go to the login screen',
  // 	},

  // 	acceptInvite: {
  // 		header: 'Accept Invite',
  // 		title: 'Hi {0}, {1} has invited you to manage {2}',
  // 		subTitle: 'Please choose a password to confirm your account',
  // 		expired: 'This invitation link has expired',
  // 	},

  // 	notFound: {
  // 		header: 'Not Found',
  // 	},

  // 	importModal: {
  // 		fileName: 'Filename',
  // 		size: 'Size',
  // 		import: 'Import',
  //     },

  //     errors: {
  // 		invalidArgs: 'Invalid or missing arguments',
  // 		invalidFields: 'Invalid or missing fields',
  // 		emailIsInvalid: 'Email format is not valid',
  // 		fillEmail: 'Please input your email',
  // 		fillPassword: 'Please input your password',
  // 		pleaseFillFormCorrectly: 'Please fill the form correctly',
  // 		fillField: 'This field is mandatory',
  // 		notSupportedFile: 'File type not supported',
  // 		nameIsEmpty: 'Please fill name field',
  // 		passwordsDontMatch: 'Fields \'New password\' and \'Confirm password\' don\'t match',
  // 		emailIsEmpty: 'Please fill email field',
  // 		fileTooBigorInvalid: 'File size is larger than 8MB or file is corrupt',
  // 	},

  // 	profile: {
  // 		profilePicture: 'Profile Picture',
  // 		role: 'Role',
  // 		editedSuccessfully: 'Profile edited successfully',
  // 	},

  // 	placeholders: {
  // 		name: 'Your name',
  // 		email: 'your@email.com',
  // 		phoneNumber: '+351265000000',
  // 		language: 'Select a language option',
  // 		role: 'Select a role',
  // 		currentPassword: 'actual password',
  // 		newPassword: 'new password',
  // 		confirmPassword: 'new password',
  // 		newEmail: 'new@email.com',
  // 		page: 'page',
  // 		titlePage: 'Page Title',
  // 		link: 'https://www.google.com/',
  // 	},

  // 	staff: {
  // 		owner: 'Owner',
  // 		admin: 'Administrator',
  // 		editedSuccessfully: 'Staff edited successfully',
  // 		passwordChangedSuccessfully: 'Password changed successfully',
  // 		emailChangedSuccessfully: 'Email changed successfully',
  // 		wrongPassword: 'Wrong password',
  // 		emailInUse: 'Email in use',
  // 	},

  // 	ranges: {
  // 		today: 'Today',
  // 		thisMonth: 'This month',
  // 		lastMonth: 'Last month',
  // 		thisYear: 'This year',
  // 		lastYear: 'Last year',
  // 	},

  // 	dashboard: {
  // 		period: 'Period',
  // 		active: 'Active',
  // 		homepage: 'Homepage',
  // 		inStock: 'In Stock',
  // 	},

  // 	settings: {
  // 		pages: 'Pages',
  // 		emailTemplates: 'E-mail Settings',
  // 	},

  // 	pages: {
  // 		single: 'Page',
  // 		title: 'Title',
  // 		content: 'Content',
  // 		new: 'New Page',
  // 		isLink: 'Redirect',
  // 	},

  // 	templates: {
  // 		single: 'Template',
  // 	},

  // 	emails: {
  // 		single: 'E-mail',
  // 		tags: 'Tags',
  // 		preview: 'Preview',
  // 		subject: 'Subject',
  // 		cc: 'CC',
  // 	},

  // 	serverErrors: {
  // 		title: 'Internal Server Error',
  // 		subTitle: 'It\'s not you, it\'s us!',
  // 		refresh: 'Go Back to Previous Page',
  // 		backToDashboard: 'Go to Dashboard',
  // 	},

  // 	logs: {
  // 		dateRange: 'Date Range',
  // 		userId: 'User Id',
  // 		url: 'Url',
  // 		token: 'Token',
  // 		codes: 'Codes',
  // 		methods: 'Methods',
  // 		sources: 'Sources',
  // 		types: 'Types',
  // 	},
  // },
});
