// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmButton{min-width:45%;margin:15px 0}.ConfirmButton{background:#fb6819 !important;color:#fff !important;border:#fb6819 !important}.ConfirmButton:hover{background:#030303 !important;color:#fb6819 !important;border:#030303 !important}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#fb6819",
	"lightPrimary": "#fc9c69",
	"secondaryColor": "#030303",
	"lightSecondary": "#959fa3"
};
module.exports = exports;
