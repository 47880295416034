// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".h1{color:#fb6819}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#fb6819",
	"lightPrimary": "#fc9c69",
	"secondaryColor": "#030303",
	"lightSecondary": "#959fa3"
};
module.exports = exports;
