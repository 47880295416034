/*
 *
 * FertilizingTypes
 *
 */

import React from "react";
import { connect } from "react-redux";
import {
  setTitle,
  setLoader,
  setBreadcrumb,
  delayedDispatch,
  updateCrumb,
} from "store/actions";
import { Table } from "components";
import { EditSidebar } from "components/EditSidebar";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";
import moment from "moment";
//import "./styles.scss";

export class FertilizingTypes extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      language: "pt",
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle(Strings.sidebar.fertilizingTypes));

    delayedDispatch(
      setBreadcrumb(() => {
        return {
          locations: [
            {
              text: Strings.sidebar.settings,
              route: "/settings",
              icon: "preferences",
            },
            {
              text: Strings.sidebar.fertilizingTypes,
              //   route: "/settings/fertilizing-types",
              icon: "growth",
            },
          ],
        };
      })
    );

    this.getFertilizingTypes();
  }

  componentDidUpdate() {
    const { dispatch } = this.props;

    dispatch(updateCrumb());
  }

  async getFertilizingTypes() {
    const response = await API.get({
      url: Endpoints.uriFertilizingTypes(),
    });

    if (response.ok) {
      this.setState({ fertilizingTypes: response.data.results });
    } else {
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }
  }

  onSubmit = async () => {
    const { dispatch } = this.props;
    const { item } = this.state;

    if (!item?.name) {
      return toast.warn(Strings.errors.invalidFields);
    }

    dispatch(setLoader(true));

    const request = item._id ? API.put : API.post;
    const id = item._id || "";

    const response = await request({
      url: Endpoints.uriFertilizingTypes(id),
      data: {
        name: item.name,
        isActive: item._id ? item.isActive : true,
      },
    });

    if (response.ok) {
      this.setState({
        fertilizingTypes: response.data.results,
        openSidebar: false,
        hasUnsavedFields: false,
      });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  deleteFertilizingType = async (id: string) => {
    const { dispatch } = this.props;
    dispatch(setLoader(true));

    const response = await API.delete({
      url: Endpoints.uriFertilizingTypes(id),
    });

    if (response.ok) {
      this.setState({ fertilizingTypes: response.data.results });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }
    dispatch(setLoader(false));
  };

  toggleFertilizingType = async (original: any) => {
    const { dispatch } = this.props;
    dispatch(setLoader(true));

    let body = { isActive: !original.isActive };
    const response = await API.patch({
      url: Endpoints.uriFertilizingTypes(original._id),
      data: body,
    });

    if (response.ok) {
      this.setState({ fertilizingTypes: response.data.results });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }
    dispatch(setLoader(false));
  };

  onChange = (field: string, value: any) => {
    const { item, language } = this.state;
    if (field === "name")
      this.setState({
        item: { ...item, [field]: { ...item[field], [language]: value } },
      });
    else this.setState({ item: { ...item, [field]: value } });
  };

  openSidebar = (value: any) => {
    this.setState({
      openSidebar: true,
      item: JSON.parse(JSON.stringify(value)),
    });
  };

  closeSidebar = () => {
    this.setState({ openSidebar: false });
  };

  renderDrawer() {
    const { item, language } = this.state;
    return (
      <EditSidebar
        title={Strings.fertilizingTypes.header}
        open={this.state.openSidebar}
        onClose={this.closeSidebar}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        defaultValue={this.state.item}
        language={language}
        onChangeLanguage={(lang: any) => this.setState({ language: lang })}
        closable={false}
        fields={[
          {
            field: "name",
            value: item?.name?.[language],
            type: "input",
            name: Strings.fields.name,
            required: true,
          },
          {
            version: moment.now(),
          },
        ]}
      />
    );
  }

  render() {
    const { fertilizingTypes = [], language } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.fertilizingTypes}</title>
          <meta name="description" content="Description of Fertilizing Types" />
        </Helmet>
        <Table
          title={{
            icon: "growth",
            title: Strings.sidebar.fertilizingTypes,
          }}
          data={fertilizingTypes}
          columns={[
            {
              Header: Strings.fertilizingTypes.header,
              id: "name",
              accessor: (row: any) => `${row.name?.[language]}`,
            },
          ]}
          filterable
          add={{
            label: String(
              Strings.formatString(
                Strings.generic.addNew,
                Strings.fertilizingTypes.header.toLowerCase()
              )
            ),
            onClick: () => this.openSidebar({}),
          }}
          actions={{
            edit: (original, value) => ({
              onClick: () => this.openSidebar(original),
            }),
            remove: (original, value) => ({
              onClick: () => this.deleteFertilizingType(original._id),
            }),
            toggle: (original, value) => ({
              value: original.isActive,
              onChange: () => this.toggleFertilizingType(original),
            }),
          }}
        />
        {this.renderDrawer()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(FertilizingTypes);
