/*
 *
 * DichotomousKey
 *
 */

import React from "react";
import { connect } from "react-redux";
import {
  setTitle,
  setLoader,
  setBreadcrumb,
  delayedDispatch,
  updateCrumb,
} from "store/actions";
import { EditSidebar } from "components/EditSidebar";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";
import { Row, Col, Select } from "antd";
import { ContentWrapper, Table } from "components";
import moment from "moment";
import { push } from "connected-react-router";
import "./styles.scss";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;

export class DichotomousKey extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      language: "pt",
      item: { name: "", description: "", parent: null, nutrient: "" },
      dichotomousKeys: null,
      nutrients: [],
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    // change this
    dispatch(setTitle(Strings.settings.dichotomousKey));

    delayedDispatch(
      setBreadcrumb(() => {
        const { dichotomousKeys, language } = this.state;

        return {
          locations: [
            {
              text: Strings.sidebar.settings,
              route: "/settings",
              icon: "preferences",
            },
            {
              text: Strings.settings.dichotomousKey,
              icon: "analysis-1",
            },
          ],
          actions: [
            {
              type: "button",
              text: Strings.generic.back,
              onClick: () => this.onClickFunc(dichotomousKeys?.parent || ""),
              disabled: !match.params.id,
            },
            {
              type: "language",
              value: language,
              onChange: (lang: any) => this.setState({ language: lang }),
              separator: "left",
            },
            {
              type: "button",
              text: Strings.generic.save,
              onClick: () => this.onPut(match.params.id),
              disabled: !this.state.hasUnsavedFields,
              separator: "left",
            },
          ],
        };
      })
    );
    this.getDichotomousKeys(match.params.id);
  }

  componentDidUpdate() {
    const { dispatch } = this.props;

    dispatch(updateCrumb());
  }

  async getDichotomousKeys(id: any) {
    const { match } = this.props;

    let nuts = [];

    const nutrientsResponse = await API.get({
      url: Endpoints.uriNutrients(),
    });

    if (nutrientsResponse.ok) {
      this.setState({ nutrients: nutrientsResponse.data.results || "" });
      nuts = nutrientsResponse.data.results;
    } else {
      return toast.error(
        nutrientsResponse.data?.message || Strings.serverErrors.title
      );
    }

    const keyResponse = await API.get({
      url: Endpoints.uriDichotomousKey(id || match.params.id),
    });

    if (keyResponse.ok) {
      const key = keyResponse.data.results;
      console.log("key1", key);
      key.nutrient = nuts.find((elem: any) => elem._id === key.nutrient);
      this.setState({ dichotomousKeys: key || "" });
    } else {
      return toast.error(
        keyResponse.data?.message || Strings.serverErrors.title
      );
    }
  }

  onPut = async (id: any) => {
    const { dispatch } = this.props;
    const { dichotomousKeys, nutrients } = this.state;

    if (!dichotomousKeys?.name) {
      return toast.warn(Strings.errors.invalidFields);
    }

    dispatch(setLoader(true));

    const response = await API.put({
      url: Endpoints.uriDichotomousKey(id),
      data: {
        name: dichotomousKeys.name,
        description: dichotomousKeys.description,
        nutrient: dichotomousKeys.nutrient?._id || null,
      },
    });

    if (response.ok) {
      const key = response.data.results;
      key.nutrient = nutrients.find((elem: any) => elem._id === key.nutrient);
      this.setState({
        dichotomousKeys: key,
      });
      toast.success(response.data?.message);
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }

    dispatch(setLoader(false));
  };

  onSubmit = async () => {
    const { dispatch, match } = this.props;
    const { item } = this.state;

    if (!item?.name) {
      return toast.warn(Strings.errors.invalidFields);
    }

    dispatch(setLoader(true));

    const response = await API.post({
      url: Endpoints.uriDichotomousKey(),
      data: {
        name: item.name,
        description: item.description,
        parent: match.params.id || null,
        nutrient: item.nutrient?._id || undefined,
      },
    });

    if (response.ok) {
      this.setState({
        dichotomousKeys: response.data.results,
      });
      toast.success(response.data?.message);
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }
    this.closeSidebar();
    dispatch(setLoader(false));
  };

  deleteDichotomousKey = async (id: string) => {
    const { dispatch, match } = this.props;
    dispatch(setLoader(true));

    const response = await API.delete({
      url: Endpoints.uriDichotomousKey(`${match.params.id}/${id}`),
    });

    if (response.ok) {
      this.setState({ dichotomousKeys: response.data.results });
    } else {
      dispatch(setLoader(false));
      return toast.error(response.data?.message || Strings.serverErrors.title);
    }
    dispatch(setLoader(false));
  };

  onChange = (field: string, value: any) => {
    const { item, language } = this.state;
    if (field === "name" || field === "description")
      this.setState({
        item: { ...item, [field]: { ...item[field], [language]: value } },
      });
    else this.setState({ item: { ...item, [field]: value } });
  };

  openSidebar = (value: any) => {
    this.setState({
      openSidebar: true,
      item: JSON.parse(JSON.stringify(value)),
    });
  };

  closeSidebar = () => {
    this.setState({ openSidebar: false });
  };

  renderExtraSidebarFields = () => {
    const { item, nutrients, language, dichotomousKeys } = this.state;
    return (
      <>
        <Select
          showSearch
          allowClear
          disabled={
            dichotomousKeys?.children && dichotomousKeys?.children.length > 0 && dichotomousKeys?.parent
          }
          placeholder={Strings.nutrients.header}
          optionFilterProp="children"
          onChange={(id: any) =>
            this.setState({
              item: {
                ...item,
                nutrient: nutrients.find((elem: any) => elem._id === id),
              },
              hasUnsavedFields: true,
            })
          }
          style={{ marginRight: "5px", width: "97%" }}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={item?.nutrient?.name?.[language]}
        >
          {nutrients?.map(
            (elem: any) =>
              elem.name?.[language] && (
                <Option value={elem._id}>{elem.name[language]}</Option>
              )
          )}
        </Select>
      </>
    );
  };

  renderDrawer() {
    const { item, language } = this.state;
    return (
      <EditSidebar
        title={Strings.dichotomousKeys.header}
        open={this.state.openSidebar}
        onClose={this.closeSidebar}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        defaultValue={this.state.item}
        language={language}
        onChangeLanguage={(lang: any) => this.setState({ language: lang })}
        closable={false}
        fields={[
          {
            field: "name",
            value: item?.name?.[language],
            type: "input",
            name: Strings.fields.name,
            required: true,
          },
          {
            field: "description",
            value: item?.description?.[language],
            type: "textArea",
            name: Strings.fields.description,
          },
          {
            version: moment.now(),
          },
        ]}
        extraFields={this.renderExtraSidebarFields}
      />
    );
  }

  onClickFunc = (id: any) => {
    const { dispatch } = this.props;

    setTimeout(function () {
      dispatch(push("/"));
      dispatch(push(`/settings/dichotomous-key/${id}`));
    }, 0);
  };

  render() {
    const { match } = this.props;
    const { dichotomousKeys, language, nutrients } = this.state;
    //console.log("nutrients: ", nutrients);
    console.log("dichotomousKeys: ", dichotomousKeys);
    return (
      <React.Fragment>
        {match.params.id ? (
          <ContentWrapper>
            <Row gutter={[24, 24]}>
              <Col xs={24} lg={12} md={12}>
                <label className="SingleLabel __required">
                  {Strings.fields.name}
                </label>
                <TextArea
                  placeholder={Strings.fields.name}
                  defaultValue={dichotomousKeys?.name?.[language]}
                  value={dichotomousKeys?.name?.[language]}
                  onChange={(e) => {
                    this.setState({
                      dichotomousKeys: {
                        ...dichotomousKeys,
                        name: {
                          ...dichotomousKeys.name,
                          [language]: e.target.value,
                        },
                      },
                      hasUnsavedFields: true,
                    });
                  }}
                />
              </Col>

              <Col xs={24} lg={12} md={12}>
                <label className="SingleLabel">
                  {Strings.fields.description}
                </label>
                <TextArea
                  placeholder={Strings.fields.description}
                  defaultValue={dichotomousKeys?.description?.[language]}
                  value={dichotomousKeys?.description?.[language]}
                  onChange={(e) => {
                    this.setState({
                      dichotomousKeys: {
                        ...dichotomousKeys,
                        description: {
                          ...dichotomousKeys.description,
                          [language]: e.target.value,
                        },
                      },
                      hasUnsavedFields: true,
                    });
                  }}
                />
              </Col>

              <Col
                xs={24}
                lg={12}
                md={12}
                style={{ padding: "12px 12px 0px 12px" }}
              >
                <label className="SingleLabel">
                  {Strings.nutrients.header}
                </label>
                <Select
                  showSearch
                  allowClear
                  disabled={dichotomousKeys?.children && dichotomousKeys?.children.length > 0}
                  placeholder={Strings.nutrients.header}
                  optionFilterProp="children"
                  onChange={(id: any) => {
                    this.setState({
                      dichotomousKeys: {
                        ...dichotomousKeys,
                        nutrient: nutrients.find(
                          (elem: any) => elem._id === id
                        ),
                      },
                      hasUnsavedFields: true,
                    });
                  }}
                  style={{ marginRight: "5px", width: "100%" }}
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  //defaultValue={dichotomousKeys?.nutrient?.name?.[language]}
                  value={dichotomousKeys?.nutrient?.name?.[language]}
                >
                  {nutrients?.map(
                    (elem: any) =>
                      elem.name?.[language] && (
                        <Option value={elem._id}>{elem.name[language]}</Option>
                      )
                  )}
                </Select>
              </Col>
              {dichotomousKeys?.nutrient ? (
                <Col
                  xs={24}
                  lg={24}
                  md={24}
                  style={{ padding: "0px 0px 0px 12px" }}
                >
                  <h4 style={{ color: "red" }}>
                    {Strings.dichotomousKeys.warning}
                  </h4>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </ContentWrapper>
        ) : (
          <></>
        )}

        <Helmet>
          <title>{Strings.settings.dichotomousKey}</title>
          <meta name="description" content="Description of Dichotomous Keys" />
        </Helmet>
        {!dichotomousKeys?.nutrient ? (
          <Table
            title={{
              icon: "analysis-1",
              title: Strings.settings.dichotomousKey,
            }}
            data={dichotomousKeys?.children}
            columns={[
              {
                Header: Strings.fields.name,
                id: "name",
                accessor: (row: any) => row.name?.[language],
              },
            ]}
            filterable
            add={{
              label: String(
                Strings.formatString(
                  Strings.generic.addNew,
                  Strings.dichotomousKeys.header.toLowerCase()
                )
              ),
              onClick: () => this.openSidebar({}),
            }}
            actions={{
              edit: (original) => ({
                onClick: () => {
                  this.onClickFunc(original._id);
                },
              }),
              remove: (original) => ({
                onClick: () => this.deleteDichotomousKey(original._id),
              }),
            }}
          />
        ) : (
          <></>
        )}

        {this.renderDrawer()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(DichotomousKey);
