// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".filesList{display:block;margin:5px 20px}.filesList .fileItem{display:inline-flex;align-items:center;margin-right:15px}.filesList .fileItem .fileIcon{position:absolute}.filesList .fileItem .fileIcon:hover{cursor:pointer;font-size:15px}.filesList .fileItem .fileName{margin-left:20px}.filesList .fileItem:hover .fileIcon{color:red}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#fb6819",
	"lightPrimary": "#fc9c69",
	"secondaryColor": "#030303",
	"lightSecondary": "#959fa3"
};
module.exports = exports;
