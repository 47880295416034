/* eslint-disable no-restricted-globals */
/*
 *
 * PageDetail
 *
 */

import React from "react";
import { connect } from "react-redux";
import {
  delayedDispatch,
  setBreadcrumb,
  setLoader,
  setTitle,
  updateCrumb,
} from "store/actions";
import { Col, Row, Input } from "antd";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { push } from 'connected-react-router';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import { toast } from "utils/utils";
import "./styles.scss";

export class EmailDetail extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      values: null,
      language: "pt",
      hasUnsavedFields: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  async componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(setLoader(true));

    const response = await API.get({
      url: Endpoints.uriEmailTemplate(match?.params?.id),
    });

    if (response.ok) {
      const email = response.data.results;
      this.setState({ email });
      dispatch(
        setTitle(
          `${Strings.emails.single} - ${response.data.results.email?.name || ''}`
        )
      );
    }

    delayedDispatch(
      setBreadcrumb(() => {
        const { hasUnsavedFields, language } = this.state;

        return {
          locations: [
            {
              text: Strings.sidebar.settings,
              route: "/settings",
              icon: "preferences",
            },
            {
              text: Strings.settings.emailTemplates,
              route: "/settings/email-templates",
              icon: "testimonial",
            },
            {
              text: this.state.email?.key,
              icon: "testimonial",
            },
          ],
          actions: [
            {
              type: "language",
              value: language,
              onChange: this.handleLanguageChange,
              className: "BreadcrumbLanguage",
              margin: "left",
            },
            {
              type: "button",
              text: Strings.generic.save,
              onClick: this.handleSave,
              disabled: !hasUnsavedFields,
              className: hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
              separator: "left",
              isSave: true,
            },
          ],
        };
      })
    );

    dispatch(setLoader(false));
  }

  componentDidUpdate() {
    const { dispatch } = this.props;

    dispatch(updateCrumb());
  }

  // addCC = (e: any) => {
  //   const { emails } = this.state;
  //   let { email } = this.state;

  //   const cc = e.map((ele: any) => emails.find((elem: any) => elem.name === ele)) || [];

  //   email.cc = cc.filter((elem: any) => elem);

  //   this.setState({ hasUnsavedFields: true, email });
  // }

  isValid() {
    const { email } = this.state;

    return email?.subject && email?.content;
  }

  async handleSave(event: any) {
    const { email } = this.state;
    const { dispatch } = this.props;

    if (!email.subject || !email.values) {
      return toast.warn(Strings.errors.invalidFields);
    }

    const response = await API.put({
      url: Endpoints.uriEmailTemplate(email._id),
      data: { key: email.key, values: email.values, subject: email.subject },
    });

    if (response.ok) {
      dispatch(push('/settings/email-templates'))
    }
    if (response.problem) {
      toast.warning(response.data);
    }

    this.setState((state: any) => ({
      hasUnsavedFields: false,
    }));
  }

  handleLanguageChange(value: any, options?: any) {
    this.setState({ language: value });
  }

  render() {
    const { email, language, isMobile } = this.state;

    console.log('email', email);

    return (
      <React.Fragment>
        <Helmet>
          <title>{email?.key}</title>
          <meta name="description" content="Description of Email Detail" />
        </Helmet>
        <Row>
          <Col md={24}>
            <label className="SingleLabel">{Strings.emails.subject}</label>
            <Input
              value={email?.subject?.[language]}
              placeholder={Strings.emails.subject}
              onChange={e => this.setState(({ email:{ ...email, subject: { ...email?.subject, [language]: e.target.value } }, hasUnsavedFields: true }))}
            />
          </Col>
        </Row>
        {/* <Row gutter={[24, 24]}>
          <Col md={24}>
            <label className="SingleLabel">{Strings.emails.cc}</label>
            <Select
              placeholder={Strings.emails.cc}
              mode='multiple'
              style={{ width: '100%' }}
              showSearch
              allowClear
              value={email?.cc?.map((ele: any) => ele.name)}
              onChange={e => this.addCC(e)}
            >
              {emailNames}
            </Select>
          </Col>
        </Row> */}
        <Row gutter={[12, 0]}>
          <Col xs={24} xl={12}>
            <span className="SingleLabel __required" style={{height: '40px'}}>
                {Strings.pages.content}
            </span>
            <AceEditor
                key={`html_${language}`}
                mode="html"
                theme="monokai"
                name="email_content"
                onChange={(newValue:any) => {
                  console.log(newValue)
                  this.setState({
                      email: {
                        ...email,
                        values: { ...email?.values, [language]: newValue },
                      },
                      hasUnsavedFields: true,
                  })
                }}
                fontSize={14}
                showPrintMargin
                showGutter
                highlightActiveLine
                value={(email?.values && email?.values?.[language]) || ''}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}
            />
          </Col>
          <Col xs={24} xl={12}>
              <div className="email_preview_options" style={{height: '40px'}}>
                  <span className="SingleLabel">
                      {Strings.emails.preview}
                  </span>
                  <div onClick={() => this.setState((state: any) => ({ isMobile: !state.isMobile }))} className={`email_preview_devices${isMobile ? ' active' : ''}`}>
                      <em className="moon-responsive" />
                  </div>
              </div>
              <div className="email_preview_block">
                  <div className={`email_preview${isMobile ? ' preview_mobile' : ''}`}>
                      <iframe srcDoc={email?.values?.[language]} title="Email Preview" />
                  </div>
              </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(EmailDetail);
