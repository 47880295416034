/* eslint-disable eqeqeq */
/*
*
* Formation
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setLoader, setBreadcrumb, delayedDispatch, updateCrumb } from 'store/actions';
import { Helmet } from 'react-helmet';
import Dropzone from 'react-dropzone';
import { push } from 'connected-react-router';
import { EditSidebar } from 'components/EditSidebar';
import { Table, Editor, Icon, ContentWrapper } from 'components';
import { Row, Col, Input, Switch, DatePicker, Select } from 'antd';
import { API, Endpoints } from "utils/api";
import { toast } from 'utils/utils';
import Compressor from "compressorjs";
import moment from 'moment';
import { urlRegex } from 'utils/utils';
import Strings from 'utils/strings';
import placeholder from 'assets/images/placeholders/image.jpg';
import './styles.scss';

const { Option } = Select;

export class Formation extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			formation: {
				attachedFiles: [],
				timezone: "Europe/Lisbon",
				isActive: true,
			},
			filesToDelete: [],
			language: 'pt',
			sidebarLanguage: 'pt',
			defaultType: 'image',
			trainingTypes: [{
				value: 'image',
				text: 'Image',
			}, {
				value: 'video',
				text: 'Video',
			}, {
				value: 'youtubeUrl',
				text: 'Youtube Url',
			}],
			countries: [],
			timezones: [],
			libraryTypes: [],
		};
	}

	componentDidMount() {
		const { match, dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.formation.header));

		this.getData();
		if(match.params.id !== 'new') this.getFormation();
		else {
			delayedDispatch(
				setBreadcrumb(() => {
					const { formation, defaultType, trainingTypes, language } = this.state;
					const { match } = this.props;
					return {
						locations: [
							{
								text: Strings.sidebar.formations,
								route: "/formations",
								icon: "working-briefcase",
							},
							{
								text: match.params.id === 'new' ? 'new' : formation.name?.[language],
								// route: `/parts/${match.params.id}`,
								icon: match.params.id === 'new' ? 'adicionar-acrescentar' : 'lapis',
							},
						],
						mobileStyleAt: 1120,
						actions: [
							{
								type: "switch",
								text: Strings.publication.published,
								value: formation.published,
								onClick: (published: any) => this.setState({ formation: { ...formation, published } }),
								small: {
									text: true,
									switch: true,
								},
							},
							{
								type: "select",
								text: Strings.formation.type,
								value: formation.type || defaultType,
								options: trainingTypes,
								separator: 'left',
								onChange: (type: any) => this.setState({ formation: { ...formation, type } }),
							},
							{
								type: "language",
								value: language,
								separator: 'left',
								onChange: (lang: any) => this.setState({ language: lang }),
							},
							{
								type: "button",
								text: Strings.generic.save,
								onClick: this.submitFormation,
								disabled: !this.state.hasUnsavedFields,
								className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
								separator: 'left',
								isSave: true,
							},
						]
					}
				})
			);
		}
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
	
		dispatch(updateCrumb());
	}

	async getData() {
		const [ response, libraryTypesResponse ] = await Promise.all([
			API.get({
				url: Endpoints.uriCountries(),
			}),
			API.get({
				url: Endpoints.uriLibraryTypes(),
			}),
		]);

		if (response.ok) {
			const countries = response.data.results;
			const portugal = countries.find((elem: any) => elem.name === "Portugal");

			this.setState({
				countries,
				formation: {
					...this.state.formation,
					country: this.state.formation.country || portugal._id,
				},
				timezones: portugal.timezones,
			});
		} else {
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		if (libraryTypesResponse.ok) {
			this.setState({
				libraryTypes: libraryTypesResponse.data.results,
			});
		} else {
			return toast.error(libraryTypesResponse.data?.message || Strings.serverErrors.title);
		}
	}

	async getFormation() {
		const { match, dispatch } = this.props;

		dispatch(setLoader(true))

		const response = await API.get({
			url: Endpoints.uriFormations(match.params.id),
		});

		if (response.ok) {
			const formation = response.data.results;

			// formation.type = trainingTypes.find((elem: any) => elem.value === formation.type);

			this.setState({ formation }, () => {
				delayedDispatch(
					setBreadcrumb(() => {
						const { formation, defaultType, trainingTypes, language } = this.state;
						const { match } = this.props;
						return {
							locations: [
								{
									  text: Strings.sidebar.formations,
									route: "/formations",
									  icon: "working-briefcase",
								},
								{
									text: match.params.id === 'new' ? 'new' : formation.name?.[language],
									// route: `/parts/${match.params.id}`,
									icon: match.params.id === 'new' ? 'adicionar-acrescentar' : 'lapis',
								},
							],
							mobileStyleAt: 1120,
							actions: [
								{
									type: "switch",
									text: Strings.publication.published,
									value: formation.published,
									onClick: (published: any) => this.setState({ formation: { ...formation, published } }),
									small: {
										text: true,
										switch: true,
									},
								},
								{
									type: "select",
									text: Strings.formation.type,
									value: formation.type || defaultType,
									options: trainingTypes,
									separator: 'left',
									onChange: (type: any) => this.setState({ formation: { ...formation, type } }),
								},
								{
									type: "language",
									value: language,
									separator: 'left',
									onChange: (lang: any) => this.setState({ language: lang }),
								},
								{
									type: "button",
									text: Strings.generic.save,
									onClick: this.submitFormation,
									disabled: !this.state.hasUnsavedFields,
									className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
									separator: 'left',
									isSave: true,
								},
							]
						}
					})
				);
			});
			
		} else {
			dispatch(push('/formations/new'))
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false))
	}

	isValid() {
		const { formation } = this.state;
	
		return formation.name && formation.category && (formation.description || (formation.image || formation.video || (formation.youtubeLink && formation.youtubeLink.match(urlRegex))));
	}

	submitFormation = async () => {
		const { match, dispatch } = this.props;
		const { formation, filesToDelete } = this.state;
		
		if (!this.isValid()) {
			return toast.warn(Strings.errors.invalidFields);
		}

		dispatch(setLoader(true));

        const body = new FormData();

        body.append("name", JSON.stringify(formation.name));
        body.append("category", JSON.stringify(formation.category));
        if (formation.youtubeLink) body.append("youtubeLink", JSON.stringify(formation.youtubeLink));
        body.append("description", JSON.stringify(formation.description || {}));
        body.append("published", formation.published || false);

		body.append("publishSchedule", formation.publishSchedule || false);

		if (formation.publishSchedule) {
        	body.append("country", formation.country);
        	body.append("timezone", formation.timezone);
			body.append("publishScheduleDate", formation.publishScheduleDate);
		}

		body.append("type", formation.type || 'image');
		body.append("notifyUsers", formation.notifyUsers || false);

		if (formation.notifyUsers) {
			body.append("notificationTitle", JSON.stringify(formation.notificationTitle));
			body.append("notificationDescription", JSON.stringify(formation.notificationDescription));
		}

		if(formation.image && formation.image.file) {
			// const image = { ...formation.image.file, fileName: `image_${formation.image.file.name}` }
			body.append(`image_${formation.image.file.name}`, formation.image.file);
		} else body.append('image', formation.image || '');

		if(formation.video && formation.video.file) {
			body.append(`video_${formation.video.file.name}`, formation.video.file);
		} else if (formation.video)
			body.append('video', formation.video);
		
		if (match?.params?.id !== 'new') body.append('filesToDelete', JSON.stringify(filesToDelete));

		let index = 0;
		for (const file of formation.attachedFiles) {
			const auxUrlList = JSON.parse(JSON.stringify({}));
			if (file.url)
				for(const lang of Object.keys(file.url)) {
					if(file.url[lang] && file.url[lang].file) {
						body.append(`file_${index}_${lang}_${file.name[lang]}`, file.url[lang].file);
					} else if (typeof file.url[lang] === 'string') {
						auxUrlList[lang] = file.url[lang];
					}
				}

			formation.attachedFiles[index] = {
				name: file.name,
				value: `${index}`,
				attachedFiles: file.url,
				url: auxUrlList,
			}
			index++;
		}
		body.append('attachedFiles', JSON.stringify(formation.attachedFiles));

		for (const pair of body.entries()) {
			console.log(`${pair[0]}, ${pair[1]}`);
		}

		const request = match?.params?.id === 'new' ? API.post : API.put;
	
		const response = await request({
			url: Endpoints.uriFormations(match?.params?.id !== 'new' ? match?.params?.id : ''),
			data: body,
		});
	
		if (response.ok) {
			this.setState({ formation: response.data.results, hasUnsavedFields: false });
			dispatch(push('/'));
			dispatch(push(`/formations/${response.data.results._id}`))
		} else {
			dispatch(setLoader(false));
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false));
	}

	addPDFFile = (file: any) => {
		const { formation, language } = this.state;

		if (!formation.url) formation.url = [];
		if (!formation.url[language]) formation.url[language] = [];

		this.setState({
			formation: { ...formation, url: { ...formation.url, [language]: [ ...formation.url[language], file ] } },
			hasUnsavedFields: true,
		});
	}

	removeFile = (index: any) => {
		const { formation, language } = this.state;

		formation.url[language].splice(index, 1)

		this.setState((state: any) => ({
			formation: { ...formation, url: { ...formation.url, [language]: formation.url[language] } },
			hasUnsavedFields: true,
		}))
	}

	onDropFile = async (files: any) => {
		try {
			for(const file of files) {
				let reader = new FileReader();
				const addPDFFile = this.addPDFFile;
	
				reader.onload = function(e: any) {
					let blob = new Blob([new Uint8Array(e.target.result)], { type: file.type });
					addPDFFile({ file: blob, fileName: file.name });
				};
				reader.readAsArrayBuffer(file);
			}
		} catch(err) {
			toast.warning(Strings.errors.notSupportedFile);
	  	}
	}

	onChange = (field: string, value: any) => {
		const { item, sidebarLanguage } = this.state;
		this.setState((state: any) => ({
			item: {
				...item,
				[field]: {
					...item[field],
					[sidebarLanguage]: value
				},
			},
			filesToDelete: field === 'url' &&
							item[field] &&
							item[field][sidebarLanguage] &&
							typeof item[field][sidebarLanguage] === 'string' ?
								[...state.filesToDelete, item[field][sidebarLanguage] ] :
								state.filesToDelete,
		}));
	}

	openFileSidebar = (item: any = {}) => {
		this.setState({
			openSidebar:true,
			item,
		});
	}

	closeSidebar = () => {
		this.setState({ openSidebar: false });
	}

	onSubmit = () => {
		const { item } = this.state;
		if (item && (item._id || item.index >= 0))
			this.editFile(item);
		else
			this.addFile(item)
	}

	addFile = async (file: any) => {
		// Add formation
		file.url && Object.keys(file.url).map(lang => {
			file.url[lang] = file.url[lang]?.[0] || undefined;
			return lang;
		})

		const { formation } = this.state;
		const auxFile = formation.attachedFiles ? JSON.parse(JSON.stringify(formation.attachedFiles)) : [];
		file.isActive = true;
		auxFile.push(file)
		this.setState({ formation: { ...formation, attachedFiles: auxFile }, openSidebar: false, hasUnsavedFields: true })
	}

	editFile = async (file: any) => {
		// Edit formation
		// eslint-disable-next-line array-callback-return
		file.url && Object.keys(file.url).map(lang => {
			file.url[lang] = typeof file.url[lang] === 'string' ? file.url[lang] : file.url[lang]?.[0] || undefined;
		})

		const { formation } = this.state;
		const auxFile = formation.attachedFiles ? JSON.parse(JSON.stringify(formation.attachedFiles)) : [];
		const index = file._id ?
						formation.attachedFiles.findIndex((elem: any) => elem._id === file._id) :
						file.index;
		auxFile.splice(index, 1, file);
		this.setState({ formation: { ...formation, attachedFiles: auxFile }, openSidebar: false, hasUnsavedFields: true });
	}

	deleteFormula = async (index: number) => {
		const { formation } = this.state;
		const auxFile = formation.attachedFiles ? JSON.parse(JSON.stringify(formation.attachedFiles)) : [];
		auxFile.splice(index, 1)
		this.setState({ formation: { ...formation, formulas: auxFile }, openSidebar: false, hasUnsavedFields: true })
	}

	getBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.readAsDataURL(file);
		  reader.onload = () => resolve(reader.result);
		  reader.onerror = (error) => reject(error);
		});
	}
	
	onDrop = (attachedFiles: any) => {
		try {
		  const file = attachedFiles[attachedFiles.length - 1];
	
		  new Compressor(file, {
			// quality: 0.9,
			// maxWidth: 400,
			mimeType: "image/jpeg",
			success: (result: any) => {
			  this.getBase64(result).then((res) => {
				console.log(result);
				this.setState((state: any) => ({
					filesToDelete: state.formation.image && typeof state.formation.image === 'string' ?
									[...state.filesToDelete, state.formation.image ] :
									state.filesToDelete,
				  	formation: { ...state.formation, image: { file: result, preview: res } },
				  	hasUnsavedFields: true,
				}));
			  });
			},
		  });
		} catch (err) {
		  toast.warning(Strings.errors.notSupportedFile);
		}
	}

	onDropVideo = (attachedFiles: any) => {
		const { formation } = this.state;
		try {
		  	const file = attachedFiles[attachedFiles.length - 1];
	
			this.getBase64(file)
			.then(preview => {
				formation.video = { preview, file };
				this.setState({ formation, hasUnsavedFields: true });
			})
			.catch(err => console.log(err));
		} catch (err) {
			toast.warning(Strings.errors.notSupportedFile);
		}
	}

	renderDrawer() {
		const { item, sidebarLanguage } = this.state;

		return (
			<EditSidebar
				title={Strings.formation.files}
				open={this.state.openSidebar}
				onClose={this.closeSidebar}
				onChange={this.onChange}
				onSubmit={this.onSubmit}
				defaultValue={this.state.item}
				language={sidebarLanguage}
				onChangeLanguage={(lang: any) => this.setState({ sidebarLanguage: lang })}
				closable={false}
				fields={[
					{
						field: 'name',
						value: item?.name?.[sidebarLanguage],
						type: 'input',
						name: Strings.fields.name,
						required: true,
					},
					{
						field: 'url',
						value: item?.url?.[sidebarLanguage],
						type: 'filePicker',
						name: Strings.products.formulaFiles,
					},
					{
						version: moment.now(),
					}
				]}
			/>
		);
	}

	renderNotification() {
		const {
			formation,
		} = this.state;
		const { language } = this.state;

		return (
			<ContentWrapper>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<div className="title" style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
							<em className="moon-bell" />
							<div style={{ marginLeft: '10px' }}>{Strings.notifications.send}</div>
						</div>
					</div>
					<div style={{ marginLeft: '10px' }} className="toggle-wrapper">
						<Switch
							checked={formation?.notifyUsers}
							size='default'
							onChange={() => this.setState({ formation: { ...formation, notifyUsers: !formation?.notifyUsers }, hasUnsavedFields: true })}
						/>
					</div>
				</div>
				{formation?.notifyUsers && <>
					<label className="SingleLabel __required">{Strings.notifications.header}</label>
					<Input
						placeholder={Strings.notifications.header}
						defaultValue={formation?.notificationTitle ? formation.notificationTitle[language] : ''}
						value={formation?.notificationTitle && formation?.notificationTitle[language]}
						onChange={e => {
							this.setState({
								formation: { ...formation, notificationTitle: { ...(formation?.notificationTitle || {}), [language]: e.target.value } },
								hasUnsavedFields: true,
							});
						}}
						disabled={!formation?.notifyUsers}
					/>
					<label className="SingleLabel __required">{Strings.notifications.text}</label>
					<Input.TextArea
						placeholder={Strings.notifications.text}
						defaultValue={formation?.notificationDescription?.[language] || ''}
						value={formation?.notificationDescription?.[language] || ''}
						style={{ minHeight: '120px' }}
						onChange={e => {
							this.setState({
								formation: {
									...formation,
									notificationDescription: {
										...(formation?.notificationDescription || {} ),
										[language]: e.target.value,
									}
								},
								hasUnsavedFields: true,
							});
						}}
						disabled={!formation?.notifyUsers}
					/>
				</>}
			</ContentWrapper>
		)
	}

	renderGeneralInfo(youtube: boolean = false) {
		const { formation, urlError, language, timezones, countries, libraryTypes } = this.state;

		return (
			<>
				<Row gutter={24}>
					<Col xs={24} md={youtube ? 12 : 24}>
						<label className="SingleLabel __required">{Strings.fields.name}</label>
						<Input
							placeholder={Strings.fields.name}
							defaultValue={formation.name?.[language] || ''}
							value={formation.name?.[language] || ''}
							onChange={e => {
								this.setState({
									formation: { ...formation, name: { ...(formation.name || {}), [language]: e.target.value } },
									hasUnsavedFields: true,
								});
							}}
						/>
					</Col>
					{youtube && <Col xs={24} md={12}>
						<label className="SingleLabel">{Strings.formation.youtubeLink}</label>
						<Input
							placeholder={Strings.formation.youtubeLink}
							value={formation.youtubeLink || ''}
							style={urlError ? { border: '1px solid red', boxShadow: '0 0 0 2px rgba(255, 0, 0, 0.1)' } : {}}
							onChange={e => {
								this.setState({
									formation: { ...formation, youtubeLink: e.target.value },
									urlError: e.target.value && !e.target.value.match(formation) && Strings.errors.videoUrlIsInvalid,
									hasUnsavedFields: true,
								});
							}}
						/>
						<p style={{ color: 'red', position: 'fixed' }}>{urlError}</p>
					</Col>}
				</Row>
				<Row gutter={24}>
					<Col xs={24} md={youtube ? 12 : 24}>
						<label className="SingleLabel __required">{Strings.libraryTypes.header}</label>
						<Select
							className="tagsSelector"
							style={{ width: '100%' }}
							placeholder={Strings.libraryTypes.header}
							value={formation.category}
							onChange={(elem: any) =>
								this.setState({
									formation: { ...formation, category: elem },
									hasUnsavedFields: true,
								})
							}>
							{libraryTypes?.map((elem: any) => (
								<Option key={elem._id} value={elem._id}>{elem.name[language]}</Option>
							))}
						</Select>
					</Col>
					<Col xs={24} md={youtube ? 12 : 24}>
						<div
							className={`General_ColorFul_Switch ${formation?.publishSchedule ? '__active' : ''}`}
							style={youtube ? { marginTop: 40 } : {}}>
							<span>{Strings.news.schedulePublication}</span>
							<Switch
								className={`Switch ${formation?.publishSchedule ? '__active' : ''}`}
								checked={formation?.publishSchedule}
								size='small'
								onChange={value => this.setState({ formation: { ...formation, publishSchedule: !formation?.publishSchedule } })}
							/>
						</div>
					</Col>
				</Row>
				{formation?.publishSchedule && <Row gutter={[12, 12]} style={{ paddingTop: '10px' }}>
					<Col xs={24}>
						<DatePicker
							value={formation?.publishScheduleDate ? moment(formation?.publishScheduleDate) : null}
							format='DD-MM-YYYY HH:mm'
							style={{ width: '100%' }}
							showTime
							placeholder={Strings.publication.publicationDate}
							onChange={(date: any) => this.setState({ hasUnsavedFields: true, formation: { ...formation, publishScheduleDate: date } })}
							disabled={!formation?.publishSchedule}
						/>
					</Col>
					<Col xs={24} md={12}>
						{/* <label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.notifications.timezone}</label> */}
						<Select
							showSearch
							placeholder={Strings.fields.countries}
							optionFilterProp="children"
							style={{ width: '100%' }}
							onChange={(countryId: any) => {
								const country = countries.find((elem: any) => elem._id === countryId);
								this.setState({
									formation: {
										...formation,
										country: countryId,
										timezone: country.timezones.find((elem: any) => elem === formation.timezone),
									},
									timezones: country.timezones,
									hasUnsavedFields: true,
								})
							}}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={formation.country}
						>
							{countries.map((elem: any) => (
								<Option value={elem._id}>{elem.name}</Option>)
							)}
						</Select>
					</Col>
					<Col xs={24} md={12}>
						{/* <label className="SingleLabel" style={{ marginTop: '5px' }}>{Strings.notifications.timezone}</label> */}
						<Select
							showSearch
							placeholder={Strings.cultures.header}
							optionFilterProp="children"
							style={{ width: '100%' }}
							onChange={(timezone: any) =>
								this.setState({ formation: { ...formation, timezone }, hasUnsavedFields: true, })
							}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={formation.timezone}
						>
							{timezones.map((elem: any) => (
								<Option value={elem}>{elem}</Option>)
							)}
						</Select>
					</Col>
				</Row>}
			</>)
	}

	renderImageType() {
		const { formation } = this.state;

		return (
			<ContentWrapper>
				<Row gutter={[24, 24]}>
					<Col xs={24} lg={10}>
						<div className="ImageContainer">
							<div className="Image" style={{ height: '220px', marginTop: '30px' }}>
								<img
									alt={Strings.fields.image}
									src={formation?.image?.preview || formation?.image || placeholder}
								/>
								<div className="ImageOverlay">
									<Dropzone
										accept="image/jpg, image/jpeg, image/png"
										className="ImageOverlayOption"
										onDrop={(this.onDrop)}
										>
										<Icon name="pencil-outline" />
									</Dropzone>
									<div
										className={`ImageOverlayOption${
												Boolean(formation?.image) ? "" : " __disabled"
											}`}
										onClick={() => this.setState((state: any) => ({
											filesToDelete: state.formation.image && typeof state.formation.image === 'string' ?
															[...state.filesToDelete, state.formation.image ] :
															state.filesToDelete,
											formation: { ...formation, image: null },
											hasUnsavedFields: true,
										}))}
										>
										<Icon name="trash" />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} lg={14}>
						{this.renderGeneralInfo()}
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderVideoType() {
		const { formation } = this.state;

		return (
			<ContentWrapper>
				<Row gutter={[24, 24]}>
					<Col xs={24} lg={10}>
						<label className="SingleLabel">{Strings.formation.video}</label>
						<div className="ImageContainer">
							<div className="Image" style={{ height: '220px', marginBottom: '19px' }}>
								{formation.video && <video
									width="100%"
									preload="none"
									controls
									style={{
										border: 0,
										maxHeight: 300,
									}}>
									<source src={formation.video?.preview ? formation.video.preview : formation.video} />
								</video>}
								<div className={`VideoOverlay ${Boolean(formation.video) ? '_content' : ''}`}>
									{!formation.video ?
										<Dropzone
											accept=".mp4"
											className="VideoOverlayOption _edit"
											onDrop={(this.onDropVideo)}>
											<Icon name="pencil-outline" />
										</Dropzone> :
										<div
											className={`VideoOverlayOption _delete`}
											onClick={() => this.setState((state: any) => ({
												filesToDelete: state.formation.video && typeof state.formation.video === 'string' ?
																[...state.filesToDelete, state.formation.video ] :
																state.filesToDelete,
												formation: { ...formation, video: null },
												hasUnsavedFields: true,
											}))}>
											<Icon name="trash" />
										</div>}
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} lg={14}>
						{this.renderGeneralInfo()}
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	render() {
		const { formation, language } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>Formation</title>
					<meta name="description" content="Description of Formation" />
				</Helmet>
				{(!formation.type || formation.type === 'image') && this.renderImageType()}
				{formation.type === 'video' && this.renderVideoType()}
				{formation.type === 'youtubeUrl' &&
					<ContentWrapper>
						{this.renderGeneralInfo(true)}
					</ContentWrapper>}
				{this.renderNotification()}
				<label className="SingleLabel">{Strings.pages.content}</label>
				<Editor
					key={`editor_${language}`}
					required
					init={{ height: 500 }}
					value={formation?.description?.[language]}
					onChange={(value: any) =>
						this.setState({ formation: { ...formation, description: { ...formation.description, [language]: value} }, hasUnsavedFields: true })
					}
				/>
				<Table
					title={{
						icon: "working-briefcase",
						title: Strings.formation.files
					}}
					data={formation?.attachedFiles}
					columns={[
						{
							Header: Strings.importModal.fileName,
							id: 'name',
							accessor: (row: any) => row.name?.[language],
						},
					]}
					filterable
					add={{
						label: String(Strings.formatString(Strings.generic.addNew, Strings.fields.file.toLowerCase())),
						onClick: () => this.openFileSidebar(),
					}}
					actions={{
						edit: (original, value) => ({
							onClick: (row: any) => {
								this.openFileSidebar({
									...original,
									index: this.state.formation.attachedFiles.findIndex((elem: any) => elem.name == original.name && elem.url == original.url)
								})
							},
						}),
						remove: (original, value) => ({
							onClick: () => {
								const index = formation.attachedFiles.findIndex((elem: any) => elem._id === original._id || (elem.name == original.name && elem.url == original.url));
								formation.attachedFiles.splice(index, 1);
								this.setState({ formation: { ...formation, attachedFiles: formation.attachedFiles } });
							},
						}),
					}}
				/>
			{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Formation);