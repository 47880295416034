/*
*
* AgronomicService
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setLoader, delayedDispatch, setBreadcrumb, updateCrumb, } from 'store/actions';
import { Helmet } from 'react-helmet';
import { ContentWrapper, Icon, Table } from "components";
import { EditSidebar } from "components/EditSidebar";
import TextArea from "antd/lib/input/TextArea";
import { Row, Col, Input, Select } from "antd";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import { push } from "connected-react-router";
import { toast } from "utils/utils";
import { API, Endpoints } from "utils/api";
import moment from "moment";
import Strings from 'utils/strings';
import placeholder from "assets/images/placeholders/image.jpg";
import './styles.scss';

const { Option } = Select;

export class AgronomicService extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			agronomicService: {},
			language: 'pt',
			sidebarLanguage: 'pt',
			filesToDelete: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		// change this
		dispatch(setTitle(Strings.settings.agronomicServices));
		delayedDispatch(
			setBreadcrumb(() => {
				const { language } = this.state;
				const { match } = this.props;
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences",
						},
						{
							text: Strings.settings.agronomicServices,
							icon: "agriculture",
							route: "/settings/agronomic-services",
						},
						{
							text: match.params.id === "new" ? "new" : this.state.agronomicService?.name?.[language],
							icon: match.params.id === "new" ? "adicionar-acrescentar" : "lapis",
						},
					],
					actions: [
						{
							type: "language",
							value: language,
							onChange: (lang: any) => this.setState({ language: lang }),
						},
						{
							type: "button",
							text: Strings.generic.save,
							onClick: this.submitAgronomicService,
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields
								? "BreadcrumbButtonSuccess"
								: "",
							separator: "left",
							isSave: true,
						},
					],
				};
			})
		);
	  
		this.getAgronomicService();
		this.getDocuments();

		this.setState({ analysisTypesOptions: [
			<Option value='complete'>{Strings.agronomicServices.complete}</Option>,
			<Option value='routine'>{Strings.agronomicServices.routine}</Option>,
			<Option value='special'>{Strings.agronomicServices.special}</Option>
		] });
	}
  
	componentDidUpdate() {
		const { dispatch } = this.props;
	
		dispatch(updateCrumb());
	}

	getAgronomicService = async () => {
		const { dispatch, match } = this.props;

		if(match.params.id === 'new') return;
	
		dispatch(setLoader(true));
	
		try {
		  	const response = await API.get({
				url: Endpoints.uriAgronomicServices(match.params.id),
		  	});

			if (response.ok) {
				const agronomicService = response.data.results;

				this.setState({
					agronomicService,
				});
			}
		} catch (err) {
		  console.log("API Error", err);
		}

		dispatch(setLoader(false));
	}

	async getDocuments() {
		const { language } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriDocuments(),
		});

		if (response.ok && response.data.results) {
			const attachedFiles = response.data.results;

			this.setState({
				attachedFiles,
				attachedFilesOptions: attachedFiles?.map((elem: any) => (
					<Option value={elem._id}>{elem.name[language]}</Option>
				))
			});
		} else {
			toast.error(
				response.data?.message || Strings.serverErrors.title
			);
		}

		dispatch(setLoader(false));
	}

	isValidAgronomicService() {
		const { agronomicService } = this.state;

		return agronomicService.image &&
				agronomicService.name &&
				agronomicService.description
	}

	submitAgronomicService = async () => {
		const { agronomicService, filesToDelete } = this.state;
		const { dispatch, match } = this.props;

		if (!this.isValidAgronomicService) return toast.error(Strings.errors.invalidFields);
	
		dispatch(setLoader(true));

		const body = new FormData();

		body.append("name", JSON.stringify(agronomicService.name));
		body.append("description", JSON.stringify(agronomicService.description));
		body.append("observations", JSON.stringify(agronomicService.observations));

        if (agronomicService.image && agronomicService.image.file) {
            body.append("image", agronomicService.image.file);
        } else if (agronomicService.image) body.append("image", agronomicService.image);

		body.append("filesToDelete", JSON.stringify(filesToDelete));
		body.append("analysisTypes", JSON.stringify(agronomicService.analysisTypes));
		body.append("attachedFiles", JSON.stringify(agronomicService.attachedFiles?.map((elem: any) => elem._id) || []));
	
		const request = match.params.id === 'new' ? API.post : API.put;

		try {
		  	const response = await request({
				url: Endpoints.uriAgronomicServices(match.params.id !== 'new' ? match.params.id : ''),
				data: body,
		  	});

			if (response.ok) {
				const agronomicService = response.data.results;

				dispatch(push('/'))
				dispatch(push(`/settings/agronomic-services/${agronomicService._id}`))
				this.setState({
					agronomicService,
				});
			}
		} catch (err) {
		  console.log("API Error", err);
		}

		dispatch(setLoader(false));
	}

    getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
        });
    };

    onDrop = (files: any) => {
		const { agronomicService, filesToDelete } = this.state;

        try {
			const file = files[files.length - 1];

			new Compressor(file, {
				// quality: 0.9,
				// maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						console.log(result);
						this.setState({
							filesToDelete: typeof agronomicService.image === 'string' ? filesToDelete.push(agronomicService.image) : filesToDelete,
							agronomicService: { ...agronomicService, image: { file: result, preview: res } },
							hasUnsavedFields: true,
						});
					});
				},
			});
        } catch (err) {
        	toast.warning(Strings.errors.notSupportedFile);
        }
    };

	onChange = (field: string, value: any) => {
	  const { item, sidebarLanguage } = this.state;
		if (field === 'observation' || field === 'value') {
			this.setState({ item: { ...item, [field]: { ...item[field], [sidebarLanguage]: value } } });
		} else {
			this.setState({ item: { ...item, [field]: value } });
		}
	};

	closeSidebar = () => {
		this.setState({ openSidebar: false });
	}

	openSidebar = (value: any, sidebarType: string, row: any = null) => {
		console.log('value', value)
		this.setState({
            openSidebar: true,
            item: JSON.parse(JSON.stringify(value)),
            sidebarType,
			row,
        });
	}

	submitAnalisysType = () => {
		const { agronomicService, item } = this.state;
		if (!item?.observation ||
			(!Object.keys(item?.observation).find(key => item?.observation[key] !== "")) ||
			!item?.type)
			return toast.warning(Strings.errors.invalidFields);

		const auxTypes = JSON.parse(JSON.stringify(agronomicService?.analysisTypes || []));

		if (item.index >= 0) {
			auxTypes.splice(item.index, 1, item)
		} else {
			auxTypes.push(item);
		}

		this.setState({
			agronomicService: { ...agronomicService, analysisTypes: auxTypes },
			openSidebar: false,
            hasUnsavedFields: true,
		});
	}
	
	deleteAnalisysType = (index: number) => {
		const { agronomicService } = this.state;

		agronomicService.analysisTypes.splice(index, 1);

		this.setState({
            agronomicService,
            hasUnsavedFields: true,
        });
	}

	submitAnalisysTypeValue = () => {
		const { agronomicService, item, row } = this.state;
		if (!item?.value) return toast.warning(Strings.errors.invalidFields);

		const auxTypes = JSON.parse(JSON.stringify(agronomicService?.analysisTypes));
		
		if (item.index >= 0) {
			const values = agronomicService?.analysisTypes[item.parent]?.values

			values.splice(item.index, 1, item.value);

			auxTypes.splice(item.parent, 1, { ...auxTypes[item.parent], values })
		} else {
			const values = agronomicService?.analysisTypes[item.parent]?.values || []
			console.log('value', values)
			console.log('auxTypes', auxTypes)
			console.log('item', item)
			values.push(item.value);

			auxTypes.splice(item.parent, 1, { ...auxTypes[item.parent], values })
		}

		this.setState({
			agronomicService: { ...agronomicService, analysisTypes: auxTypes },
			openSidebar: false,
            hasUnsavedFields: true,
		}, () => {
			row.toggleRowExpanded(true);
		});
	}
	
	deleteAnalisysTypeValue = (index: number, parentIndex: number, row: any) => {
		const { agronomicService } = this.state;

		const auxTypes = JSON.parse(JSON.stringify(agronomicService?.analysisTypes));

		const values = agronomicService?.analysisTypes[parentIndex]?.values

		values.splice(index, 1);

		auxTypes.splice(parentIndex, 1, { ...auxTypes[parentIndex], values })

		this.setState({
			agronomicService: { ...agronomicService, analysisTypes: auxTypes },
			openSidebar: false,
			hasUnsavedFields: true,
		}, () => {
			row.toggleRowExpanded(true);
		});
	}

	submitAttachedFile = () => {
		const { agronomicService, item, attachedFiles } = this.state;

		if (!item?.attachedFile) return toast.warning(Strings.errors.invalidFields);

		const auxFiles = JSON.parse(JSON.stringify(agronomicService?.attachedFiles || []));
		const file = attachedFiles.find((elem: any) => elem._id === item.attachedFile);

		if (item.index >= 0) {
			auxFiles.splice(item.index, 1, file)
		} else {
			auxFiles.push(file);
		}

		this.setState({
			agronomicService: { ...agronomicService, attachedFiles: auxFiles },
			openSidebar: false,
            hasUnsavedFields: true,
		});
	}

	deleteAttachedFile = (index: number) => {
		const { agronomicService } = this.state;

		agronomicService.attachedFiles.splice(index, 1);

		this.setState({
            agronomicService,
            hasUnsavedFields: true,
        });
	}

	renderDrawer() {
        const {
			item,
			sidebarLanguage,
			sidebarType,
			attachedFilesOptions = [],
			analysisTypesOptions = [],
        } = this.state;

        let onSubmit = () => {};

        let fields: any = [];
        let title = "";

        if (sidebarType === "attachedFile") {
			title = Strings.agronomicServices.attachedFiles;
			fields = [
				{
					field: "attachedFile",
					value: item?._id,
					type: "selector",
					populated: true,
					options: attachedFilesOptions,
					name: Strings.fields.file,
					required: true,
				},
			];
			onSubmit = () => this.submitAttachedFile();
        } else if (sidebarType === "analisysType") {
			title = Strings.agronomicServices.analisysType;
			fields = [
				{
					field: "type",
					value: item?.type,
					type: "selector",
					populated: true,
					options: analysisTypesOptions,
					name: Strings.agronomicServices.analisysType,
					required: true,
				},
				{
                    field: "observation",
                    value: item?.observation?.[sidebarLanguage],
                    type: "textArea",
                    name: Strings.agronomicServices.observation,
                    required: true,
                },
			];
			onSubmit = () => this.submitAnalisysType();
        } else if (sidebarType === "analisysTypeValue") {
			title = String(Strings.formatString(
				Strings.nutrients.value,
				Strings.agronomicServices.analisysType))
			fields = [
				{
					field: "value",
					value: item?.value?.[sidebarLanguage],
					type: "input",
					name: Strings.nutrients.value,
					required: true,
				},
			];
			onSubmit = () => this.submitAnalisysTypeValue();
        }

        return (
			<EditSidebar
				title={title}
				open={this.state.openSidebar}
				onClose={this.closeSidebar}
				onChange={this.onChange}
				onSubmit={onSubmit}
				defaultValue={this.state.item}
				language={sidebarType !== "attachedFile" ? sidebarLanguage : undefined}
				onChangeLanguage={(lang: any) =>
					this.setState({ sidebarLanguage: lang })
				}
				closable={false}
				fields={[
					...fields,
					{
						version: moment.now(),
					},
				]}
			/>
        );
    }

	renderGeneralInfo () {
        const { agronomicService, language } = this.state;

		return (
			<ContentWrapper>
				<Row gutter={[24, 24]}>
					<Col xs={24} lg={10}>
						<div className="ImageContainer">
						<div
							className="Image"
							style={{ height: "220px", marginTop: "30px" }}
						>
							<img
							alt={Strings.fields.image}
							src={agronomicService?.image?.preview || agronomicService?.image || placeholder}
							/>
							<div className="ImageOverlay">
							<Dropzone
								accept="image/jpg, image/jpeg, image/png"
								className="ImageOverlayOption"
								onDrop={this.onDrop}
							>
								<Icon name="pencil-outline" />
							</Dropzone>
							<div
								className={`ImageOverlayOption${
								Boolean(agronomicService?.image) ? "" : " __disabled"
								}`}
								onClick={() => this.setState({ image: null })}
							>
								<Icon name="trash" />
							</div>
							</div>
						</div>
						</div>
					</Col>
					<Col xs={24} lg={14}>
						<label className="SingleLabel __required">
							{Strings.fields.name}
						</label>
						<Input
							placeholder={Strings.fields.name}
							defaultValue={agronomicService?.name?.[language] || ""}
							value={agronomicService?.name?.[language] || ""}
							onChange={(e) => {
								this.setState({
									agronomicService: { ...agronomicService, name: { ...agronomicService?.name, [language]: e.target.value } },
									hasUnsavedFields: true,
								});
							}}
						/>
						<label className="SingleLabel __required">
							{Strings.fields.description}
						</label>
						<TextArea
							placeholder={Strings.fields.description}
							defaultValue={agronomicService?.description?.[language]}
							value={agronomicService?.description?.[language]}
							style={{ height: 130 }}
							onChange={(e) => {
								this.setState({
									agronomicService: {
										...agronomicService, 
										description: {
											...agronomicService?.description,
											[language]: e.target.value,
										}
									},
									hasUnsavedFields: true,
								});
							}}
						/>
					</Col>
				</Row>
				<Row gutter={[24, 24]}>
					<Col xs={24}>
						<label className="SingleLabel __required">
							{Strings.fertilizing.observations}
						</label>
						<TextArea
							placeholder={Strings.fertilizing.observations}
							defaultValue={agronomicService?.observations?.[language]}
							value={agronomicService?.observations?.[language]}
							style={{ height: 40 }}
							onChange={(e) => {
								this.setState({
									agronomicService: {
										...agronomicService,
										observations: {
											...agronomicService?.observations,
											[language]: e.target.value,
										},
									},
									hasUnsavedFields: true,
								});
							}}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderAnalysisTypes() {
		const { agronomicService, language } = this.state;

		return <Table
			title={{
				icon: "agriculture",
				title: Strings.agronomicServices.analysisTypes,
			}}
			data={agronomicService?.analysisTypes}
			columns={[
				{
					Header: Strings.agronomicServices.analisysType,
					id: "type",
					accessor: (row: any) => row.type,
				},
				{
					Header: Strings.agronomicServices.observation,
					id: "observation",
					accessor: (row: any) => row.observation?.[language],
				},
			]}
			expanded
			renderExpanded={(elem: any) => this.renderAnalisysTypeExpandable(elem)}
			add={{
				label: String(
					Strings.formatString(
						Strings.generic.addNew,
						Strings.agronomicServices.analisysType.toLowerCase()
					)
				),
				onClick: () => this.openSidebar({}, "analisysType"),
			}}
			actions={{
				edit: (original: any, value: any) => ({
				onClick: () =>
					this.openSidebar(
						{ ...original, index: value.index },
						"analisysType"
					),
				}),
				remove: (original: any, value: any) => ({
					onClick: () => this.deleteAnalisysType(value.index),
				}),
			}}
		/>
	}

	renderAnalisysTypeExpandable(row: any) {
		const { agronomicService, language } = this.state;
		const index = row.index;

		return (
			<Table
				title={{
					icon: "agriculture",
					title: Strings.agronomicServices.values,
				}}
                data={agronomicService?.analysisTypes[index].values}
                columns={[
                    {
						Header: Strings.nutrients.value,
						id: "name",
						accessor: (row: any) => row?.[language],
					},
                ]}
                add={{
                    label: String(
						Strings.formatString(
							Strings.generic.addNew,
							Strings.nutrients.value.toLowerCase()
                    	)
                    ),
                    onClick: () => this.openSidebar({ parent: index }, "analisysTypeValue", row),
                }}
                actions={{
                    edit: (original: any, value: any) => ({
                    	onClick: () =>
							this.openSidebar(
								{ value: original, parent: index, index: value.index },
								"analisysTypeValue",
								row,
							),
                    }),
                    remove: (original: any, value: any) => ({
                    	onClick: () => this.deleteAnalisysTypeValue(value.index, index, row),
                    }),
                }}
            />
		);
	}

	renderAttachedFiles() {
		const { agronomicService, language } = this.state;
		console.log('file', agronomicService?.attachedFiles)
		return (
			<Table
                title={{
                    icon: "file",
                    title: Strings.agronomicServices.attachedFiles,
                }}
                data={agronomicService?.attachedFiles}
                columns={[
                    {
						Header: Strings.documents.header,
						id: "name",
						accessor: (row: any) => row.name?.[language],
					},
                    {
						Header: '',
						id: "file",
						accessor: (row: any) => row.file?.[language],
						Cell: (cell: any) => <button
							className="submitButton"
							onClick={() => window.open(cell.value)}
							style={{
								margin: 0,
								borderRadius: 4,
								minWidth: 120,
								cursor: 'pointer',
								float: 'left',
							 }}
						>
							{Strings.documents.file}
						</button>
					},
                ]}
                add={{
                    label: String(
						Strings.formatString(
							Strings.generic.addNew,
							Strings.documents.file.toLowerCase()
                    	)
                    ),
                    onClick: () => this.openSidebar({}, "attachedFile"),
                }}
                actions={{
                    edit: (original: any, value: any) => ({
                    onClick: () =>
                        this.openSidebar(
							{ ...original, index: value.index },
							"attachedFile"
                        ),
                    }),
                    remove: (original: any, value: any) => ({
                    	onClick: () => this.deleteAttachedFile(value.index),
                    }),
                }}
            />
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.agronomicServices.header}</title>
					<meta name="description" content="Description of AgronomicService" />
				</Helmet>
        		{this.renderGeneralInfo()}
        		{this.renderAnalysisTypes()}
        		{this.renderAttachedFiles()}
        		{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(AgronomicService);