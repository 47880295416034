/*
 *
 * Promoters
 *
 */

import React from "react";
import { connect } from "react-redux";
import {
  setLoader,
  setTitle,
  setBreadcrumb,
  delayedDispatch,
  updateCrumb,
} from "store/actions";
import { API, Endpoints } from "utils/api";
import { Table } from "components";
import { Helmet } from "react-helmet";
import { push } from "connected-react-router";
import Strings from "utils/strings";
import { ImportModal } from "components";
import "./styles.scss";

export class Promoters extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      importModal: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // change this
    dispatch(setTitle(Strings.sidebar.promoters));

    delayedDispatch(
      setBreadcrumb(() => {
        return {
          locations: [
            {
              text: Strings.sidebar.promoters,
              //   route: "/promoters",
              icon: "working-briefcase",
            },
          ],
        };
      })
    );

    this.getPromoters();
  }

  componentDidUpdate() {
    const { dispatch } = this.props;

    dispatch(updateCrumb());
  }

  async getPromoters() {
    //GET Promoters
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    try {
      const response = await API.get({
        url: Endpoints.uriPromoters(),
      });

      if (response.ok) {
        this.setState({
          promoters: response.data.results || [],
        });
      }
    } catch (err) {
      console.log("API Error", err);
    }

    dispatch(setLoader(false));
  }

  removePromoter = async (id: string) => {
    const { dispatch } = this.props;

    dispatch(setLoader(true));

    try {
      const response = await API.delete({
        url: Endpoints.uriPromoters(id),
      });

      if (response.ok) {
        this.setState({
          promoters: response.data.results || [],
        });
      }
    } catch (err) {
      console.log("API Error", err);
    }

    dispatch(setLoader(false));
  };

  patchPromoter = async (promoter: any) => {
    const { dispatch } = this.props;

    console.log("promoter", promoter);

    dispatch(setLoader(true));

    try {
      const response = await API.patch({
        url: Endpoints.uriPromoters(promoter._id),
        data: { isActive: !promoter.isActive },
      });

      if (response.ok) {
        this.setState({
          promoters: response.data.results || [],
        });
      }
    } catch (err) {
      console.log("API Error", err);
    }

    dispatch(setLoader(false));
  };

  onImport = () => {
    this.setState({ importModal: true });
  };

  renderImport() {
    return (
      <ImportModal
        title={Strings.promoters.importPromoters}
        openModal={this.state.importModal}
        onSubmit={() => {
          this.setState({ uploadFile: null }, () => {
            this.getPromoters();
          });
        }}
        onRequestClose={() =>
          this.setState({
            importModal: false,
            uploadFile: null,
          })
        }
        onUploadFile={(uploadFile: any) => {
          console.log("file: ", uploadFile);
          this.setState({ uploadFile });
        }}
        file={this.state.uploadFile}
        deleteFile={() => this.setState({ uploadFile: null })}
        type="promoters"
      />
    );
  }

  render() {
    const { promoters } = this.state;
    const { dispatch } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.promoters}</title>
          <meta name="description" content="Description of Promoters" />
        </Helmet>
        <Table
          title={{
            icon: "working-briefcase",
            title: Strings.sidebar.promoters,
          }}
          data={promoters}
          columns={[
            {
              Header: Strings.fields.photo,
              id: "image",
              type: "image",
              accessor: (row: any) => row.photo,
              maxWidth: 80,
            },
            {
              Header: Strings.promoters.header,
              id: "name",
              accessor: (row: any) => row.name,
            },
          ]}
          filterable
          importable
          importData={() => this.onImport()}
          add={{
            label: String(
              Strings.formatString(
                Strings.generic.addNew,
                Strings.promoters.header.toLowerCase()
              )
            ),
            onClick: () => dispatch(push("/promoters/new")),
          }}
          actions={{
            edit: (original, value) => ({
              onClick: () => dispatch(push(`/promoters/${original._id}`)),
            }),
            remove: (original, value) => ({
              onClick: () => this.removePromoter(original._id),
            }),
            toggle: (original, value) => ({
              value: original.isActive,
              onChange: () => this.patchPromoter(original),
            }),
          }}
        />
        {this.renderImport()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Promoters);
