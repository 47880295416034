/*
*
* News
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setLoader, setBreadcrumb, delayedDispatch, updateCrumb } from 'store/actions';
import { Table } from 'components';
import { Helmet } from 'react-helmet';
import { API, Endpoints } from "utils/api";
import { push } from 'connected-react-router';
import { toast } from 'utils/utils';
import Strings from 'utils/strings';
import './styles.scss';

export class News extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			language: 'pt',
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.news));
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
						  text: Strings.sidebar.news,
						//   route: "/settings/cultures",
						  icon: "timetable-outline",
						},
					],
				}
			})
		);

		this.getNews();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
	
		dispatch(updateCrumb());
	}

	async getNews() {
		const response = await API.get({
			url: Endpoints.uriNews(),
		});
	  
		if (response.ok) {
			this.setState({ news: response.data.results });
		} else {
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}
	}

	async deleteNew(id: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true))

		const response = await API.delete({
			url: Endpoints.uriNews(id)
		});
	  
		if (response.ok) {
			this.setState({ news: response.data.results });
		} else {
			dispatch(setLoader(false))
			return toast.error(response.data?.message || Strings.serverErrors.title);
		}

		dispatch(setLoader(false))
	}

	render() {
		const { news = [], language } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.news}</title>
					<meta name="description" content="Description of News" />
				</Helmet>
				<Table
					title={{
						icon: "timetable-outline",
						title: Strings.sidebar.news
					}}
					data={news}
					columns={[
						{
							Header: Strings.fields.image,
							id: 'image',
							type: 'image',
							accessor: (row: any) => row.image,
							maxWidth: 80,
						},
						{
							Header: Strings.news.header,
							id: 'name',
							accessor: (row: any) => row.name?.[language],
						},
					]}
					filterable
					add={{
						label: String(Strings.formatString(Strings.generic.addNew, Strings.news.header.toLowerCase())),
						onClick: () => dispatch(push('/news/new')),
					}}
					actions={{
						edit: (original, value) => ({
							onClick: () => dispatch(push(`/news/${original._id}`)),
						}),
						remove: (original, value) => ({
							onClick: () => this.deleteNew(original._id),
						}),
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(News);